import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useDispatch } from "react-redux";
import { getAllProductsRequest } from "../AllProductPage/httpactions/httpactions";
import SectionStyleThree from "../Helpers/SectionStyleThree";
import SectionStyleTwo from "../Helpers/SectionStyleTwo";
import ViewMoreTitle from "../Helpers/ViewMoreTitle";
import LayoutHomeThree from "../Partials/LayoutHomeThree";
import Banner from "./Banner";
import BodyBannerPage from "./BodyBanner";
import CampaignCountDown from "./CampaignCountDown";
import ProductsAds from "./ProductsAds";
import Features from "./Features";
import gloves from "./gloves.gif";
import helmet from "./helmet.gif";

const TransportData = [
  {
    vehicle: "Ezykle",
    costPerDay: 0.07,
    costPerMonth: 2.1,
    image: "assets/images/compare_cycle.png",
  },
  {
    vehicle: "Bike",
    costPerDay: 2.5,
    costPerMonth: 75,
    image: "assets/images/compare_bike.png",
  },
  {
    vehicle: "Car",
    costPerDay: 7.7,
    costPerMonth: 231,
    image: "assets/images/compare_car.png",
  },
];

export default function HomeThree() {
  const [products, setProducts] = useState([]);
  const dispatch = useDispatch();
  const [topsellingProducts, setTopSellingProducts] = useState([]);

  const [kilometers, setKilometers] = useState(0);

  const handleScroll = (e) => {
    setKilometers(e.target.value);
  };

  useEffect(() => {
    console.log("");
    getAllProducts();

    //eslint-disable-next-line
  }, []);

  const getAllProducts = async () => {
    await dispatch(
      getAllProductsRequest(
        { userId: localStorage.getItem("userId") },
        dispatch
      )
    ).then((response) => {
      if (response !== undefined) {
        console.log(response.result);
        setProducts(response.result);
        updateTopsellingProducts(response.result);
      }
    });
  };

  const updateTopsellingProducts = (results) => {

    if(results.length > 5) {
     let arr =  results.slice(Math.max(results.length - 5, 0))
     setTopSellingProducts(arr);
    } else {
      let arr =  results.slice(0, 4);
      setTopSellingProducts(arr);
    }
    
  };
  return (
    <>
      <LayoutHomeThree type={3} childrenClasses="pt-0">
        <Helmet>
        <title>Buy Electric Cycles Online - Best Electric Bicycles for Sale.</title>
          <meta
            name="Keyword"
            content="E Cycle Accessories, Electric Bike Parts Online"
          />
          <meta
            name="SEO title"
            content="Shop E Cycle Accessories | Electric Bike Parts Online|Ezykle"
          />
          <meta
            name="Description Line"
            content="Find a wide range of e-cycle accessories and electric bike parts online. Enhance your ride with quality accessories for your electric bike."
          />
          <meta name="Final URL" content="" />
          <meta name="author" content="Goutham" />

          <meta
            name="Keyword"
            content="Electric Bike Insurance, Best Budget Electric Bike"
          />
          <meta
            name="SEO title"
            content="Electric Bike Insurance | Best Budget e-Bikes | Ezykle"
          />
          <meta
            name="Description Line"
            content="Protect your investment with electric bike insurance. Find the best budget-friendly e-bikes and ride with peace of mind."
          />
          <meta name="Final URL" content="https://ezykle.com/#/assurance" />
          <meta name="author" content="Goutham" />

          <meta
            name="Keyword"
            content="About Us | Your Trusted E-Cycle Experts | Ezykle"
          />
          <meta
            name="SEO title"
            content="About Us | Best E Cycle Manufactures in India"
          />
          <meta
            name="Description Line"
            content="Learn about our passion for e-cycles and commitment to providing quality electric bicycles. Discover why we're your trusted e-cycle experts."
          />
          <meta name="Final URL" content="https://ezykle.com/#/about" />
          <meta name="author" content="Goutham" />

          <meta
            name="Keyword"
            content="Best Electric Bicycle, Electric Bike Models, Latest Electric Bike"
          />
          <meta
            name="SEO title"
            content="Best Electric Bicycle Models | Top-Rated e-Bikes | Ezykle"
          />
          <meta
            name="Description Line"
            content="Discover the top electric bicycle models available, featuring cutting-edge technology and performance for an exceptional riding experience."
          />
          <meta name="Final URL" content="https://ezykle.com/#/all-products" />
          <meta name="author" content="Goutham" />

          <meta name="Keyword" content="" />
          <meta
            name="SEO title"
            content="Contact Us |Get in Touch with E-Cycle Experts | Ezykle"
          />
          <meta
            name="Description Line"
            content="Reach out to our team of e-cycle experts for assistance, inquiries, or support. We're here to help you with all your electric bicycle needs."
          />
          <meta name="Final URL" content="https://ezykle.com/#/contact" />
          <meta name="author" content="Goutham" />

          <meta name="Keyword" content="" />
          <meta
            name="SEO title"
            content="Become a Dealer | Partner with E-Cycle Experts | Ezykle"
          />
          <meta
            name="Description Line"
            content="Join our network of dealers and expand your business with top-quality e-cycles. Partner with the experts in electric bicycles today."
          />
          <meta name="Final URL" content="https://ezykle.com/#/dealer" />
          <meta name="author" content="Goutham" />

          <meta name="Keyword" content="" />
          <meta
            name="SEO title"
            content="E-Cycle Events| Join the Electric Revolution | Ezykle"
          />
          <meta
            name="Description Line"
            content="Explore upcoming e-cycle events and be part of the electric revolution. Connect with like-minded enthusiasts and discover new riding experiences."
          />
          <meta name="Final URL" content="https://ezykle.com/#/events" />
          <meta name="author" content="Goutham" />

          <meta name="Keyword" content="" />
          <meta
            name="SEO title"
            content="Discover E-Cycle FAQs: Your Ultimate Guide | Ezykle"
          />
          <meta
            name="Description Line"
            content="Find answers to frequently asked questions about e-cycles. Get all the information you need to know about electric bicycles."
          />
          <meta name="Final URL" content="https://ezykle.com/#/faq" />
          <meta name="author" content="Goutham" />

          <meta
            name="Keyword"
            content="Best Electric Cycles, Electric Mountain Bicycle"
          />
          <meta
            name="SEO title"
            content="Discover the Best Electric Cycles | Electric Mountain Bicycle"
          />
          <meta
            name="Description Line"
            content="Explore the best electric mountain bicycles for thrilling rides on any terrain. Find the perfect e-cycle for your adventurous spirit today!"
          />
          <meta name="Final URL" content="https://ezykle.com/#/fleet" />
          <meta name="author" content="Goutham" />

          <meta
            name="Description Line"
            content="Explore a wide range of electric bicycles for sale online. Shop now to buy the best electric cycles from top brands with fast delivery and easy returns."
          />
          <meta
            name="Keyword"
            content="Buy Electric Cycles Online - Best Electric Bicycles for Sale"
          />
          <meta name="author" content="Goutham" />
          <meta
            name="SEO title"
            content="Buy Electric Cycles Online - Best Electric Bicycles for Sale"
          />
          <meta
            name="description"
            content="Explore a wide range of electric bicycles for sale online. Shop now to buy the best electric cycles from top brands with fast delivery and easy returns."
          />
          <link rel="canonical" href="https://ezykle.com/" />
          <meta name="Final URL" content="https://ezykle.com/" />

          <meta name="Keyword" content="" />
          <meta
            name="SEO title"
            content="Track Your Order: Stay Updated on Your E-Cycle Delivery"
          />
          <meta
            name="Description Line"
            content="Monitor the status of your e-cycle order with our convenient tracking system. Stay informed every step of the way until your bike arrives."
          />
          <meta
            name="Final URL"
            content="https://ezykle.com/#/tracking-order"
          />
          <meta name="author" content="Goutham" />

          <meta name="Keyword" content="" />
          <meta
            name="SEO title"
            content="Virtual Showroom | Explore E-Cycles Online | Ezykle"
          />
          <meta
            name="Description Line"
            content="Experience our virtual showroom and explore our range of e-cycles from the comfort of your home. Find your perfect ride today"
          />
          <meta name="Final URL" content="https://ezykle.com/#/v-showroom" />
          <meta name="author" content="Goutham" />
        </Helmet>
        <Banner className="" />
        {/* <BrandSection
          type={3}
          sectionTitle="Shop by Brand"
          className="brand-section-wrapper mb-[60px]"
        /> */}
        <SectionStyleThree
          type={3}
          products={products}
          sectionTitle="New Arrivals"
          seeMoreUrl="/all-products"
          className="new-products mb-[60px] mt-[10px]"
        />

        <ProductsAds
          ads={[]} //`${process.env.PUBLIC_URL}/assets/images/hels.jpeg`
          className="products-ads-section mb-[60px]"
        >
          <h1
            className="text-[34px] font-bold text-qblack mb-4"
            style={{ textAlign: "center" }}
          >
            Transportation Cost Comparison
          </h1>

          <div className="transportation-costs">
            {TransportData.map((data) => (
              <div key={data.vehicle} className="vehicle">
                <img
                  src={`${process.env.PUBLIC_URL}/${data.image}`}
                  alt={data.vehicle}
                />
                <h4>{data.vehicle}</h4>
                <h2>Day : ₹ {(data.costPerDay * kilometers).toFixed(2)}</h2>
                <h2>Month : ₹ {(data.costPerMonth * kilometers).toFixed(2)}</h2>
              </div>
            ))}
          </div>
          <div className="scroll-line">
            <h3>Daily Usage {kilometers} km(s)</h3>
            <input
              type="range"
              min="1"
              max="200" // Adjust maximum kilometers as needed
              value={kilometers}
              onChange={handleScroll}
            />
          </div>
        </ProductsAds><br/>

        <Features/><br/>
        <BodyBannerPage className="products-ads-section mb-[60px]" />
        <ProductsAds
          ads={[helmet, gloves]}
          sectionHeight="sm:h-[295px] h-full"
          className="products-ads-section mb-[60px]"
        />
        <ViewMoreTitle
          className="top-selling-product mb-[60px]"
          seeMoreUrl="/all-products"
          categoryTitle="Top Selling Products"
        >
          <SectionStyleTwo type={3} products={topsellingProducts} />
        </ViewMoreTitle>

        <CampaignCountDown
          className="mb-[60px]"
          lastDate="2024-07-31 16:00:00"
        />
      </LayoutHomeThree>
    </>
  );
}
