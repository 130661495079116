import { API_URL } from "../utils/ApiUrl";
import ServiceCalls from "../utils/services/httpServiceCalls";

export const getAllProducts = (data) => async () => {
  if (data.userId == "" || data.userId == null) {
    try {
      let result = await ServiceCalls.get(
        `${API_URL.ALL_PRODUCTS}?catId=4`,
        data,
        true
      );
      return result;
    } catch (error) {}
  } else {
    try {
      let result = await ServiceCalls.get(
        `${API_URL.ALL_PRODUCTS}?catId=4&userId=${data.userId}`,
        data,
        true
      );
      return result;
    } catch (error) {}
  }
};

export const getProductDetails = (data) => async () => {
  if (data.userId == "" || data.userId == null) {
    try {
      let result = await ServiceCalls.get(
        `${API_URL.PRODUCT_INFO}${data.id}`,
        data,
        true
      );
      return result;
    } catch (error) {}
  } else {
    try {
      let result = await ServiceCalls.get(
        `${API_URL.PRODUCT_INFO}${data.id}&userId=${data.userId}`,
        data,
        true
      );
      return result;
    } catch (error) {}
  }
};

export const getAllSubCategories = (data) => async () => {
  try {
    let result = await ServiceCalls.get(
      `${API_URL.GET_SUB_CATS}${data.id}`,
      data,
      true
    );
    return result;
  } catch (error) {}
};

export const getAllSubCategoriesById = (data) => async () => {
  try {
    let result = await ServiceCalls.get(
      `${API_URL.ALL_PRODUCTS}?catId=14&subCatId=${data.subcatId}`,
      null,
      true
    );
    return result;
  } catch (error) {}
};

export const getWishListItemsById = (data) => async () => {
  try {
    let result = await ServiceCalls.get(
      `${API_URL.GET_WISH_LIST}${data.userId}`,
      null,
      true
    );
    return result;
  } catch (error) {}
};

export const addWishListItemsById = (data) => async () => {
  try {
    let result = await ServiceCalls.get(
      `${API_URL.ADD_WISH_LIST}${data.userId}&productId=${data.productId}`,
      null,
      true
    );
    return result;
  } catch (error) {}
};

export const removeWishListItemsById = (data) => async () => {
  try {
    let result = await ServiceCalls.get(
      `${API_URL.REMOVE_WISH_LIST}${data.userId}&productId=${data.productId}`,
      null,
      true
    );
    return result;
  } catch (error) {}
};

export const productAddToCartById = (data) => async () => {
  try {
    let result = await ServiceCalls.get(
      `${API_URL.ADD_TO_CART}${data.userId}&productId=${data.productId}&quantity=${data.quantity}`,
      null,
      true
    );
    return result;
  } catch (error) {}
};

export const userCartListById = (data) => async () => {
  try {
    let result = await ServiceCalls.get(
      `${API_URL.USER_CART_LIST}${data.userId}`,
      null,
      true
    );
    return result;
  } catch (error) {}
};

export const userCartTotalById = (data) => async () => {
  try {
    let result = await ServiceCalls.get(
      `${API_URL.CART_TOTAL}${data.userId}`,
      null,
      true
    );
    return result;
  } catch (error) {}
};

export const userCartRemoveItemById = (data) => async () => {
  try {
    let result = await ServiceCalls.delete(
      `${API_URL.REMOVE_CART_ITEM}${data.productId}`,
      null,
      true
    );
    return result;
  } catch (error) {}
};

export const userClearCartById = (data) => async () => {
  try {
    let result = await ServiceCalls.delete(
      `${API_URL.CLEAR_CART_ITEMS}${data.userId}`,
      null,
      true
    );
    return result;
  } catch (error) {}
};

export const userClearWishListById = (data) => async () => {
  try {
    let result = await ServiceCalls.delete(
      `${API_URL.CLEAR_WISHLIST_ITEMS}${data.userId}`,
      null,
      true
    );
    return result;
  } catch (error) {}
};

export const updateCartDataService = (data) => async () => {
  try {
    let result = await ServiceCalls.get(
      `${API_URL.UPDATE_CART_QUANTITY}${data.userId}&productId=${data.productId}&quantity=${data.quantity}`,
      null,
      true
    );
    return result;
  } catch (error) {}
};

export const getCartDataService = (data) => async () => {
  try {
    let result = await ServiceCalls.get(
      `${API_URL.GET_CART_DATA}${data.userId}`,
      null,
      true
    );
    return result;
  } catch (error) {}
};

export const createOrderService = (data) => async () => {
  try {
    let result = await ServiceCalls.post(`${API_URL.CREATE_ORDER}`, data, true);
    return result;
  } catch (error) {}
};

export const getOrdersHistoryService = (data) => async () => {
  try {
    let result = await ServiceCalls.get(
      `${API_URL.ORDER_HISTORY}${data.userId}`,
      null,
      true
    );
    return result;
  } catch (error) {}
};

// Any Name 5123456789012346 07/2025 123
