import {
  addUserAddressService,
  addressListService,
  deleteUserAddressService,
  getUserDetailsService,
  registerUser,
  updateUser,
  updateUserAddressService,
} from "../../../api/userservices";

const registerUserRequest = (data) => async (dispatch) => {
  const response = await dispatch(registerUser(data));
  if (
    (response &&
      Object.keys(response).length &&
      response?.data?.status === 201) ||
    200
  ) {
    if (response.data != null && response.data != undefined) {
      return Promise.resolve(response.data);
    } else {
      return Promise.resolve(null);
    }
  } else {
    return Promise.resolve(null);
  }
};

const updateUserRequest = (data) => async (dispatch) => {
  const response = await dispatch(updateUser(data));
  if (
    (response &&
      Object.keys(response).length &&
      response?.data?.status === 201) ||
    200
  ) {
    if (response.data != null && response.data != undefined) {
      return Promise.resolve(response.data);
    } else {
      return Promise.resolve(null);
    }
  } else {
    return Promise.resolve(null);
  }
};

const getUserAddressListRequest = (data) => async (dispatch) => {
  const response = await dispatch(addressListService(data));
  if (
    (response &&
      Object.keys(response).length &&
      response?.data?.status === 201) ||
    200
  ) {
    if (response.data != null && response.data != undefined) {
      return Promise.resolve(response.data);
    } else {
      return Promise.resolve(null);
    }
  } else {
    return Promise.resolve(null);
  }
};

const addUserAddressRequest = (data) => async (dispatch) => {
  const response = await dispatch(addUserAddressService(data));
  if (
    (response &&
      Object.keys(response).length &&
      response?.data?.status === 201) ||
    200
  ) {
    if (response.data != null && response.data != undefined) {
      return Promise.resolve(response.data);
    } else {
      return Promise.resolve(null);
    }
  } else {
    return Promise.resolve(null);
  }
};

const updateUserAddressRequest = (data) => async (dispatch) => {
  const response = await dispatch(updateUserAddressService(data));
  if (
    (response &&
      Object.keys(response).length &&
      response?.data?.status === 201) ||
    200
  ) {
    if (response.data != null && response.data != undefined) {
      return Promise.resolve(response.data);
    } else {
      return Promise.resolve(null);
    }
  } else {
    return Promise.resolve(null);
  }
};

const deleteUserAddressRequest = (data) => async (dispatch) => {
  const response = await dispatch(deleteUserAddressService(data));
  if (
    (response &&
      Object.keys(response).length &&
      response?.data?.status === 201) ||
    200
  ) {
    if (response.data != null && response.data != undefined) {
      return Promise.resolve(response.data);
    } else {
      return Promise.resolve(null);
    }
  } else {
    return Promise.resolve(null);
  }
};

const getUserDataRequest = (data) => async (dispatch) => {
  const response = await dispatch(getUserDetailsService(data));
  if (
    (response &&
      Object.keys(response).length &&
      response?.data?.status === 201) ||
    200
  ) {
    if (response.data != null && response.data != undefined) {
      return Promise.resolve(response.data);
    } else {
      return Promise.resolve(null);
    }
  } else {
    return Promise.resolve(null);
  }
};

export {
  addUserAddressRequest,
  deleteUserAddressRequest,
  getUserAddressListRequest,
  getUserDataRequest,
  registerUserRequest,
  updateUserAddressRequest,
  updateUserRequest,
};
