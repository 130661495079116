import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import generatePDF, { Margin, Resolution } from "react-to-pdf";
import PageTitle from "../../../Helpers/PageTitle";
import Layout from "../../../Partials/Layout";
import "./Invoice.css";
export default function Invoice() {
  const navigate = useNavigate();

  const options = {
    // default is `save`
    method: "open",
    // default is Resolution.MEDIUM = 3, which should be enough, higher values
    // increases the image quality but also the size of the PDF, so be careful
    // using values higher than 10 when having multiple pages generated, it
    // might cause the page to crash or hang.
    resolution: Resolution.MEDIUM,
    page: {
      margin: Margin.MEDIUM,
      // default is 'A4'
      format: "letter",
    },
  };
  const location = useLocation();
  const [item, setItem] = useState(location.state.item);
  const targetRef = useRef();
  const getFormattedDate = (e) => {
    console.log("new date is ", e);
    var date = new Date(e);
    // console.log(date.toDateString());
    return date.toLocaleDateString();
  };

  useEffect(() => {
    console.log("Hello Invoice", item);
  }, []);
  return (
    <Layout>
      <div className="page-title mb-10">
        <PageTitle
          title="Order Details"
          breadcrumb={[
            { name: "home", path: "/" },
            { name: "Profile", path: "/profile#order" },
            { name: "Order Details" },
          ]}
        />
      </div>
      <div className="cart-page-wrapper w-full">
        <div className="container-x mx-auto">
          <div
            style={{ margin: 20, marginLeft: 20, marginRight: 20 }}
            className="pl-10, pt-10"
            ref={targetRef}
          >
            <div className="page-container">
              Page
              <span className="page"></span>
              of
              <span className="pages"> </span>
            </div>

            <div className="logo-container">
              <img
                src={`${process.env.PUBLIC_URL}/assets/images/logo.png`}
                alt=""
                style={{ height: 40 }}
              />
            </div>

            <table className="invoice-info-container">
              <tr>
                <td rowspan="2" className="client-name">
                  {localStorage.getItem("user_first_name")}{" "}
                  {localStorage.getItem("user_last_name")}
                </td>
                <td>Ezykle</td>
              </tr>
              <tr>
                <td>Preston Prime Mall,</td>
              </tr>
              <tr>
                <td>
                  Invoice Date:{" "}
                  <strong>{getFormattedDate(item.updated_at)}</strong>
                </td>
                <td>Lumbini Avenue Gachibowli,</td>
              </tr>
              <tr>
                <td>
                  Invoice No: <strong>{item.id}</strong>
                </td>
                <td>Hyderabad, Telangana, India - 500081</td>
              </tr>
            </table>

            <table className="line-items-container">
              <thead>
                <tr>
                  <th className="heading-quantity">S.No.</th>
                  <th className="heading-description">Name</th>
                  <th className="heading-description">Qty</th>
                  <th className="heading-price">Price per unit</th>
                  <th className="heading-price">Tax</th>
                </tr>
              </thead>
              <tbody>
                {item.items.map((order, i) => {
                  return (
                    <tr>
                      <td>{i + 1}</td>
                      <td>{order.name}</td>
                      <td>{order.quantity}</td>
                      <td className="right bold">₹{order.offer_price}</td>
                      <td className="bold">₹{order.total_tax}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>

            <table className="line-items-container has-bottom-border">
              <thead>
                <tr>
                  <th>Payment Info</th>
                  <th>Total</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="payment-info">
                    <div>
                      <strong>ONLINE</strong>
                    </div>
                  </td>

                  <td className="large total">₹{item.items_cost}</td>
                </tr>
              </tbody>
            </table>

            <div className="footer">
              <div className="footer-info">
                <span>sales@ezykle.com</span> |<span>+(91) 9154213932</span> |
                <span>ezykle.com</span>
              </div>
              <div className="footer-thanks">
                {/* <img src="https://github.com/anvilco/html-pdf-invoice-template/raw/main/img/heart.png" alt="heart"> */}
                <span>Thank you!</span>
                <br />
              </div>
              <h1>.</h1>
            </div>
          </div>
          <div
            className="flex justify-between items-center"
            style={{ alignItems: "center" }}
          >
            <button
              type="button"
              className="w-[116px] h-[46px]"
              style={{ background: "#862860", color: "white" }}
              onClick={() => navigate(-1)}
            >
              Back
            </button>
            <button
              type="button"
              className="w-[116px] h-[46px]"
              style={{ background: "#862860", color: "white" }}
              onClick={() =>
                generatePDF(targetRef, options, { filename: "Invoice.pdf" })
              }
            >
              Download
            </button>
          </div>
        </div>
      </div>
    </Layout>
  );
}
