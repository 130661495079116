import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Star from "../Helpers/icons/Star";
import {
  addWishListItemsRequest,
  carListByIdRequest,
  getWishListItemsRequest,
  productAddToCartByIdRequest,
  removeWishListItemsRequest,
} from "./httppactions/httpactions";

import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { CartListContext } from "../../contextProviders/cartContextProvider";
import { WishListContext } from "../../contextProviders/wishListContextProvider";
import InputCom from "../Helpers/InputCom";

export default function ProductView({
  className,
  reportHandler,
  productInfo,
  loadingHandler,
  alertHandler,
}) {
  const productsImg = [
    {
      id: 1,
      src: `${productInfo.name.toLowerCase()}1.png`,
      color: "#FFBC63",
    },
    {
      id: 2,
      src: `${productInfo.name.toLowerCase()}2.png`,
      color: "#649EFF",
    },
    {
      id: 3,
      src: `${productInfo.name.toLowerCase()}3.png`,
      color: "#FFFFFF",
    },
    {
      id: 4,
      src: `${productInfo.name.toLowerCase()}4.png`,
      color: "#FF7173",
    },
  ];

  const [src, setSrc] = useState(productsImg[0].src);
  const [productDetails, setProductDetails] = useState(productInfo);
  const changeImgHandler = (current) => {
    setSrc(current);
  };
  const [quantity, setQuantity] = useState(1);
  const dispatch = useDispatch();
  const increment = () => {
    if (productInfo.quantity > quantity) {
      setQuantity((prev) => prev + 1);
    }
  };
  const decrement = () => {
    if (quantity > 1) {
      setQuantity((prev) => prev - 1);
    }
  };

  const [wishList, setWishList] = useContext(WishListContext);
  const [cartList, setCartList] = useContext(CartListContext);
  const [paymentType, setPaymentType] = useState("");
  const [partialAmount, setPartialAmount] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    console.log(productInfo.wishListId, "Product view mama");
    //eslint-disable-next-line
  }, []);

  const addToWishListTapped = async () => {
    console.log("Add to wishlist tapped");
    const userId = localStorage.getItem("userId");
    productDetails.wishListId != null
      ? removeWhishList(userId)
      : addToWishList(userId);
  };

  const addToWishList = async (userId) => {
    if (userId == null || userId == "") {
      alert("Please login to add wishlist");
    } else {
      loadingHandler(true);
      await dispatch(
        addWishListItemsRequest(
          { userId: localStorage.getItem("userId"), productId: productInfo.id },
          dispatch
        )
      ).then((response) => {
        loadingHandler(false);

        if (response.statusCode == 200) {
          // alert("Product added to wishlist.");
          setProductDetails({ ...productDetails, wishListId: "22" });
          getWhishListData();
          alertHandler({ message: "Product added to wishlist." });
        } else {
          // alert("Unable to add wishlist. Try again");
          alertHandler({ message: "Unable to add to wishlist. Try again" });
        }
      });
    }
  };

  const removeWhishList = async (userId) => {
    if (userId == null || userId == "") {
      alert("Please login to remove wishlist");
    } else {
      loadingHandler(true);
      await dispatch(
        removeWishListItemsRequest(
          { userId: localStorage.getItem("userId"), productId: productInfo.id },
          dispatch
        )
      ).then((response) => {
        loadingHandler(false);
        if (response.statusCode == 200) {
          // alert("Product removed to wishlist.");
          setProductDetails({ ...productDetails, wishListId: null });
          getWhishListData();
          alertHandler({ message: "Product removed from wishlist." });
        } else {
          // alert("Unable to remove wishlist. Try again");
          alertHandler({
            message: "Unable to remove from  wishlist. Try again",
          });
        }
      });
    }
  };

  const getWhishListData = async () => {
    const userId = localStorage.getItem("userId");
    if (userId != null && userId != "") {
      console.log("w-list data calling");
      await dispatch(
        getWishListItemsRequest(
          { userId: localStorage.getItem("userId") },
          dispatch
        )
      ).then((response) => {
        if (response.result) {
          setWishList(response.result);
        } else {
          setWishList([]);
        }
      });
    } else {
      console.log("w-list data nulll");
      setWishList([]);
    }
  };

  const getCartData = async () => {
    const userId = localStorage.getItem("userId");
    if (userId != null && userId != "") {
      await dispatch(
        carListByIdRequest({ userId: localStorage.getItem("userId") }, dispatch)
      ).then((response) => {
        if (response.result) {
          console.log(response.result.length);
          setCartList(response.result);
        } else {
          setCartList([]);
        }
      });
    } else {
      setCartList([]);
    }
  };

  const addToCartTapped = async () => {
    const userId = localStorage.getItem("userId");
    if (userId == null || userId == "") {
      alert("Please login to add item to cart");
    } else {
      loadingHandler(true);
      await dispatch(
        productAddToCartByIdRequest(
          {
            userId: localStorage.getItem("userId"),
            productId: productInfo.id,
            quantity: quantity,
          },
          dispatch
        )
      ).then((response) => {
        loadingHandler(false);
        if (response.statusCode == 200) {
          getCartData();
          // setca;
          alertHandler({ message: "Product added to cart successfully." });
        } else {
          // alert("Unable to add item to cart. Try again");
          alertHandler({ message: "Unable to add item to cart. Try again" });
        }
      });
    }
  };

  const handleRadioInputChanges = (e) => {
    console.log(e.target.value);
    setPaymentType(e.target.value);
  };

  const handleChanges = (e) => {
    setPartialAmount(e.target.value);
    console.log(e.target.value);
  };

  const buyNowTapped = () => {
    const userId = localStorage.getItem("userId");
    if (userId == null || userId == "") {
      alert("Please login to buy.");
    } else {
      if (paymentType == "") {
        alert("Please select Payment type.");
      } else if (paymentType == "FULL_PAYMENT") {
        navigateToCartPage();
      } else if (paymentType == "PARTIAL_PAYMENT") {
        if (partialAmount == "") {
          alert("Please enter amount");
        } else if (partialAmount < 999) {
          alert("Please enter amount minimum ₹999");
        } else {
          navigateToCartPage(true);
        }
      } else {
        alert("Unexpected error.");
      }
    }
  };

  const navigateToCartPage = (flag = false) => {
    productDetails.cartQuantity = 1;
    console.log(
      "Buy now tapped ",
      productDetails,
      parseFloat(
        productInfo.discounted_price + productInfo.discounted_price * 0.05
      ).toFixed(2),
      {
        cartTotal: parseFloat(
          productInfo.discounted_price + productInfo.discounted_price * 0.05
        ).toFixed(2),
        itemsCost: productInfo.mrp,
        offerPrice: productInfo.discounted_price,
        taxTotal: productInfo.discounted_price * 0.05,
        totalItems: 1,
        totalQuanity: quantity,
      }
    );
    if (flag == false) {
      const data = productDetails;
      data['cartQuantity'] = quantity;

      console.log("Data ", data)
      navigate("/checkout", {
        state: {
          list: [data],
          totalPrice: productInfo.discounted_price,
          discount: productInfo.mrp - productInfo.discounted_price,
          priceResult: {
            cartTotal: parseFloat(
              productInfo.discounted_price + productInfo.discounted_price * 0.05
            ).toFixed(2),
            itemsCost: parseFloat(
              productInfo.discounted_price + productInfo.discounted_price * 0.05
            ).toFixed(2),
            offerPrice: productInfo.discounted_price,
            taxTotal: productInfo.discounted_price * 0.05,
            totalItems: quantity,
            totalQuanity: quantity,
          },
          isSingleProduct: true,
        },
      });
    } else {
      const data = productDetails;
      data['cartQuantity'] = quantity;

      console.log("Data ", data);
      navigate("/checkout", {
        state: {
          list: [data],
          totalPrice: parseFloat(partialAmount).toFixed(2),
          priceResult: {
            cartTotal: parseFloat(partialAmount).toFixed(2),
            itemsCost: parseFloat(partialAmount).toFixed(2),
            offerPrice: partialAmount,
            taxTotal: 0,
            totalItems: quantity,
            totalQuanity: quantity,
          },
          isSingleProduct: true,
        },
      });

    }
  };

  return (
    <div
      className={`product-view w-full lg:flex justify-between ${
        className || ""
      }`}
    >
      <div data-aos="fade-right" className="lg:w-1/2 xl:mr-[70px] lg:mr-[50px]">
        <div className="w-full">
          <div className="w-full h-[500px] border border-qgray-border flex justify-center items-center overflow-hidden relative mb-3">
            <img
              src={`${process.env.PUBLIC_URL}/assets/images/${src}`}
              alt=""
              className="object-contain"
            />
            {/* <div className="w-[80px] h-[80px] rounded-full bg-qyellow text-qblack flex justify-center items-center text-xl font-medium absolute left-[30px] top-[30px]">
              <span>-50%</span>
            </div> */}
          </div>
          <div className="flex gap-2 flex-wrap">
            {productsImg &&
              productsImg.length > 0 &&
              productsImg.map((img) => (
                <div
                  onClick={() => changeImgHandler(img.src)}
                  key={img.id}
                  className="w-[110px] h-[110px] p-[15px] border border-qgray-border cursor-pointer"
                >
                  <img
                    src={`${process.env.PUBLIC_URL}/assets/images/${img.src}`}
                    alt=""
                    className={`w-full h-full object-contain ${
                      src !== img.src ? "opacity-50" : ""
                    } `}
                  />
                </div>
              ))}
          </div>
        </div>
      </div>
      <div className="flex-1">
        <div className="product-details w-full mt-10 lg:mt-0">
          <span
            data-aos="fade-up"
            className="text-qgray text-xs font-normal uppercase tracking-wider mb-2 inline-block"
          >
            Eco Automotive
          </span>
          <p
            data-aos="fade-up"
            className="text-xl font-medium text-qblack mb-4"
          >
            {productInfo.name}
          </p>

          <div
            data-aos="fade-up"
            className="flex space-x-[10px] items-center mb-6"
          >
            <div className="flex">
              <Star />
              <Star />
              <Star />
              <Star />
              <Star />
            </div>
            <span className="text-[13px] font-normal text-qblack">
              0 Reviews
            </span>
          </div>

          {productInfo.category_id == "14" ? (
            <></>
          ) : (
            <div
              data-aos="fade-up"
              className="flex space-x-2 items-center mb-7"
            >
              <span className="text-sm font-500 text-qgray line-through mt-2">
                ₹{productInfo.mrp}
              </span>
              <span className="text-2xl font-500 text-qred">
                ₹{productInfo.discounted_price}
              </span>
            </div>
          )}

          <p
            data-aos="fade-up"
            className="text-qgray text-sm text-normal mb-[30px] leading-7"
          >
            Available Products : {productInfo.quantity}
          </p>

          <p
            data-aos="fade-up"
            className="text-qgray text-sm text-normal mb-[30px] leading-7"
          >
            {productInfo.description}
          </p>

          <div data-aos="fade-up" className="product-size mb-[30px]">
            <span className="text-sm font-normal uppercase text-qgray mb-[14px] inline-block">
              SELECT
            </span>
            <div className="w-full">
              <div className=" h-[50px] flex justify-between items-center px-6">
                <div className="w-[120px] h-full px-[8px] flex items-center border border-qgray-border">
                  <div className="flex justify-between items-center w-full">
                    <button
                      onClick={decrement}
                      type="button"
                      className="text-base text-qgray w-[30px]"
                    >
                      -
                    </button>
                    <span className="text-qblack">{quantity}</span>
                    <button
                      onClick={increment}
                      type="button"
                      className="text-base text-qgray w-[30px]"
                    >
                      +
                    </button>
                  </div>
                </div>
                <div className="w-[60px] h-full flex justify-center items-center border border-qgray-border">
                  <button type="button" onClick={() => addToWishListTapped()}>
                    <span>
                      {productDetails.wishListId != null ? (
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="red"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M17 1C14.9 1 13.1 2.1 12 3.7C10.9 2.1 9.1 1 7 1C3.7 1 1 3.7 1 7C1 13 12 22 12 22C12 22 23 13 23 7C23 3.7 20.3 1 17 1Z"
                            stroke="#D5D5D5"
                            strokeWidth="2"
                            strokeMiterlimit="10"
                            strokeLinecap="square"
                          />
                        </svg>
                      ) : (
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M17 1C14.9 1 13.1 2.1 12 3.7C10.9 2.1 9.1 1 7 1C3.7 1 1 3.7 1 7C1 13 12 22 12 22C12 22 23 13 23 7C23 3.7 20.3 1 17 1Z"
                            stroke="#D5D5D5"
                            strokeWidth="2"
                            strokeMiterlimit="10"
                            strokeLinecap="square"
                          />
                        </svg>
                      )}
                    </span>
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div
            data-aos="fade-up"
            className="quantity-card-wrapper w-full flex items-center h-[50px] space-x-[10px] mb-[30px]"
          >
            <div className="flex-1 h-full">
              {/* <button
                onClick={() => addToCartTapped()}
                type="button"
                className="bg-fuchsia-900 text-sm text-white font-semibold w-full h-full"
              >
                Add To Cart
              </button> */}
            </div>
            <div className="flex-1 h-full">
              {/* <button
                type="button"
                className="bg-fuchsia-900 text-sm text-white font-semibold w-full h-full"
              >
                BUY NOW
              </button> */}
              <button
                onClick={() => addToCartTapped()}
                type="button"
                className="bg-fuchsia-900 text-sm text-white font-semibold w-full h-full"
              >
                Add To Cart
              </button>
            </div>
          </div>

          <div data-aos="fade-up" className="mb-[20px]">
            <p
              data-aos="fade-up"
              className="text-xl font-medium text-qblack mb-4"
            >
              Buy Now
            </p>
            <div className="shipping mt-[30px]">
              <ul className="flex flex-col space-y-1">
                <li>
                  <div className="flex space-x-2.5 items-center mb-5">
                    <div className="input-radio">
                      <input
                        type="radio"
                        name="price"
                        className="btn-blue"
                        id="fullpayment"
                        value="FULL_PAYMENT"
                        onClick={(e) => handleRadioInputChanges(e)}
                      />
                    </div>
                    <label
                      htmlFor="bank"
                      className="text-[18px] text-normal text-qblack"
                    >
                      Full Payment
                    </label>
                  </div>
                </li>
                <li>
                  <div className="flex space-x-2.5 items-center mb-5">
                    <div className="input-radio">
                      <input
                        type="radio"
                        name="price"
                        id="partialpayment"
                        style={{ background: "#862860", color: "#862860" }}
                        value="PARTIAL_PAYMENT"
                        onClick={(e) => handleRadioInputChanges(e)}
                      />
                    </div>
                    <label
                      htmlFor="delivery"
                      className="text-[18px] text-normal text-qblack"
                    >
                      Partial payment
                    </label>
                    <div className="input-item mb-5">
                      <InputCom
                        placeholder="Please enter amount"
                        label="Amount*"
                        name="amount"
                        type="number"
                        inputClasses="h-[50px]"
                        inputHandler={(e) => handleChanges(e)}
                      />
                    </div>
                  </div>
                </li>
              </ul>
              <p
                data-aos="fade-up"
                className="text-qgray text-sm text-normal mb-[30px] leading-7"
              >
                Balance payment to be made 10 days prior to delivery
                <br />* Booking amount is not refundable.
              </p>
            </div>
            <div className="flex-1 h-[50px]">
              <button
                type="button"
                className="bg-fuchsia-900 text-sm text-white font-semibold w-full h-full"
                onClick={() => buyNowTapped()}
              >
                Buy Now
              </button>
            </div>
          </div>

          <div
            data-aos="fade-up"
            className="flex space-x-2 items-center mb-[20px]"
          >
            <span></span>
          </div>
        </div>
      </div>
    </div>
  );
}

// Any Name 5123456789012346 07/2025 123
