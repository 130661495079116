import { useCallback, useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import { CartListContext } from "../../contextProviders/cartContextProvider";
import LoadingHud from "../../hud/loadinghud";
import { BASE_URL } from "../../utils/ApiUrl";
import { getUserAddressListRequest } from "../Auth/authActions/httpactions";
import DataIteration from "../Helpers/DataIteration";
import PageTitle from "../Helpers/PageTitle";
import Layout from "../Partials/Layout";
import {
  checkOutInitiatePayment,
  createOrderRequest,
  getCheckoutData,
} from "./httpactions/checkoutservices";

export default function CheakoutPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [cartItems, setCartItems] = useContext(CartListContext);
  const location = useLocation();
  const [products, setProducts] = useState(location.state.list);
  const [totalPrice, setTotalPrice] = useState(location.state.totalPrice);
  const [priceResult, setPriceResult] = useState(location.state.priceResult);
  const [offerPrice, setOfferPrice] = useState(location.state.discount);
  const [isLoading, setIsLoading] = useState(false);
  const [userAddressList, setUserAddressList] = useState([]);
  const [selectedAddressId, setSelectedAddressId] = useState(null);
  const [checkOutData, setCheckOutData] = useState([]);
  var reload = false;

  const buyNowButtonClicked = async () => {
    if (selectedAddressId == null) {
      alert("Please select address.");
    } else {
      window.addEventListener("responseCallbackData", keyPress, false);
      setIsLoading(true);
      var call_url = BASE_URL.BASE_URL + "accessKey";

      await dispatch(
        checkOutInitiatePayment(
          {
            txnid: getRandomId(8),
            amount: totalPrice,
            email: localStorage.getItem("userEmail"),
            phone: localStorage.getItem("userPhone"),
            name: localStorage.getItem("user_first_name"),
          },
          call_url,
          dispatch
        )
      ).then((response) => {
        setIsLoading(false);
        if (response.result.status == 1) {
          window.easeBuzzCheckoutView(response.result.data);
        } else {
          alert("Unable to initiate payment. Please try again later.");
        }
      });
    }
  };

  const redirectToPay = () => {};

  const getRandomId = (length) => {
    let result = "";
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }
    return result;
  };

  const getCheckOutDataReq = async () => {
    await dispatch(
      getCheckoutData({ userId: localStorage.getItem("userId") }, dispatch)
    ).then((response) => {
      console.log(response);
      if (response.status == true) {
        console.log(response.result, "Chk out data resp");
        // setCheckOutData(response.result);
        updateLocalData(response.result);
      }
    });
  };

  const updateLocalData = async (result) => {
    if (location.state.isSingleProduct == true) {
      await localStorage.setItem(
        "checkout_data",
        JSON.stringify(location.state.list)
      );
    } else {
      await localStorage.setItem("checkout_data", JSON.stringify(result));
    }
    await localStorage.setItem("pricetags", JSON.stringify(priceResult));
  };

  const proceedtoCreateOrder = async () => {
    if (reload == false) {
      reload = true;
      setIsLoading(true);
      console.log(localStorage.getItem("selected_addressID"), "sele addID");
      console.log(JSON.parse(localStorage.getItem("checkout_data")), "chk dta");
      const priceRes = JSON.parse(localStorage.getItem("pricetags"));
      console.log(priceRes, "Price list");
      await dispatch(
        createOrderRequest(
          {
            userId: localStorage.getItem("userId"),
            addressId: localStorage.getItem("selected_addressID"),
            items: JSON.parse(localStorage.getItem("checkout_data")),
            totalItems: priceRes.totalItems,
            itemsCost: priceRes.itemsCost,
            offerPrice: priceRes.offerPrice,
            totalTax: priceRes.taxTotal,
            totalRoundOff: priceRes.itemsCost,
            paymentType: "ONLINE",
          },
          dispatch
        )
      ).then((response) => {
        setIsLoading(false);
        console.log(response);
        localStorage.removeItem("selected_addressID", response.result);
        localStorage.removeItem("checkout_data", response.result);
        localStorage.removeItem("pricetags", response.result);
        alert("Order placed successfully.");
        navigate("/");
      });
    }
  };

  useEffect(() => {
    console.log("Hello ... ",location);
    getAddressList();
    getCheckOutDataReq();

    // return () => document.removeEventListener("responseCallbackData", keyPress);
    //eslint-disable-next-line
  }, [1]);

  const keyPress = useCallback(
    (response) => {
      console.log("call back Resp  ", response);
      if (response != null) {
        let pgResponse = response.detail;
        console.log("PG Resp  ", pgResponse);
        if (
          pgResponse.status === "success" &&
          pgResponse.error_Message != null
        ) {
          // alert(pgResponse.status + " -- " + pgResponse.error_Message);

          proceedtoCreateOrder();
        } else if (pgResponse.error_Message != null) {
          switch (pgResponse.status) {
            case "userCancelled":
              alert("User Cancelled");
              return;
            default:
              alert(
                pgResponse.status +
                  " -- " +
                  pgResponse.error_Message +
                  pgResponse.cancellation_reason
              );
              return;
          }
        } else {
          alert("Oops !! Something went wrong.");
        }
      }
    },
    [location]
  );

  const getAddressList = async () => {
    setIsLoading(true);
    await dispatch(
      getUserAddressListRequest(
        { id: localStorage.getItem("userId") },
        dispatch
      )
    ).then((response) => {
      setIsLoading(false);
      console.log(response);
      if (response.status == true) {
        setUserAddressList(response.result);
      } else {
        alert("Unable to get data");
      }
    });
  };

  const addressOnChangeSelected = async (e) => {
    console.log(e, "Selected Address id");
    setSelectedAddressId(e.id);
    await localStorage.setItem("selected_addressID", e.id);
  };

  return (
    <>
      {isLoading && <LoadingHud />}
      <Layout childrenClasses="pt-0 pb-0">
        <div className="checkout-page-wrapper w-full bg-white pb-[60px]">
          <div className="w-full mb-5">
            <PageTitle
              title="Checkout"
              breadcrumb={[
                { name: "home", path: "/" },
                { name: "checkout", path: "/checkout" },
              ]}
            />
          </div>
          <div className="checkout-main-content w-full">
            <div className="container-x mx-auto">
              <div className="w-full lg:flex lg:space-x-[30px]">
                <div className="lg:w-1/2 w-full">
                  <h1 className="sm:text-2xl text-xl text-qblack font-medium mb-5">
                    Address Details
                  </h1>
                  <div className="w-full px-10 py-[30px] border border-[#EDEDED] flex justify-left">
                    <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                      {/* table heading */}
                      <thead>
                        <tr className="text-base text-qgray whitespace-nowrap px-2 border-b default-border-bottom ">
                          <th className="py-4 block whitespace-nowrap w-[60px]">
                            Select
                          </th>
                          <th className="py-4 whitespace-nowrap">Address</th>
                        </tr>
                      </thead>
                      <tbody>
                        {userAddressList.map((datas, i) => {
                          return (
                            <tr className="bg-white border-b hover:bg-gray-50">
                              <td className="text-center">
                                <input
                                  type="radio"
                                  className="accent-pink-500"
                                  value={datas.id}
                                  checked={selectedAddressId == datas.id}
                                  onChange={() =>
                                    addressOnChangeSelected(datas)
                                  }
                                />
                              </td>
                              <td className="py-4 px-1">
                                <span className="">
                                  {datas.house_no +
                                    ", " +
                                    datas.street +
                                    ", " +
                                    datas.locality +
                                    ", " +
                                    datas.area +
                                    ", " +
                                    datas.city +
                                    ", " +
                                    datas.state +
                                    ", " +
                                    "India" +
                                    ". Landmark: " +
                                    datas.landmark}
                                </span>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                  <div className="w-[180px] h-[50px] mt-4">
                    <button
                      type="button"
                      className="blue-btn"
                      onClick={() => {
                        navigate("/addaddress", {
                          state: {
                            isCheckout: true
                          },
                        });
                      }}
                    >
                      <div className="w-full text-sm font-semibold">
                        Add New Address
                      </div>
                    </button>
                  </div>
                </div>
                <div className="flex-1">
                  <h1 className="sm:text-2xl text-xl text-qblack font-medium mb-5">
                    Order Summary
                  </h1>

                  <div className="w-full px-10 py-[30px] border border-[#EDEDED]">
                    <div className="sub-total mb-6">
                      <div className=" flex justify-between mb-5">
                        <p className="text-[13px] font-medium text-qblack uppercase">
                          PROduct
                        </p>
                        <p className="text-[13px] font-medium text-qblack uppercase">
                          total
                        </p>
                      </div>
                      <div className="w-full h-[1px] bg-[#EDEDED]"></div>
                    </div>
                    <DataIteration
                      datas={products}
                      startLength={0}
                      endLength={products.length}
                    >
                      {({ datas }) => (
                        <div className="product-list w-full mb-[30px]">
                          <ul className="flex flex-col space-y-5">
                            <li>
                              <div className="flex justify-between items-center">
                                <div>
                                  <h4 className="text-[15px] text-qblack mb-2.5">
                                    {datas.name}
                                    <sup className="text-[13px] text-qgray ml-2 mt-2">
                                      x{datas.cartQuantity}
                                    </sup>
                                  </h4>
                                </div>
                                <div>
                                  <span className="text-[15px] text-qblack font-medium">
                                    ₹
                                    {datas.cartQuantity *
                                      datas.discounted_price}
                                  </span>
                                </div>
                              </div>
                            </li>
                          </ul>
                        </div>
                      )}
                    </DataIteration>
                    <div className="w-full h-[1px] bg-[#EDEDED]"></div>
                    {/* {console.log("Locat ... ", lo)} */}
                    <div className="mt-[30px]">
                      <div className=" flex justify-between mb-5">
                        <p className="text-[13px] font-medium text-qblack uppercase">
                          Your savings
                        </p>
                        <p className="text-[15px] font-medium text-qblack uppercase">
                          ₹{offerPrice}
                        </p>
                      </div>
                      <div className=" flex justify-between mb-5">
                        <p className="text-[13px] font-medium text-qblack uppercase">
                        GST Included
                        </p>
                        <p className="text-[15px] font-medium text-qblack uppercase">
                          5%
                        </p>
                      </div>
                    </div>
                    <div className="w-full mt-[30px]">
                      <div className="sub-total mb-6">
                        <div className=" flex justify-between mb-5">
                          <div>
                            <span className="text-xs text-qgraytwo mb-3 block">
                              SHIPPING
                            </span>
                          </div>
                          <p className="text-xs text-qgraytwo mb-3 block">₹0</p>
                        </div>
                        <div className="w-full h-[1px] bg-[#EDEDED]"></div>
                      </div>
                    </div>

                    <div className="mt-[30px]">
                      <div className=" flex justify-between mb-5">
                        <p className="text-2xl font-medium text-qblack">
                          Total
                        </p>
                        <p className="text-2xl font-medium text-qred">
                          ₹{totalPrice}
                        </p>
                      </div>
                    </div>
                    <div className="shipping mt-[30px]">
                      <ul className="flex flex-col space-y-1">
                        <li>
                          <div className="flex space-x-2.5 items-center mb-5">
                            <div className="input-radio">
                              <input
                                type="radio"
                                name="price"
                                className="accent-pink-500"
                                id="bank"
                                checked
                              />
                            </div>
                            <label
                              htmlFor="bank"
                              className="text-[18px] text-normal text-qblack"
                            >
                              Online Payment
                            </label>
                          </div>
                        </li>
                        {/* <li>
                            <div className="flex space-x-2.5 items-center mb-5">
                              <div className="input-radio">
                                <input
                                  type="radio"
                                  name="price"
                                  className="accent-pink-500"
                                  id="delivery"
                                />
                              </div>
                              <label
                                htmlFor="delivery"
                                className="text-[18px] text-normal text-qblack"
                              >
                                Cash on Delivery
                              </label>
                            </div>
                          </li> */}
                      </ul>
                      <p
                        data-aos="fade-up"
                        className="text-qgray text-sm text-normal mb-[30px] leading-7"
                      >
                        <br />* Booking amount is not refundable.
                      </p>
                    </div>
                    <div
                      className="w-full h-[50px] black-btn flex justify-center items-center"
                      onClick={() => buyNowButtonClicked()}
                    >
                      <button id="ezyklepayment">Place Order Now</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
}

// Any Name 5123456789012346 07/2025 123
