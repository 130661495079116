import { Helmet } from "react-helmet-async";
import PageTitle from "../Helpers/PageTitle";
import Layout from "../Partials/Layout";

export default function VirtualShowroom() {
  return (
    <Layout>
      <PageTitle
        breadcrumb={[
          { name: "home", path: "/" },
          { name: "Virtual Showroom", path: "/v-showroom" },
        ]}
        title="Virtual Showroom"
      />
      <Helmet>
        <meta name="Keyword" content="" />
        <meta
          name="SEO title"
          content="Virtual Showroom | Explore E-Cycles Online | Ezykle"
        />
        <meta
          name="Description Line"
          content="Experience our virtual showroom and explore our range of e-cycles from the comfort of your home. Find your perfect ride today"
        />
        <meta name="Final URL" content="https://ezykle.com/#/v-showroom" />
        <meta
          name="description"
          content="Experience our virtual showroom and explore our range of e-cycles from the comfort of your home. Find your perfect ride today"
        />
        <link rel="canonical" href="https://ezykle.com/#/v-showroom" />
        <meta name="author" content="Goutham" />
      </Helmet>

      <div className="cart-page-wrapper w-full">
        <div className="container-x mx-auto">
          <div className="empty-card-wrapper w-full">
            <div className="flex justify-center items-center w-full">
              {/* <video width="800" height="500" controls>
                <source src={myvideo} type="video/mp4" />
              </video> */}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}
