import { useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { CartListContext } from "../../contextProviders/cartContextProvider";
import BreadcrumbCom from "../BreadcrumbCom";
import EmptyCardError from "../EmptyCardError";
import PageTitle from "../Helpers/PageTitle";
import Layout from "../Partials/Layout";
import {
  carListByIdRequest,
  carTotalByIdRequest,
  clearCartItemsByIDRequest,
} from "../SingleProductPage/httppactions/httpactions";
import ProductsTable from "./ProductsTable";

export default function CardPage({ cart = false }) {
  const [cartListData, setCartListData] = useContext(CartListContext);
  const [isCartList, setIsCartList] = useState(cart);
  const dispatch = useDispatch();

  const [cartList, setCartList] = useContext(CartListContext);
  const [price, setPrice] = useState(0);
  const [tax, setTax] = useState(0);
  const [totalPrice, setTotalPrice] = useState(0);
  const [discount, setDiscount] = useState(0);
  const navigate = useNavigate();
  const [priceResult, setPriceResult] = useState({});
  useEffect(() => {
    if (cartListData.length) {
      setIsCartList(true);
      getUpdatedPrice(cartList);
    } else {
      setIsCartList(false);
    }

    //eslint-disable-next-line
  }, [cartListData.length]);

  const clearCart = async () => {
    await dispatch(
      clearCartItemsByIDRequest(
        { userId: localStorage.getItem("userId") },
        dispatch
      )
    ).then((response) => {
      if (response.statusCode == 200) {
        alert("Removed successfully");
        getCartData();
      } else {
        alert("Unable to remove");
      }
    });
  };

  const getCartData = async () => {
    const userId = localStorage.getItem("userId");
    if (userId != null && userId != "") {
      await dispatch(
        carListByIdRequest({ userId: localStorage.getItem("userId") }, dispatch)
      ).then((response) => {
        if (response.result) {
          setCartList(response.result);
        } else {
          setCartList([]);
        }
      });
    } else {
      setCartList([]);
    }
  };

  const getUpdatedPrice = async (e) => {
    await dispatch(
      carTotalByIdRequest({ userId: localStorage.getItem("userId") }, dispatch)
    ).then((response) => {
      console.log("total ", response);
      if (response.status == true) {
        setPrice(parseFloat(response.result.offerPrice).toFixed(2));
        setTax(parseFloat(response.result.taxTotal).toFixed(2));
        setTotalPrice(parseFloat(response.result.cartTotal).toFixed(2));
        setDiscount(parseFloat(response.result.discount).toFixed(2))
        setPriceResult(response.result);
        getCartData();
      }
    });
  };

  const proceedToCheckOut = (e) => {
    navigate("/checkout", {
      state: {
        list: cartList,
        totalPrice: totalPrice,
        priceResult: priceResult,
        isSingleProduct: false,
        discount: discount
      },
    });

    // console.log(cartList, "Ok 1", totalPrice, "Ok 2", priceResult);
  };

  return (
    <Layout childrenClasses={cart ? "pt-0 pb-0" : ""}>
      {isCartList === false ? (
        <div className="cart-page-wrapper w-full">
          <div className="container-x mx-auto">
            <BreadcrumbCom
              paths={[
                { name: "home", path: "/" },
                { name: "cart", path: "/cart" },
              ]}
            />
            <EmptyCardError />
          </div>
        </div>
      ) : (
        <div className="cart-page-wrapper w-full bg-white pb-[60px]">
          <div className="w-full">
            <PageTitle
              title="Your Cart"
              breadcrumb={[
                { name: "home", path: "/" },
                { name: "cart", path: "/cart" },
              ]}
            />
          </div>
          <div className="w-full mt-[23px]">
            <div className="container-x mx-auto">
              <ProductsTable
                className="mb-[30px]"
                data={cartListData}
                handler={(e) => {
                  getUpdatedPrice(e);
                }}
              />
              <div className="w-full sm:flex justify-end">
                {/* <div className="discount-code sm:w-[270px] w-full mb-5 sm:mb-0 h-[50px] flex">
                  <div className="flex-1 h-full">
                    <InputCom type="text" placeholder="Discount Code" />
                  </div>
                  <button type="button" className="w-[90px] h-[50px] black-btn">
                    <span className="text-sm font-semibold">Apply</span>
                  </button>
                </div> */}
                <div className="flex space-x-2.5 items-center">
                  <Link to="/">
                    <div className="w-[220px] h-[50px] bg-[#F6F6F6] flex justify-center items-center">
                      <span className="text-sm font-semibold">
                        Continue Shopping
                      </span>
                    </div>
                  </Link>
                  <Link>
                    <div className="w-[140px] h-[50px] bg-[#F6F6F6] flex justify-center items-center">
                      <span
                        className="text-sm font-semibold"
                        onClick={() => clearCart()}
                      >
                        Clear Cart
                      </span>
                    </div>
                  </Link>
                </div>
              </div>
              <div className="w-full mt-[30px] flex sm:justify-end">
                <div className="sm:w-[370px] w-full border border-[#EDEDED] px-[30px] py-[26px]">
                  <div className="sub-total mb-6">
                    <div className=" flex justify-between mb-6">
                      <p className="text-[15px] font-medium text-qblack">
                        Subtotal
                      </p>
                      <p className="text-[15px] font-medium text-qred">
                        ₹{price}
                      </p>
                    </div>
                    <div className=" flex justify-between mb-6">
                      <p className="text-xs text-qgraytwo mb-3 block">GST Included</p>
                      <p className="text-xs text-qgraytwo mb-3 block">₹{tax}</p>
                    </div>
                    <div className="w-full h-[1px] bg-[#EDEDED]"></div>
                  </div>
                  {/* <div className="shipping mb-6 flex justify-between">
                    <span className="text-[15px] font-medium text-qblack mb-[18px] block">
                      Shipping
                    </span>
                    <span className="text-[13px] text-normal text-qgraytwo">
                      ₹0.00
                    </span>
                  </div> */}
                  <div className="total mb-6">
                    <div className=" flex justify-between">
                      <p className="text-[18px] font-medium text-qblack">
                        Total
                      </p>
                      <p className="text-[18px] font-medium text-qred">
                        ₹{totalPrice}
                      </p>
                    </div>
                  </div>
                  <div
                    className="w-full h-[50px] black-btn flex justify-center items-center cursor-pointer"
                    onClick={proceedToCheckOut}
                  >
                    <span className="text-sm font-semibold">
                      Proceed to Checkout
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </Layout>
  );
}
