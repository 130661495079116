import { useNavigate } from "react-router-dom";

export default function EventCard({ className, datas, type }) {
  const navigate = useNavigate();
  const showProductDetails = (e) => {
    navigate("/single-product", { state: e });
  };
  return (
    <div
      data-aos="fade-left"
      className={`product-row-card-style-one w-full h-[250px] bg-white group relative overflow-hidden ${
        className || ""
      }`}
    >
      <img
        className="h-full"
        src={`/assets/images/events${datas.imgName}.png`}
        alt="thumb"
        style={{
          height: "100%",
          width: "100%",
          "object-fit": "contain",
        }}
      />
    </div>
  );
}
