import { useRef } from "react";
import SimpleSlider from "../Helpers/SliderCom";

export default function BodyBannerPage({
  className,
  lastDate,
  counterbg,
  appscreen,
}) {
  const sliderRef = useRef(null);
  const settings = {
    dots: true,
    infinite: true,
    autoplay: true,
    fade: true,
    arrows: true,
  };
  return (
    <div>
      <div className={`w-full lg:h-[460px] pb-[30px] ${className || ""}`}>
        <div className="container-x mx-auto h-full">
          <SimpleSlider settings={settings} selector={sliderRef}>
            <div className="item w-full xl:h-[450px]">
              <div
                className="w-full h-full relative"
                style={{
                  // backgroundImage: `url(/assets/images/banner1.jpg)`,
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                }}
              >
                <img
                  src="/assets/images/body1.png"
                  alt="thumb"
                  style={{
                    height: "100%",
                    width: "100%",
                    "object-fit": "contain",
                  }}
                />
              </div>
            </div>
            <div className="item w-full xl:h-[450px]">
              <div
                style={{
                  // backgroundImage: `url(/assets/images/banner2.jpg)`,
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                }}
                className="w-full h-full relative"
              >
                <img
                  src="/assets/images/body2.png"
                  alt="thumb"
                  style={{
                    height: "100%",
                    width: "100%",
                    "object-fit": "contain",
                  }}
                />
              </div>
            </div>
          </SimpleSlider>
        </div>
      </div>
    </div>
  );
}
