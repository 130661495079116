import { Route, Routes } from "react-router-dom";
import Accessories from "./accessories";
import Assurance from "./assurance";
import About from "./components/About";
import AllProductPage from "./components/AllProductPage";
import Login from "./components/Auth/Login/index";
import Profile from "./components/Auth/Profile";
import AddAddress from "./components/Auth/Profile/tabs/AddAddreess";
import Invoice from "./components/Auth/Profile/tabs/Invoice.jsx";
import Signup from "./components/Auth/Signup";
import BecomeSaller from "./components/BecomeSaller";
import Blogs from "./components/Blogs";
import Blog from "./components/Blogs/Blog.jsx";
import CardPage from "./components/CartPage";
import CheakoutPage from "./components/CheakoutPage";
import Contact from "./components/Contact";
import DealerPage from "./components/Delarship";
import Disclaimer from "./components/Disclaimer";
import Faq from "./components/Faq";
import FlashSale from "./components/FlashSale";
import FleetPage from "./components/Fleet";
import FourZeroFour from "./components/FourZeroFour";
import HomeFive from "./components/HomeFive";
import HomeFour from "./components/HomeFour";
import HomeThree from "./components/HomeThree";
import HomeTwo from "./components/HomeTwo";
import NoInterNetConn from "./components/NoInterNetConn/nointernetconn";
import PrivacyPolicy from "./components/PrivacyPolicy";
import ProductsCompaire from "./components/ProductsCompaire/index";
import SallerPage from "./components/SallerPage";
import Sallers from "./components/Sellers";
import SingleProductPage from "./components/SingleProductPage";
import TermsCondition from "./components/TermsCondition/index";
import TrackingOrder from "./components/TrackingOrder";
import Wishlist from "./components/Wishlist";
import EventsPage from "./components/events";
import ShippingPolicy from "./components/shippingpolicy/index.js";
import VirtualShowroom from "./components/virtualshowroom";

export default function Routers() {
  return (
    <Routes>
      <Route exact path="/" element={<HomeThree />} />
      <Route exact path="/home-two" element={<HomeTwo />} />
      <Route exact path="/home-three" element={<HomeThree />} />
      <Route exact path="/home-four" element={<HomeFour />} />
      <Route exact path="/home-five" element={<HomeFive />} />
      <Route exact path="/all-products" element={<AllProductPage />} />
      <Route exact path="/single-product" element={<SingleProductPage />} />
      <Route exact path="/cart" element={<CardPage />} />
      <Route exact path="/checkout" element={<CheakoutPage />} />
      <Route exact path="/wishlist" element={<Wishlist />} />
      <Route exact path="/flash-sale" element={<FlashSale />} />
      <Route exact path="/saller-page" element={<SallerPage />} />
      <Route exact path="/products-compaire" element={<ProductsCompaire />} />
      <Route exact path="/sallers" element={<Sallers />} />
      <Route exact path="/about" element={<About />} />
      <Route exact path="/blogs" element={<Blogs />} />
      <Route exact path="/blogs/blog" element={<Blog />} />
      <Route exact path="/tracking-order" element={<TrackingOrder />} />
      <Route exact path="/contact" element={<Contact />} />
      <Route exact path="/faq" element={<Faq />} />
      <Route exact path="/login" element={<Login />} />
      <Route exact path="/signup" element={<Signup />} />
      <Route exact path="/profile" element={<Profile />} />
      <Route exact path="/become-saller" element={<BecomeSaller />} />
      <Route exact path="/privacy-policy" element={<PrivacyPolicy />} />
      <Route exact path="/terms-condition" element={<TermsCondition />} />
      <Route exact path="/accessories" element={<Accessories />} />
      <Route exact path="*" element={<FourZeroFour />} />
      <Route exact path="/nointernet" element={<NoInterNetConn />} />
      <Route exact path="/assurance" element={<Assurance />} />
      <Route exact path="/v-showroom" element={<VirtualShowroom />} />
      <Route exact path="/events" element={<EventsPage />} />
      <Route exact path="/fleet" element={<FleetPage />} />
      <Route exact path="/dealer" element={<DealerPage />} />
      <Route exact path="/disclaimer" element={<Disclaimer />} />
      <Route exact path="/addaddress" element={<AddAddress />} />
      <Route exact path="/invoice" element={<Invoice />} />
      <Route exact path="/shipping" element={<ShippingPolicy />} />
    </Routes>
  );
}
