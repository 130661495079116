import { Helmet } from "react-helmet-async";
import Accodion from "../Helpers/Accodion";
import PaymentCard from "../Helpers/Cards/PaymentCard";
import ServiceCard from "../Helpers/Cards/ServiceCard";
import PageTitle from "../Helpers/PageTitle";
import Layout from "../Partials/Layout";

export default function Faq() {
  return (
    <Layout childrenClasses="pt-0 pb-0">
      <Helmet>
        <meta name="Keyword" content="" />
        <meta
          name="SEO title"
          content="Discover E-Cycle FAQs: Your Ultimate Guide | Ezykle"
        />
        <meta
          name="Description Line"
          content="Find answers to frequently asked questions about e-cycles. Get all the information you need to know about electric bicycles."
        />
        <meta name="Final URL" content="https://ezykle.com/#/faq" />
        <meta name="author" content="Goutham" />
        <meta
          name="description"
          content="Find answers to frequently asked questions about e-cycles. Get all the information you need to know about electric bicycles."
        />
        <link rel="canonical" href="https://ezykle.com/#/faq" />
      </Helmet>
      <div className="faq-page-wrapper w-full mb-10">
        <div className="page-title w-full">
          <PageTitle
            title="Support"
            breadcrumb={[
              { name: "home", path: "/" },
              { name: "support", path: "/faq" },
            ]}
          />
        </div>
      </div>
      <div className="w-full py-[60px]">
        <div className="container-x mx-auto">
          <div className="w-full">
            <div className="grid md:grid-cols-2 grid-cols-1 lg:gap-[30px] gap-5">
              <ServiceCard />
              <PaymentCard />
            </div>
          </div>
        </div>
      </div>
      <div className="contact-wrapper w-full mb-10">
        <div className="container-x mx-auto">
          <div className="main-wrapper w-full lg:flex lg:space-x-[30px]">
            <div className="lg:w-1/2 w-full mb-10 lg:mb-0">
              <h1 className="text-qblack font-bold text-[22px] mb-4">
                Frequently asked questions
              </h1>
              <div className="flex flex-col space-y-7 justify-between">
                <Accodion
                  title="01. How does the Pedal Assist mode work?"
                  des="To ride on Pedal Assist mode, turn on the power button on the keypad, choose one of the 5 levels of power boost and start pedalling. The power boost kicks in as soon as you start pedalling. You can change the power gears on the move. Please note, you will need to pedal for the power to kick in."
                />
                <Accodion
                  title="02. How does the Throttle mode work?"
                  des="To ride on the throttle mode, turn on the power button on the keypad, start pedalling. Once the bike is on the move, push the throttle down to power ahead with/without pedalling."
                />
                <Accodion
                  title="03. Is the battery pack detachable from the bike?"
                  des="Yes, for some models its applicable the battery pack is detachable from the bike and you can carry it with you. "
                />
                <Accodion
                  title="04. What is the life of the battery pack?"
                  des="The life of Li-on battery packs is measured in terms of charge cycles. The battery packs have a life of 500 charge cycles. Essentially, the battery life depends on how often you charge it. On an average, the batteries will have a life of 2 to 3 years.
(Note: The battery packs come with a warranty of 18 months)"
                />
                <Accodion
                  title="05. What is the life of the motor?"
                  des="The conservative estimate of the motor life is 3 years, and if the electric bike is taken good care of, the motor life can go up to 5 years.
(Note: The motor comes with a warranty of 18 months)"
                />
                <Accodion
                  title="06. Does the electric bike come fully assembled or is there any assembling required?"
                  des="The electric bike comes to you 85% assembled, in a well-packaged carton box. You will need very little assembly to do before your cycle is ready to ride. With the “Get your cycle ready” video that comes along with the instructions, assembling your bike won’t be difficult at all. In fact, you might love doing it."
                />
                <Accodion
                  title="07. What if my delivery is faulty or the product is damaged?"
                  des="We hope you never have to experience that. However, such things can happen and if it does, please give us a ring, message us or write to us. We will arrange for the replacement to be delivered."
                />
                <Accodion
                  title="08. What’s the best part about an e-cycle?"
                  des="Electric bikes break down barriers of riding. It comes with assist, an incredibly intuitive, vital tool for commuting that gives you an option to pedal, go full throttle or use pedal assist. E-cycles are also eco-friendly, ensure less physical exertion than regular cycles and help you save money on fuel and maintenance."
                />
                <Accodion
                  title="09. How often does this need servicing?"
                  des="Our e-Bicycle is maintenance Free. However, Health check-ups are Scheduled."
                />
                <Accodion
                  title="10. Does law mandate for a helmet to ride?"
                  des="No, but for your safety, we strictly recommend that you wear one when you ride."
                />
                <Accodion
                  title="11. How I can book the service for my e-bike?"
                  des="There are two ways You can directly go to dealer service station or book an appointment through App."
                />
              </div>
            </div>
            <div className="flex-1">
              <h1 className="text-qblack font-bold text-[22px] mb-4">
                RETURN & CANCELLATION
              </h1>
              <div className="details">
                <p className="text-qgraytwo text-[15px] leading-[30px] mb-3">
                  We really hope you are thrilled on your first Electric Bike.
                  However, if for some reason if you have changed your mind and
                  wish to cancel your order, we offer to refund you the full
                  purchase price (subject to certain terms and conditions)
                  within 28 days(This is only applicable for online buyers, who
                  have not done the test rides).
                </p>
              </div>
              <div className="flex flex-col space-y-7 justify-between">
                <Accodion
                  title="1. Cancellation before shipment"
                  des="If you wish to cancel the ordered electric bike prior to its shipment, you can write to our customer support team with a subject line 'Order cancellation - << Order number >> ' and reason for the cancellation in the body of the e-mail. We will verify the cancellation order and refund the money paid by you within 28 days to the same account/card that was used while making the payment for bookings."
                />
                <Accodion
                  title="2. Cancellation post shipment"
                  des="If you wish to cancel the order, which has already been handed over to the courier company from our end, they may still attempt to deliver the Electric Bike. However please do not accept the delivery of the order stating that the cancellation request has been placed and write to our customer support team with a subject line 'Order cancellation - << Order number >> '. On receipt of returned power bike package, we will refund the money paid by you after deducting the shipment cost, within 28 days to the same account/card that was used while making the payment for bookings."
                />
                <Accodion
                  title="3. Cancellation after opening the package"
                  des="We hope you love your EZYKLE after opening the package. However, if you are not fully satisfied with your purchase and wish to return the Electric Bike, then you must write to us within 7 days of receiving your power bike. The return shall be accepted if the bike is in its original condition and package, accompanied by the original proof of sale including all manuals, charger, battery, keys and accessories that came standard or were installed on the bike by our service department. We will arrange for the return pick up and the return shipping cost will be borne by you. However, the aforesaid return policy shall apply only if the electric bike has not been used on road and hence, it is advisable to check and test ride before placing the order."
                />
                <Accodion
                  title="4. Damaged, defective item, or wrong product delivered"
                  des="Our shipments go through rigorous quality check processes before they leave our warehouse. However, in any of the event that your product is damaged during shipment or transit, you can request for a replacement. If you have received an item in a damaged/defective condition or have been sent a wrong product, you can write to us within 3 days of receiving the order providing your order details and we will initiate the replacement process of either the whole or defective part(s) of the bike within 7 working days ."
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}
