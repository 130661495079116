import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import Routers from "./Routers";
import { getSubCategoriesRequest } from "./components/AllProductPage/httpactions/httpactions";
import ScrollToTop from "./components/ScrollToTop";
import {
  carListByIdRequest,
  getWishListItemsRequest,
} from "./components/SingleProductPage/httppactions/httpactions";
import ShareMenu from "./components/share";
import {
  AccessoriesContext,
  AccessoriesContextProvider,
} from "./contextProviders/accessoriesContextProvider";
import { CartListContext } from "./contextProviders/cartContextProvider";
import { WishListContext } from "./contextProviders/wishListContextProvider";

function App() {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [accessories, setAccessories] = useState([]);
  const [wishList, setWishList] = useState([]);
  const [cartList, setCartList] = useState([]);

  useEffect(() => {
    getSubCategories();
    getWhishListData();
    getCartData();
    window.scrollTo(0, 0);
    return () => {
      document.body.classList.remove("home-two");
      document.body.classList.remove("home-four");
      document.body.classList.remove("home-five");
      document.body.classList.remove("home-one");
      document.body.classList.add("home-three");
    };
    //eslint-disable-next-line
  }, []);

  const getSubCategories = async () => {
    navigator.onLine
      ? await dispatch(getSubCategoriesRequest({ id: 14 }, dispatch)).then(
          (response) => {
            if (response.result) {
              setAccessories(response.result);
            } else {
              setAccessories([]);
            }
          }
        )
      : navigate("/nointernet");
  };

  const getWhishListData = async () => {
    const userId = localStorage.getItem("userId");
    if (userId != null && userId != "") {
      console.log("w-list data calling");
      await dispatch(
        getWishListItemsRequest(
          { userId: localStorage.getItem("userId") },
          dispatch
        )
      ).then((response) => {
        if (response.result) {
          setWishList(response.result);
        } else {
          setWishList([]);
        }
      });
    } else {
      console.log("w-list data nulll");
      setWishList([]);
    }
  };

  const getCartData = async () => {
    const userId = localStorage.getItem("userId");
    if (userId != null && userId != "") {
      await dispatch(
        carListByIdRequest({ userId: localStorage.getItem("userId") }, dispatch)
      ).then((response) => {
        if (response.result) {
          console.log(response.result.length);
          setCartList(response.result);
        } else {
          setCartList([]);
        }
      });
    } else {
      setCartList([]);
    }
  };

  return (
    <AccessoriesContextProvider>
      <AccessoriesContext.Provider value={[accessories, setAccessories]}>
        <WishListContext.Provider value={[wishList, setWishList]}>
          <CartListContext.Provider value={[cartList, setCartList]}>
            <ShareMenu></ShareMenu>
            <ScrollToTop />
            <Routers />
          </CartListContext.Provider>
        </WishListContext.Provider>
      </AccessoriesContext.Provider>
    </AccessoriesContextProvider>
  );
}

export default App;
