import { useEffect, useState } from "react";
import "react-input-range/lib/css/index.css";
import { useDispatch } from "react-redux";
import blog from "../../data/blogs.json";
import BlogCard from "../Helpers/Cards/BlogCard";
import DataIteration from "../Helpers/DataIteration";
import PageTitle from "../Helpers/PageTitle";
import Layout from "../Partials/Layout";

export default function EventsPage() {
  const [isLoading, setIsLoading] = useState(false);

  const [products, setProducts] = useState([
    {
      id: 24,
      imgName: 1,
      name: "SanthoshamAwards",
      description:
        "The 21st edition of these prestigious awards is now slated to be held at the JRC Convention in Hyderabad. The organisers announced that the award show will begin from 3:30 PM onwards and will provide 12 hours of nonstop entertainment.",
      category_id: "4",
      subcategory_id: "3",
      quantity: "10",
      mrp: "52498.95",
      c_gst: "2.5",
      s_gst: "2.5",
      discounted_price: "49999",
      created_at: "0000-00-00 00:00:00",
      updated_at: "2023-10-23T08:31:26.000Z",
      deleted_at: "2023-10-18T12:33:02.000Z",
      status: "active",
    },
    {
      id: 24,
      name: "ComedyEvent",
      imgName: 2,
      description:
        "We teamed up with the folks at Blacklist Promotions to bring you a handpicked line-up of local comedians. Not for the fainthearted, and not for the easily offended - these comedians tell it like it is, local stories from real people, with a diversity on stage that matches our community. ",
      category_id: "4",
      subcategory_id: "3",
      quantity: "10",
      mrp: "52498.95",
      c_gst: "2.5",
      s_gst: "2.5",
      discounted_price: "49999",
      created_at: "0000-00-00 00:00:00",
      updated_at: "2023-10-23T08:31:26.000Z",
      deleted_at: "2023-10-18T12:33:02.000Z",
      status: "active",
    },
  ]);
  const dispatch = useDispatch();

  useEffect(() => {
    window.scrollTo(0, 60);
    // getAllProducts();

    //eslint-disable-next-line
  }, []);

  //   const getAllProducts = async () => {
  //     setIsLoading(true);
  //     await dispatch(
  //       getAllProductsRequest(
  //         { userId: localStorage.getItem("userId") },
  //         dispatch
  //       )
  //     ).then((response) => {
  //       setIsLoading(false);
  //       if (response !== undefined) {
  //         console.log(response.result);
  //         setProducts(response.result);
  //       }
  //     });
  //   };

  // return (
  //   <>
  //     {isLoading && <LoadingHud />}
  //     <Layout>
  //       <Helmet>
  //         <meta name="Keyword" content="" />
  //         <meta
  //           name="SEO title"
  //           content="E-Cycle Events| Join the Electric Revolution | Ezykle"
  //         />
  //         <meta
  //           name="Description Line"
  //           content="Explore upcoming e-cycle events and be part of the electric revolution. Connect with like-minded enthusiasts and discover new riding experiences."
  //         />
  //         <meta name="Final URL" content="https://ezykle.com/#/events" />
  //         <meta name="author" content="Goutham" />
  //         <meta
  //         name="description"
  //         content="Explore upcoming e-cycle events and be part of the electric revolution. Connect with like-minded enthusiasts and discover new riding experiences."
  //       />
  //       <link rel="canonical" href="https://ezykle.com/#/events" />
  //       </Helmet>
  //       <div className="products-page-wrapper w-full">
  //         <div className="container-x mx-auto">
  //           <BreadcrumbCom />
  //           <div className="w-full lg:flex lg:space-x-[30px]">
  //             <div className="flex-1">
  //               <SectionStyleTwo type={3} products={products} isEvents={true} />
  //             </div>
  //           </div>
  //         </div>
  //       </div>
  //     </Layout>
  //   </>
  // );

  return (
    <Layout childrenClasses="pt-0 pb-0">
      <div className="blogs-wrapper w-full-width">
        <div className="title-bar">
          <PageTitle
            title="Our Blogs"
            breadcrumb={[
              { name: "home", path: "/" },
              { name: "blogs", path: "/blogs" },
            ]}
          />
        </div>
      </div>

      <div className="w-full py-[60px]">
        <div className="container-x mx-auto">
          <div className="w-full">
            <div className="grid md:grid-cols-2 grid-cols-1 lg:gap-[30px] gap-5">
              <DataIteration
                datas={blog.blogs}
                startLength={0}
                endLength={blog.blogs.length}
              >
                {({ datas }) => (
                  <div
                    data-aos="fade-up"
                    key={datas.id}
                    className="item w-full"
                  >
                    <BlogCard datas={datas} />
                  </div>
                )}
              </DataIteration>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}
