import React from 'react';
import './Details.css';

function Details({ data }) {
    const handleTouchStart = (e) => {
        e.currentTarget.classList.toggle('hover');
    };

    const details = [
        { 'name': 'ROOK', 'motor': '250W, 42NM torque, IP-67 sensor', 'power': '250/350 watts', 'speed': '35 KMPH', 'range': '100 KMS', 'battery': 'LI-Ion', 'amp': '7.8Ah, C1 grade, BIS Battery', 'charging': '3-4 HRS', },
        { 'name': 'LIDO', 'motor': '250W, 42NM torque, IP-67 sensor', 'power': '250/350 watts', 'speed': '35 KMPH', 'range': '40 KMS', 'battery': 'LI-Ion', 'amp': '7.8Ah, C1 grade, BIS Battery', 'charging': '3-4 HRS', },
        { 'name': 'RAZE PRO', 'motor': '250W, 40NM torque, IP-67 sensor', 'power': '250/350 watts', 'speed': '35 KMPH', 'range': '40 KMS', 'battery': 'LI-Ion', 'amp': '7.8Ah, C1 grade, BIS Battery', 'charging': '3-4 HRS', },
        { 'name': 'ELIX', 'motor': '250W, 40NM torque, IP-67 sensor', 'power': '250/350 watts', 'speed': '35 KMPH', 'range': '40 KMS', 'battery': 'LI-Ion', 'amp': '7.8Ah, C1 grade, BIS Battery', 'charging': '3-4 HRS', },
        { 'name': 'EZEN', 'motor': '250W, 38NM torque, IP-67 sensor', 'power': '250/350 watts', 'speed': '30 KMPH', 'range': '30 KMS', 'battery': 'LI-Ion', 'amp': '6.6Ah, C1 grade, BIS Battery', 'charging': '3-4 HRS', },
        { 'name': 'RAZE', 'motor': '250W, 42NM torque, IP-67 sensor', 'power': '250/350 watts', 'speed': '35 KMPH', 'range': '40 KMS', 'battery': 'LI-Ion', 'amp': '7.8Ah, C1 grade, BIS Battery', 'charging': '3-4 HRS', },
        { 'name': 'COSMO', 'motor': '250W, 42NM torque, IP-67 sensor', 'power': '250/350 watts', 'speed': '35 KMPH', 'range': '40 KMS', 'battery': 'LI-Ion', 'amp': '7.8Ah, C1 grade, BIS Battery', 'charging': '3-4 HRS', },
        { 'name': 'LARC LITE', 'motor': '250W, 40NM torque, IP-67 sensor', 'power': '250/350 watts', 'speed': '35 KMPH', 'range': '40 KMS', 'battery': 'LI-Ion', 'amp': '7.8Ah, C1 grade, BIS Battery', 'charging': '3-4 HRS', },
        { 'name': 'LARC', 'motor': '250W, 38NM torque, IP-67 sensor', 'power': '250/350 watts', 'speed': '35 KMPH', 'range': '40 KMS', 'battery': 'LI-Ion', 'amp': '7.6Ah, C1 grade, BIS Battery', 'charging': '3-4 HRS', }
    ]

    const product = data
    const filterDetails = (e) => {
        const a = details.filter((product) => {
            if (product.name == e.name) {
                return product
            }
        })
        if (a.length > 0) {
            return a[0]
        }
    }


    return (
        <div className="wrapper">
            <div className="cols">
                <div className="col" onTouchStart={handleTouchStart}>
                    <div className="details-container">
                        <div className="front" >
                            <div className="inner">
                                <div className='details-img'>
                                    <img src='/assets/images/speedo.png' alt='speedo' className='img-fluid' />
                                </div>
                                <p>Performance</p>
                                <span>{product.name}</span>
                            </div>
                        </div>
                        <div className="back">
                            <div className="inner">
                                <div className='text-container'>
                                    <ul>
                                        <li>Motor Type : {filterDetails(product).motor}</li>
                                        <li>Power : {filterDetails(product).power}</li>
                                        <li>Top Speed : {filterDetails(product).speed}</li>
                                        <li>Range : {filterDetails(product).range}</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col" onTouchStart={handleTouchStart}>
                    <div className="details-container">
                        <div className="front" >
                            <div className="inner">
                                <div className='details-img'>
                                    <img src='/assets/images/battery.png' alt='speedo' className='img-fluid' />
                                </div>
                                <p>Battery</p>
                                <span>{product.name}</span>
                            </div>
                        </div>
                        <div className="back">
                            <div className="inner">
                                <div className='text-container'>
                                    <ul>
                                        <li>Battery Type : {filterDetails(product).battery}</li>
                                        <li>Battery Amp : {filterDetails(product).amp}</li>
                                        <li>Charging Time : {filterDetails(product).charging}</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col" onTouchStart={handleTouchStart}>
                    <div className="details-container">
                        <div className="front" >
                            <div className="inner">
                                <div className='details-img'>
                                    <img src='/assets/images/wheel.png' alt='speedo' className='img-fluid' />
                                </div>
                                <p>Wheel</p>
                                <span>{product.name}</span>
                            </div>
                        </div>
                        <div className="back">
                            <div className="inner">
                                <div className='text-container'>
                                    <ul>
                                        <li>Wheel Type : Alloy Rim </li>
                                        <li>Wheel : 27" </li>
                                        <li>Tyre Size(rear and front) : 26"X4.00 </li>
                                        <li>Tyre Type : Nylon High Grip</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col" onTouchStart={handleTouchStart}>
                    <div className="details-container">
                        <div className="front">
                            <div className="inner">
                                <div className='details-img'>
                                    <img src='/assets/images/disc.png' alt='speedo' className='img-fluid' />
                                </div>
                                <p>Disc</p>
                                <span>{product.name}</span>
                            </div>
                        </div>
                        <div className="back">
                            <div className="inner">
                                <div className='text-container'>
                                    <ul>
                                        <p>Powerful and Secure dual disc breaks provide ultimate safety and control</p>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="cols">
                <div className="col" onTouchStart={handleTouchStart}>
                    <div className="details-container">
                        <div className="front" >
                            <div className="inner">
                                <div className='details-img'>
                                    <img src='/assets/images/tracking.png' alt='speedo' className='img-fluid' />
                                </div>
                                <p>Tracking</p>
                                <span>{product.name}</span>
                            </div>
                        </div>
                        <div className="back">
                            <div className="inner">
                                <div className='text-container'>
                                    <ul>
                                        <li>Tracking Your Bicycle</li>
                                        <li>View Trip History</li>
                                        <li>ET Alerts on Safety and Security</li>
                                        <li>Geo Fencing</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col" onTouchStart={handleTouchStart}>
                    <div className="details-container">
                        <div className="front" >
                            <div className="inner">
                                <div className='details-img'>
                                    <img src='/assets/images/puncture.png' alt='speedo' className='img-fluid' />
                                </div>
                                <p>Puncture Resistence</p>
                                <span>{product.name}</span>
                            </div>
                        </div>
                        <div className="back">
                            <div className="inner">
                                <div className='text-container'>
                                    <ul>
                                        <p>The wheels combine beauty and durability, as they are engineered with precision</p>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col" onTouchStart={handleTouchStart}>
                    <div className="details-container">
                        <div className="front" >
                            <div className="inner">
                                <div className='details-img'>
                                    <img src='/assets/images/light.png' alt='speedo' className='img-fluid' />
                                </div>
                                <p>HeadLight</p>
                                <span>{product.name}</span>
                            </div>
                        </div>
                        <div className="back">
                            <div className="inner">
                                <div className='text-container'>
                                    <ul>
                                        <p>Visibility is improved under low light conditions, ensuring a safe ride</p>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col" onTouchStart={handleTouchStart}>
                    <div className="details-container">
                        <div className="front">
                            <div className="inner">
                                <div className='details-img'>
                                    <img src='/assets/images/seat.png' alt='speedo' className='img-fluid' />
                                </div>
                                <p>Saddle</p>
                                <span>{product.name}</span>
                            </div>
                        </div>
                        <div className="back">
                            <div className="inner">
                                <div className='text-container'>
                                    <ul>
                                       <p>Memory Foam cushion with Suspension</p>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Details;
