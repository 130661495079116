import { FaBook, FaIdCard } from "react-icons/fa";
import { Link } from "react-router-dom";
import Facebook from "../../../Helpers/icons/Facebook";
import Instagram from "../../../Helpers/icons/Instagram";
import Youtube from "../../../Helpers/icons/Youtube";

export default function Footer({ type }) {
  return (
    <footer className="footer-section-wrapper bg-white print:hidden">
      <div className="container-x block mx-auto pt-[56px]">
        <div className="w-full flex flex-col items-center mb-[50px]">
          <div className="mb-[40px]">
            <Link to="/">
              <img
                width="152"
                height="36"
                src={`${process.env.PUBLIC_URL}/assets/images/logo.png`}
                alt="logo"
              />
            </Link>
          </div>
          <div className="w-full h-[1px] bg-[#E9E9E9]"></div>
        </div>
        <div className="lg:flex justify-between mb-[50px]">
          {/* <div className="lg:w-[400px]  ml-0 w-full mb-10 lg:mb-0">
            
          </div> */}
          <div className="lg:w-1/3 w-full mb-10 lg:mb-0">
            <div className="mb-5">
              <h6 className="text-[18] font-500 text-[#2F2F2F] ml-11">Quick Links</h6>
            </div>
            <div style={{ display: "flex", flexFlow: "row" }}>
              <Link to="/about" className="p-5">
                <FaIdCard
                  color="white"
                  style={{
                    width: 25,
                    height: 25,
                    paddingBottom: 5,
                    backgroundColor: "#830860",
                  }}
                />
                <span className="text-[#9A9A9A] text-[15px] hover:text-qblack border-b border-transparent hover:border-qblack cursor-pointer capitalize">
                  About Us
                </span>
              </Link>
              <Link to="/faq" className="p-5">
                <FaBook
                  color="white"
                  style={{
                    width: 25,
                    height: 25,
                    paddingBottom: 5,
                    backgroundColor: "#830860",
                  }}
                />
                <span className="text-[#9A9A9A] text-[15px] hover:text-qblack border-b border-transparent hover:border-qblack cursor-pointer capitalize">
                  FAQ
                </span>
              </Link>
            </div>
          </div>
          <div className="lg:w-1/3 w-full mb-10 lg:mb-0">
            <div className="mb-5">
              <h6 className="text-[18] font-500 text-[#2F2F2F] ml-0">
                Support Links
              </h6>
            </div>
            <div>
              <ul className="flex flex-col space-y-4 ">
                <li>
                  <Link to="/terms-condition">
                    <span className="text-[#9A9A9A] text-[15px] hover:text-qblack border-b border-transparent hover:border-qblack cursor-pointer capitalize">
                      Terms & Conditions
                    </span>
                  </Link>
                </li>
                <li>
                  <Link to="/faq">
                    <span className="text-[#9A9A9A] text-[15px] hover:text-qblack border-b border-transparent hover:border-qblack cursor-pointer capitalize">
                      Refund & Return policies
                    </span>
                  </Link>
                </li>
                <li>
                  <Link to="/shipping">
                    <span className="text-[#9A9A9A] text-[15px] hover:text-qblack border-b border-transparent hover:border-qblack cursor-pointer capitalize">
                      Shipping policy
                    </span>
                  </Link>
                </li>
                <li>
                  <Link to="/disclaimer">
                    <span className="text-[#9A9A9A] text-[15px] hover:text-qblack border-b border-transparent hover:border-qblack cursor-pointer capitalize">
                      Disclaimer
                    </span>
                  </Link>
                </li>
              </ul>
            </div>
          </div>
          <div className="lg:w-1/3 lg:flex lg:flex-col items-center w-full mb-10 lg:mb-0 ">
            <div>
              <div className="mb-5">
                <h6 className="text-[18] font-500 text-[#2F2F2F] ml-0">
                  General Links
                </h6>
              </div>
              <div>
                <ul className="flex flex-col space-y-4 ">
                  <li>
                    <Link to="/tracking-order">
                      <span className="text-[#9A9A9A] text-[15px] hover:text-qblack border-b border-transparent hover:border-qblack cursor-pointer capitalize">
                        Tracking Order
                      </span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/dealer">
                      <span className="text-[#9A9A9A] text-[15px] hover:text-qblack border-b border-transparent hover:border-qblack cursor-pointer capitalize">
                        Become Dealer
                      </span>
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="lg:w-1/3 lg:flex lg:flex-col items-center w-full mb-10 lg:mb-0">
            <div>
              <div className="mb-5">
                <h6 className="text-[18] font-500 text-[#2F2F2F] ml-0">Helpful</h6>
              </div>
              <div>
                <ul className="flex flex-col space-y-4 ">
                  <li>
                    <Link to="/flash-sale">
                      <span className="text-[#9A9A9A] text-[15px] hover:text-qblack border-b border-transparent hover:border-qblack cursor-pointer capitalize">
                        Flash Sale
                      </span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/faq">
                      <span className="text-[#9A9A9A] text-[15px] hover:text-qblack border-b border-transparent hover:border-qblack cursor-pointer capitalize">
                        Support
                      </span>
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          {/* <div className="flex-1 lg:flex">
            
          </div> */}
        </div>
        <div className="bottom-bar border-t border-qgray-border lg:h-[82px] lg:flex justify-between items-center">
          <div className="flex lg:space-x-5 justify-between items-center mb-3">
            <div className="flex space-x-5 items-center">
              <Link to="#">
                <Instagram className="fill-current text-qgray hover:text-qblack" />
              </Link>
              <Link to="#">
                <Facebook className="fill-current text-qgray hover:text-qblack" />
              </Link>
              <Link to="#">
                <Youtube className="fill-current text-qgray hover:text-qblack" />
              </Link>
            </div>
            <span className="sm:text-base text-[10px] text-qgray font-300">
              ©2023 Powered by Eco-Automotive Private Limited
            </span>
          </div>
          <div className="">
            <Link to="#">
              <img
                width="318"
                height="28"
                src={`${process.env.PUBLIC_URL}/assets/images/payment-getways.png`}
                alt="payment-getways"
              />
            </Link>
          </div>
        </div>
      </div>
    </footer>
  );
}
