import React from "react";
import { HashLoader } from "react-spinners";

const LoadingHud = () => {
  return (
    <div
      style={{
        backgroundColor: "rgba(0,0,0,0.5)",
        width: "100%",
        height: "100%",
        position: "fixed",
        zIndex: "999",
        top: 0,
      }}
    >
      <div
        style={{
          position: "fixed",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <div
          className="center"
          style={{
            alignItems: "center",
          }}
        >
          <div className="loaderDivCenter">
            <HashLoader color="#830860" loading size={100} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoadingHud;
