import React from 'react';
import './Specifications.css';


function Specifications({data}) {
  const description = [
    {'name' : 'ROOK', 'description' : 'Carries up to 130 kgs. Engineered for the demands of Indian roads. Explore endless possibilities with a variety of accessories and ride the future with Rook!', 'speed': '35 KMPH', 'Range': '100 KMS', 'title': "India's Best Electric Cycle"},
    {'name' : 'LIDO', 'description' : 'LIDO offers a balance of power and comfort. Ideal for both daily commutes and weekend adventures, LIDO is built to deliver a superior riding experience', 'speed': '35 KMPH', 'Range': '40 KMS', 'title': 'Perfect Balance between Power and Comfort'},
    {'name' : 'RAZE PRO', 'description' : 'RAZE PRO is built for performance enthusiasts. With top-tier specs and a robust build, this electric cycle takes your rides to the next level', 'speed': '35 KMPH', 'Range': '40 KMS', 'title': 'Best Performance Enthusiasts' },
    {'name' : 'ELIX', 'description' : 'ELIX combines innovative technology with a comfortable ride, making it the perfect choice for city dwellers. Stay ahead with ELIX’s modern electric cycling.', 'speed': '35 KMPH', 'Range': '40 KMS', 'title': 'Perfect Choice for City Dwellers' },
    {'name' : 'EZEN', 'description' : 'EZEN is your ultimate companion for effortless rides. Its seamless integration of technology and design ensures a comfortable and eco-friendly journey.', 'speed': '30 KMPH', 'Range': '30 KMS', 'title': 'Best Companion for Effortless rides' },
    {'name' : 'RAZE', 'description' : 'RAZE is designed for those who seek adventure and efficiency. With its powerful motor and sleek design, every ride is a thrill.', 'speed': '35 KMPH', 'Range': '40 KMS', 'title': 'Seeking Adventure and Efficiency' },
    {'name' : 'COSMO', 'description' : 'Cosmo blends style and performance, making it an excellent choice for modern urban commuters. Enjoy a smooth, stylish ride with Cosmo.', 'speed': '35 KMPH', 'Range': '40 KMS', 'title': 'Best Style and Performance' },
    {'name' : 'LARC LITE', 'description' : 'LARC LITE offers a lightweight and compact solution for city commuting. Enjoy the ease of mobility with this efficient electric cycle.', 'speed': '35 KMPH', 'Range': '40 KMS', 'title': 'Lightweight and Compact Solution for City rides' },
    {'name' : 'LARC', 'description' : 'LARC offers exceptional performance and durability. Ideal for both commuting and leisure, LARC is designed to meet your daily transportation needs.', 'speed': '35 KMPH', 'Range': '40 KMS', 'title': 'Exceptional Performance and Durability' }
  ]
  const product = data
  const filterProduct = (e) => {
   const a = description.filter((product)=>{
    if (product.name == e.name){
      return product
    }
   })
   console.log(data, e, a)
   if (a.length > 0){
    return a[0]
   }
  }
  
  return (
    <div className='container-fluid'>
      <div className="card">
        <div className='rook'>
          <h4 className='title'>About {product.name}</h4>
        </div>
        <div>
          <h1 className='heading'>{filterProduct(product).title}</h1>
        </div><br /><br/>
        <div>
          <h6 className='spec-desc'>{filterProduct(product).description}</h6>
        </div><br />
        <div className='flex-container'>
          <div>
            <img src='/assets/images/speedo.png' alt='speedo' className='card-image' />
            <span>
              <p className='speedo-text'>Top Speed</p>
              <p className='speedo-text'>{filterProduct(product).speed}</p>
            </span>
          </div>
          <div>
            <img src='/assets/images/battery.png' alt='battery' className='card-image' />
            <span>
              <p className='speedo-text'>Range</p>
              <p className='speedo-text'>UPTO {filterProduct(product).Range}</p>
            </span>
          </div>
          <div>
            <img src='/assets/images/tracking.png' alt='tracking' className='card-image' />
            <span>
              <p className='speedo-text'>Bicycle</p>
              <p className='speedo-text'>Tracking</p>
            </span>
          </div>
          <div>
            <img src='/assets/images/call.png' alt='call' className='card-image' />
            <span>
              <p className='speedo-text'>Emergency</p>
              <p className='speedo-text'>Calls</p>
            </span>
          </div>
          <div>
            <img src='/assets/images/city.png' alt='city' className='card-image' />
            <span>
              <p className='speedo-text'>Perfect for</p>
              <p className='speedo-text'>Short City Rides</p>
            </span>
          </div>
        </div><br />
        <div className='flex-container'>
          <div>
            <img src='/assets/images/easy.png' alt='emi' className='img'/>
          </div>
          <div>
            <img src='/assets/images/waranty.png' alt='emi' className='img' />
          </div>
          <div>
            <img src='/assets/images/delivery.png' alt='emi' className='img' />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Specifications;


