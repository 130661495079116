import EventCard from "./Cards/EventCard";
import ProductCardRowStyleOne from "./Cards/ProductCardRowStyleOne";
import DataIteration from "./DataIteration";

export default function SectionStyleTwo({
  className,
  products,
  type,
  isEvents = false,
}) {
  return (
    <div
      className={`section-content w-full grid sm:grid-cols-2 grid-cols-1 xl:gap-[30px] gap-5 ${
        className || ""
      }`}
    >
      {isEvents ? (
        <DataIteration
          datas={products}
          startLength={0}
          endLength={products.length}
        >
          {({ datas }) => (
            <div key={datas.id} className="item w-full">
              <EventCard type={type} datas={datas} />
            </div>
          )}
        </DataIteration>
      ) : (
        <DataIteration datas={products} startLength={0} endLength={4}>
          {({ datas }) => (
            <div key={datas.id} className="item w-full">
              <ProductCardRowStyleOne type={type} datas={datas} />
            </div>
          )}
        </DataIteration>
      )}
    </div>
  );
}
