import { useContext } from "react";
import { Link } from "react-router-dom";
import { CartListContext } from "../../contextProviders/cartContextProvider";
import DataIteration from "../Helpers/DataIteration";

export default function Cart({ className, type }) {
  const [cartList, setCartList] = useContext(CartListContext);
  return (
    <>
      <div
        style={{ boxShadow: " 0px 15px 50px 0px rgba(0, 0, 0, 0.14)" }}
        className={`w-[300px] bg-white border-t-[3px] ${
          type === 3 ? "border-qh3-blue" : "cart-wrappwer"
        }  ${className || ""}`}
      >
        <div className="w-full h-full">
          <div className="product-items h-[240px] overflow-y-scroll">
            <DataIteration
              datas={cartList}
              startLength={0}
              endLength={cartList.length}
            >
              {({ datas }) => (
                <ul>
                  <li className="w-full h-full flex">
                    <div className="flex space-x-[6px] justify-center items-center px-4 my-[10px]">
                      <div className="w-[65px] h-full">
                        <img
                          src={`${
                            process.env.PUBLIC_URL
                          }/assets/images/${datas.name.toLowerCase()}1.png`}
                          alt=""
                          className="w-full h-full object-contain"
                        />
                      </div>
                      <div className="flex-1 h-full flex flex-col justify-center ">
                        <p className="title mb-2 text-[13px] font-600 text-qblack leading-4 line-clamp-2 hover:text-blue-600">
                          {datas.name}
                        </p>

                        <p className="price">
                          <span className="offer-price text-qred font-600 text-[15px] ml-2">
                            ₹{datas.discounted_price}
                          </span>
                        </p>
                      </div>
                    </div>
                  </li>
                </ul>
              )}
            </DataIteration>
          </div>
          <div className="w-full px-4 mt-[20px] mb-[12px]">
            <div className="h-[1px] bg-[#F0F1F3]"></div>
          </div>
          <div className="product-actions px-4 mb-[30px]">
            {/* <div className="total-equation flex justify-between items-center mb-[28px]">
              <span className="text-[15px] font-500 text-qblack">Subtotal</span>
              <span className="text-[15px] font-500 text-qred ">$3</span>
            </div> */}
            <div className="product-action-btn">
              <Link to="/cart">
                <div className="gray-btn w-full h-[50px] mb-[10px] ">
                  <span>View Cart</span>
                </div>
              </Link>
              {/* <Link>
                <div className="w-full h-[50px]">
                  <div className={type === 3 ? "blue-btn" : "yellow-btn"}>
                    <span className="text-sm">Checkout Now</span>
                  </div>
                </div>
              </Link> */}
            </div>
          </div>
          <div className="w-full px-4 mt-[20px]">
            <div className="h-[1px] bg-[#F0F1F3]"></div>
          </div>
        </div>
      </div>
    </>
  );
}
