// import axios from "axios";
import { axiosApi } from "./http-common";

import { BASE_URL } from "../ApiUrl";

class ServiceCalls {
  static headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    "api-access-key": BASE_URL.SECRET_KEY,
  };

  static get(path, data = null, sendToken = false) {
    let url = BASE_URL.BASE_URL + path;
    return axiosApi
      .get(`${url}`, {
        headers: sendToken
          ? {
              ...ServiceCalls.headers,
              Authorization: "Bearer " + localStorage.getItem("authToken"),
            }
          : ServiceCalls.headers,
      })
      .then((response) => {
        if (response.status === 401) {
        } else {
          return response;
        }
      })
      .catch((error) => {
        return error.response;
      });
  }

  static post(path, data = null, sendToken = false) {
    let url = BASE_URL.BASE_URL + path;
    return axiosApi
      .post(`${url}`, data, {
        headers: sendToken
          ? {
              ...ServiceCalls.headers,
              Authorization: "Bearer " + localStorage.getItem("authToken"),
            }
          : ServiceCalls.headers,
      })
      .then((response) => {
        if (response.status === 401) {
        } else {
          return response;
        }
      })
      .catch((error) => {
        return error.response;
      });
  }

  static requestPost(path, data = null, sendToken = false) {
    let url = BASE_URL.BASE_URL + path;

    return axiosApi
      .post(`${url}`, data, {
        headers: sendToken
          ? {
              ...ServiceCalls.headers,
            }
          : ServiceCalls.headers,
      })
      .then((response) => {
        if (response.status === 401) {
        } else {
          return response;
        }
      })
      .catch((error) => {
        return error.response;
      });
  }
  static put(path, data = null, sendToken = false) {
    let url = BASE_URL.BASE_URL + path;
    return axiosApi
      .put(`${url}`, data, {
        headers: sendToken
          ? {
              ...ServiceCalls.headers,
              "api-access-key": BASE_URL.SECRET_KEY,
              Authorization: "Bearer " + localStorage.getItem("authToken"),
            }
          : ServiceCalls.headers,
      })
      .then((response) => {
        if (response.status === 401) {
        } else {
          return response;
        }
      })
      .catch((error) => {
        return error.response;
      });
  }

  static patch(path, data = null, sendToken = false) {
    let url = BASE_URL.BASE_URL + path;
    return axiosApi
      .patch(`${url}`, data, {
        headers: sendToken
          ? {
              ...ServiceCalls.headers,
              "api-access-key": BASE_URL.SECRET_KEY,
              Authorization: "Bearer " + localStorage.getItem("authToken"),
            }
          : ServiceCalls.headers,
      })
      .then((response) => {
        if (response.status === 401) {
        } else {
          return response;
        }
      })
      .catch((error) => {
        return error.response;
      });
  }

  static delete(path, data = null, sendToken = false) {
    let url = BASE_URL.BASE_URL + path;
    return axiosApi
      .delete(`${url}`, {
        headers: sendToken
          ? {
              ...ServiceCalls.headers,
              "api-access-key": BASE_URL.SECRET_KEY,
              Authorization: "Bearer " + localStorage.getItem("authToken"),
            }
          : ServiceCalls.headers,
      })
      .then((response) => {
        if (response.status === 401) {
        } else {
          return response;
        }
      })
      .catch((error) => {
        return error.response;
      });
  }

  /* ***************** EaseBuzz Payment Gateway methods ********************/

  static postPaymentGateway(path, data = null, sendToken = false) {
    let url = path;
    return axiosApi
      .post(`${url}`, data, {
        headers: ServiceCalls.headers,
      })
      .then((response) => {
        if (response.status === 401) {
        } else {
          return response;
        }
      })
      .catch((error) => {
        return error.response;
      });
  }
}

export default ServiceCalls;
