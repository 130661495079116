import ServiceCalls from "../../../utils/services/httpServiceCalls";

export const easebuzzInitiatePayment = (data, url) => async () => {
  try {
    let result = await ServiceCalls.postPaymentGateway(url, data, false);
    return result;
  } catch (error) {
    console.log(error);
    return error;
  }
};
