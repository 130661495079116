import React, { useEffect, useState } from "react";
import { Toast } from "react-bootstrap";
// import { useDispatch, useSelector } from "react-redux";

const AlertView = (props) => {
  const [showMessage, setShowMessage] = useState(props.message);
  useEffect(() => {
    console.log(props);
  });
  return (
    <div
      className="w-full flex justify-end mr-5"
      style={{
        position: "fixed",
        padding: 10,
        zIndex: "999",
      }}
    >
      <div>
        <div
          className="w-[200px]"
          style={{
            padding: "5px",
            backgroundColor: "#04A9F5",
          }}
        >
          <Toast
            style={{ backgroundColor: "#04A9F5", color: "white" }}
            onClose={() => {
              console.log(false);
              props.onClose();
            }}
            show={true}
            delay={3000}
            autohide
          >
            <Toast.Header className="table" closeButton={false}>
              <strong className="mr-auto">Alert !!</strong>
            </Toast.Header>
            <Toast.Body className="table">{showMessage}</Toast.Body>
          </Toast>
        </div>
      </div>
    </div>
  );
};

export default AlertView;
