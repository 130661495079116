import PageTitle from "../Helpers/PageTitle";
import Layout from "../Partials/Layout";

export default function Disclaimer() {
  return (
    <Layout childrenClasses="pt-0 pb-0">
      <div className="terms-condition-page w-full bg-white pb-[30px]">
        <div className="w-full mb-[30px]">
          <PageTitle
            breadcrumb={[
              { name: "home", path: "/" },
              { name: "Disclaimer", path: "/disclaimer" },
            ]}
            title="Disclaimer"
          />
        </div>
        <div className="w-full">
          <div className="container-x mx-auto">
            <div className="content-item w-full mb-10">
              <h2 className="text-[18px] font-medium text-qblack mb-5">
                1. General Information:
              </h2>
              <p className="text-[15px] text-qgraytwo leading-7">
                The information provided on this website is for general
                informational purposes only. While we strive to provide accurate
                and up-to-date information, we make no warranties or
                representations of any kind, either express or implied, about
                the completeness, accuracy, reliability, suitability, or
                availability of the information, products, services, or related
                graphics contained on this website for any purpose. Any reliance
                you place on such information is therefore strictly at your own
                risk.
              </p>
            </div>
            <div className="content-item w-full mb-10">
              <h2 className="text-[18px] font-medium text-qblack mb-5">
                2. Product Descriptions:
              </h2>
              <p className="text-[15px] text-qgraytwo leading-7">
                Product descriptions, specifications, and prices provided on
                this website are subject to change without notice. We do our
                best to ensure that the product information is accurate, but we
                do not guarantee that the information is error-free. We
                recommend verifying all product details with us directly before
                making a purchase.
              </p>
            </div>
            <div className="content-item w-full mb-10">
              <h2 className="text-[18px] font-medium text-qblack mb-5">
                3. Use of Electric Cycles:
              </h2>
              <p className="text-[15px] text-qgraytwo leading-7">
                Electric cycles sold on this website are intended for personal
                use and are not suitable for all environments and conditions.
                Users should familiarize themselves with local laws and
                regulations regarding the use of electric cycles in their area.
                It is the responsibility of the customer to use electric cycles
                in a safe and legal manner.
              </p>
            </div>
            <div className="content-item w-full mb-10">
              <h2 className="text-[18px] font-medium text-qblack mb-5">
                4. Safety:
              </h2>
              <p className="text-[15px] text-qgraytwo leading-7">
                Electric cycles can pose safety risks if not used properly. We
                strongly advise all customers to follow safety guidelines, wear
                appropriate safety gear, and practice safe riding habits. We are
                not responsible for any accidents, injuries, or damages that may
                occur while using our products.
              </p>
            </div>
            <div className="content-item w-full mb-10">
              <h2 className="text-[18px] font-medium text-qblack mb-5">
                5. Warranty:
              </h2>
              <p className="text-[15px] text-qgraytwo leading-7">
                We may offer warranties on some products, and the terms and
                conditions of these warranties will be specified at the time of
                purchase. Customers should review and understand the warranty
                information provided with each product.
              </p>
            </div>
            <div className="content-item w-full mb-10">
              <h2 className="text-[18px] font-medium text-qblack mb-5">
                6. Third-Party Websites:
              </h2>
              <p className="text-[15px] text-qgraytwo leading-7">
                Our website may contain links to third-party websites for your
                convenience. We do not endorse, control, or have any
                responsibility for the content, products, or services offered by
                these third-party websites. Visiting these websites is at your
                own risk.
              </p>
            </div>
            <div className="content-item w-full mb-10">
              <h2 className="text-[18px] font-medium text-qblack mb-5">
                7. Liability:
              </h2>
              <p className="text-[15px] text-qgraytwo leading-7">
                To the extent permitted by law, we shall not be liable for any
                direct, indirect, incidental, consequential, or punitive damages
                arising from your use of this website, any products or services
                purchased through this website, or any information provided on
                this website.
              </p>
            </div>
            <div className="content-item w-full mb-10">
              <h2 className="text-[18px] font-medium text-qblack mb-5">
                8. Legal Compliance:
              </h2>
              <p className="text-[15px] text-qgraytwo leading-7">
                It is the responsibility of the customer to ensure that they
                comply with all local, state, and federal laws and regulations
                related to the purchase and use of electric cycles.
              </p>
            </div>
            <div className="content-item w-full mb-10">
              <h2 className="text-[18px] font-medium text-qblack mb-5">
                9. Changes to Disclaimer:
              </h2>
              <p className="text-[15px] text-qgraytwo leading-7">
                We reserve the right to update or modify this disclaimer at any
                time without prior notice. Any changes will be posted on this
                website.
              </p>
            </div>

            <div className="content-item w-full mb-10">
              <p className="text-[15px] text-qgraytwo leading-7">
                By using this website and purchasing our products, you agree to
                the terms and conditions outlined in this disclaimer.
              </p>
              <p className="text-[15px] text-qgraytwo leading-7">
                If you have any questions or concerns, please contact us for
                clarification and additional information.
              </p>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}
