export const API_URL = {
  // USER
  USER_LOGIN: "validate-user",
  USER_REGISTER: "user",
  USER_UPDATE: "user/",
  ADD_USER_ADDRESS: "address",
  ADDRESS_LIST: "user-address/",
  GET_USER: "user",

  // Products
  ALL_PRODUCTS: "products",
  PRODUCT_INFO: "product?productId=",
  GET_SUB_CATS: "subcategory/",
  GET_SUB_CATS_LIST: "subcategory/",

  // Wish List
  ADD_WISH_LIST: "add-wishlist?userId=",
  GET_WISH_LIST: "wishlist?userId=",
  REMOVE_WISH_LIST: "remove-wishlist?userId=",
  CLEAR_WISHLIST_ITEMS: "clearWhishList?userId=",

  // CART
  ADD_TO_CART: "updateCart?userId=",
  USER_CART_LIST: "userCart?userId=",
  CART_TOTAL: "cartTotal?userId=",
  REMOVE_CART_ITEM: "deleteCartItem?id=",
  CLEAR_CART_ITEMS: "clearCart?userId=",
  UPDATE_CART_QUANTITY: "updateCart?userId=",
  GET_CART_DATA: "cartCheckout?userId=",

  // ORDER
  CREATE_ORDER: "order",
  ORDER_HISTORY: "user-orders/",
};

export const BASE_URL = {
  BASE_URL: "https://ezykle-93eb5b8618a4.herokuapp.com/", //process.env.REACT_APP_API_URL
  SECRET_KEY: "A31AB78E-C4C7-4C9E-AD98-6D6A1B801E45",
  // AUTH_BASE_URL: process.env.REACT_APP_KEYCLOAK_AUTH_URL,
};

// https://ezykle-93eb5b8618a4.herokuapp.com/
