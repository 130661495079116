import { useRef } from "react";
import { Helmet } from "react-helmet-async";
import PageTitle from "../Helpers/PageTitle";
import SimpleSlider from "../Helpers/SliderCom";
import HalfStar from "../Helpers/icons/HalfStar";
import QtrStar from "../Helpers/icons/QtrStar";
import Star from "../Helpers/icons/Star";
import Layout from "../Partials/Layout";

export default function About() {
  const settings = {
    slidesToShow: 3,
    slidesToScroll: 3,
    autoplay: true,
    centerMode: true,
    infinite: true,
    centerPadding: "60px",
    dots: false,
    responsive: [
      {
        breakpoint: 1026,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          centerMode: false,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: false,
        },
      },

      // You can unslick at a given breakpoint now by adding:
      // settings: "unslick"
      // instead of a settings object
    ],
  };
  const slider = useRef(null);
  const prev = () => {
    slider.current.slickPrev();
  };
  const next = () => {
    slider.current.slickNext();
  };
  return (
    <Layout childrenClasses="pt-0 pb-0">
      <Helmet>
        <meta
          name="Keyword"
          content="About Us | Your Trusted E-Cycle Experts | Ezykle"
        />
        <meta
          name="SEO title"
          content="About Us | Best E Cycle Manufactures in India"
        />
        <meta
          name="Description Line"
          content="Learn about our passion for e-cycles and commitment to providing quality electric bicycles. Discover why we're your trusted e-cycle experts."
        />
        <meta
          name="description"
          content="Learn about our passion for e-cycles and commitment to providing quality electric bicycles. Discover why we're your trusted e-cycle experts."
        />
        <link rel="canonical" href="https://ezykle.com/about" />
        <meta name="Final URL" content="https://ezykle.com/about" />
        <meta name="author" content="Goutham" />
      </Helmet>
      <div className="about-page-wrapper w-full">
        <div className="title-area w-full">
          <PageTitle
            title="About Us"
            breadcrumb={[
              { name: "home", path: "/" },
              { name: "About us", path: "/about" },
            ]}
          />
        </div>

        <div className="aboutus-wrapper w-full">
          <div className="container-x mx-auto">
            <div className="w-full min-h-[300px] lg:flex lg:space-x-12 items-center pt-20">
              <div className="md:w-[400px] w-full md:h-[300px] lr:20 h-auto rounded overflow-hidden">
                <img
                  src={`${process.env.PUBLIC_URL}/assets/images/vision.jpg`}
                  alt="about"
                  className="w-full"
                />
              </div>
              <div className="content flex-1">
                <h1 className="text-[18px] font-medium text-qblack mb-2.5">
                  TRANSFORMING URBAN MOBILITY:
                </h1>
                <p className="text-[15px] text-qgraytwo leading-7 mb-2.5">
                  At EZYKLE, we’re passionate about revolutionizing urban
                  mobility with our innovative electric cycles. With a
                  commitment to sustainability and cutting- edge technology, we
                  strive to provide stylish, eco-friendly solutions for modern
                  transportation needs.
                </p>
              </div>
            </div>
            <div className="w-full min-h-[300px] lg:flex lg:space-x-12 items-center pt-10">
              <div className="content flex-1">
                <h1 className="text-[18px] font-medium text-qblack mb-2.5">
                  OUR TEAM
                </h1>
                <p className="text-[15px] text-qgraytwo leading-7 mb-2.5">
                  Our team is dedicated to crafting high-quality electric cycles
                  that combine sleek design, effortless performance, and
                  exceptional comfort, Whether you’re commuting to work,
                  exploring the city, or embarking on outdoor adventures, EZYKLE
                  is your trusted partner for a convenient, exhilarating ride.
                </p>
              </div>
              <div className="md:w-[400px] w-full md:h-[300px] h-auto rounded overflow-hidden my-5 lg:my-0">
                <img
                  src={`${process.env.PUBLIC_URL}/assets/images/ourteam.png`}
                  alt="about"
                  className="w-full"
                />
              </div>
            </div>
            <div className="w-full min-h-[300px] lg:flex lg:space-x-12 items-center pt-20">
              <div className="md:w-[400px] w-full md:h-[300px] lr:20 h-auto rounded overflow-hidden">
                <img
                  src={`${process.env.PUBLIC_URL}/assets/images/philosophy.jpg`}
                  alt="about"
                  className="w-full"
                />
              </div>
              <div className="content flex-1">
                <h1 className="text-[18px] font-medium text-qblack mb-2.5">
                  OUR VISION
                </h1>
                <p className="text-[15px] text-qgraytwo leading-7 mb-2.5">
                  To pioneer a sustainable revolution in urban mobility, EZYKLE
                  envisions a world where every journey’s emission-free,
                  effortless, and empowering. Through innovative electric
                  cycles, we aim to redefine commuting, clustering healthier
                  lifestyles, cleaner environments, and connected communities.
                </p>
              </div>
            </div>
            <div className="w-full min-h-[300px] lg:flex lg:space-x-12 items-start pt-10">
              <div className="content flex-1">
                <h1 className="text-[18px] font-medium text-qblack mb-2.5">
                  OUR MISSION
                </h1>
                <p className="text-[15px] text-qgraytwo leading-7 mb-2.5">
                  EZYKLE is dedicated to crafting high-quality electric cycles
                  that seamlessly integrate into modern lifestyles, offering
                  convenience, efficiency, and enjoyment.
                </p>
                <div className="content-item w-full mb-5">
                  <h2 className="text-[18px] font-medium text-qblack mb-5 pl-4">
                    1. ECO-FRIENDLY
                  </h2>
                  <p className="text-[15px] text-qgraytwo leading-7 pl-4">
                    By prioritizing innovation, eco-friendliness, and
                    user-centric design
                  </p>
                </div>
                <div className="content-item w-full mb-5">
                  <h2 className="text-[18px] font-medium text-qblack mb-5 pl-4">
                    2. REDUCES CARBON
                  </h2>
                  <p className="text-[15px] text-qgraytwo leading-7 pl-4">
                    We strive to democratize electric mobility, making it
                    accessible to all while reducing carbon footprints and
                    enhancing urban livability.
                  </p>
                </div>
                <div className="content-item w-full mb-5">
                  <h2 className="text-[18px] font-medium text-qblack mb-5 pl-4">
                    WHY CHOOSE EZYKLE?
                  </h2>
                </div>
                <div className="content-item w-full mb-5">
                  <h2 className="text-[18px] font-medium text-qblack mb-5 pl-4">
                    1.Eco-Friendly:
                  </h2>
                  <p className="text-[15px] text-qgraytwo leading-7 pl-4">
                    Reduce your carbon footprint and contribute to a cleaner
                    environment.
                  </p>
                </div>
                <div className="content-item w-full mb-5">
                  <h2 className="text-[18px] font-medium text-qblack mb-5 pl-4">
                    2.Effortless Riding:
                  </h2>
                  <p className="text-[15px] text-qgraytwo leading-7 pl-4">
                    Effortlessly glide through city streets with our powerful
                    electric assistance.
                  </p>
                </div>
                <div className="content-item w-full mb-5">
                  <h2 className="text-[18px] font-medium text-qblack mb-5 pl-4">
                    3.Cost-Effective:
                  </h2>
                  <p className="text-[15px] text-qgraytwo leading-7 pl-4">
                    Save money on fuel and maintenance compared to traditional
                    vehicles.
                  </p>
                </div>
                <div className="content-item w-full mb-5">
                  <h2 className="text-[18px] font-medium text-qblack mb-5 pl-4">
                    4.Health Benefits:
                  </h2>
                  <p className="text-[15px] text-qgraytwo leading-7 pl-4">
                    Enjoy the health benefits of cycling with added assistance
                    for longer rides.
                  </p>
                </div>
                <div className="content-item w-full mb-5">
                  <h2 className="text-[18px] font-medium text-qblack mb-5 pl-4">
                    5.Styling Designs:
                  </h2>
                  <p className="text-[15px] text-qgraytwo leading-7 pl-4">
                    Our electric cycles are sleek, modern, and built to turn
                    heads wherever you go.
                  </p>
                </div>
              </div>
              <div className="md:w-[400px] w-full md:h-[300px] h-auto rounded overflow-hidden my-5 lg:my-0">
                <img
                  src={`${process.env.PUBLIC_URL}/assets/images/product.png`}
                  alt="about"
                  className="w-full"
                />
              </div>
            </div>
          </div>
        </div>

        <div className="customer-feedback w-full bg-white py-[60px]">
          <div className="title flex justify-center mb-5">
            <h1 className="text-[30px] font-semibold text-qblack">
              Customers Feedback
            </h1>
          </div>
          <div className="feedback-slider-wrapper w-vw relative overflow-hidden">
            <SimpleSlider selector={slider} settings={settings}>
              <div className="item h-[385px] bg-primarygray sm:px-10 sm:py-9 p-2">
                <div className="flex flex-col justify-between h-full">
                  <div className="rating flex space-x-1 items-center">
                    <div className="flex items-center">
                      <Star w="20" h="20" />
                      <Star w="20" h="20" />
                      <Star w="20" h="20" />
                      <Star w="20" h="20" />
                      <HalfStar w="20" h="20" />
                    </div>
                    <span className="text-[13px] text-qblack">(4.5)</span>
                  </div>
                  <div className="text-[15px] text-qgraytwo leading-[30px] text-justify line-clamp-6">
                    Passion for the sport with Great combination of different
                    models in the cycle I'm very new to cycling. With the
                    quality work they perform very good and the fitting was very
                    easy to be done . Its very good to ride
                  </div>
                  <div className="flex items-center space-x-2.5 mt-3">
                    <div className="w-[50px] h-[50px] rounded-full overflow-hidden">
                      <img
                        src={`${process.env.PUBLIC_URL}/assets/images/comment-user-4.png`}
                        alt="user"
                      />
                    </div>
                    <div>
                      <p className="text-[18px] text-qblack font-medium">
                        Sahithi
                      </p>
                      <p className="text-qgraytwo text-[13px]">
                        Pragati Nagar, HYD
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="item h-[385px] bg-primarygray sm:px-10 sm:py-9 p-2">
                <div className="flex flex-col justify-between h-full">
                  <div className="rating flex space-x-1 items-center">
                    <div className="flex items-center">
                      <Star w="20" h="20" />
                      <Star w="20" h="20" />
                      <Star w="20" h="20" />
                      <Star w="20" h="20" />
                    </div>
                    <span className="text-[13px] text-qblack">(4.0)</span>
                  </div>
                  <div className="text-[15px] text-qgraytwo leading-[30px] text-justify line-clamp-6">
                    The folks at Ezykle store are A+. Knowledgeable,
                    accommodating and extremely helpful. They were very
                    supportive while we test the cycles from their warehouse so
                    we can compare colors We bought two great cycles from them
                    and we are very satisfied. Highly recommended.
                  </div>
                  <div className="flex items-center space-x-2.5 mt-3">
                    <div className="w-[50px] h-[50px] rounded-full overflow-hidden">
                      <img
                        src={`${process.env.PUBLIC_URL}/assets/images/comment-user-2.png`}
                        alt="user"
                      />
                    </div>
                    <div>
                      <p className="text-[18px] text-qblack font-medium">
                        Yeshwanth
                      </p>
                      <p className="text-qgraytwo text-[13px]">Begumpet, HYD</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="item h-[385px] bg-primarygray sm:px-10 sm:py-9 p-2">
                <div className="flex flex-col justify-between h-full">
                  <div className="rating flex space-x-1 items-center">
                    <div className="flex items-center">
                      <Star w="20" h="20" />
                      <Star w="20" h="20" />
                      <Star w="20" h="20" />
                      <Star w="20" h="20" />
                      <QtrStar w="20" h="20" />
                    </div>
                    <span className="text-[13px] text-qblack">(4.3)</span>
                  </div>
                  <div className="text-[15px] text-qgraytwo leading-[30px] text-justify line-clamp-6">
                    I recently purchased a cycle at this location. They were
                    very friendly and helpful throughout the process. They
                    answered all my questions and got me ready for my “test
                    drive”. I also spoke with the cycle mechanic that day who
                    was equally helpful. I’m currently waiting for my cycle to
                    be delivered. Thank you again!
                  </div>
                  <div className="flex items-center space-x-2.5 mt-3">
                    <div className="w-[50px] h-[50px] rounded-full overflow-hidden">
                      <img
                        src={`${process.env.PUBLIC_URL}/assets/images/comment-user-3.png`}
                        alt="user"
                      />
                    </div>
                    <div>
                      <p className="text-[18px] text-qblack font-medium">
                        Pravallika
                      </p>
                      <p className="text-qgraytwo text-[13px]">
                        Medchal,Rangareddy
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="item h-[385px] bg-primarygray sm:px-10 sm:py-9 p-2">
                <div className="flex flex-col justify-between h-full">
                  <div className="rating flex space-x-1 items-center">
                    <div className="flex items-center">
                      <Star w="20" h="20" />
                      <Star w="20" h="20" />
                      <Star w="20" h="20" />
                      <Star w="20" h="20" />
                      <Star w="20" h="20" />
                    </div>
                    <span className="text-[13px] text-qblack">(5.0)</span>
                  </div>
                  <div className="text-[15px] text-qgraytwo leading-[30px] text-justify line-clamp-6">
                    It was great riding Ezykle. By riding this cycle my morning
                    workout became very easier as it shows me calories burnt and
                    number of kms travelled and it shows me Trip History. It is
                    very good to see the new features they are very helpful. I
                    Highly recommend to buy this product.
                  </div>
                  <div className="flex items-center space-x-2.5 mt-3">
                    <div className="w-[50px] h-[50px] rounded-full overflow-hidden">
                      <img
                        src={`${process.env.PUBLIC_URL}/assets/images/comment-user-1.png`}
                        alt="user"
                      />
                    </div>
                    <div>
                      <p className="text-[18px] text-qblack font-medium">
                        Srinivas
                      </p>
                      <p className="text-qgraytwo text-[13px]">
                        Mehdipatnam , HYD
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </SimpleSlider>

            <div className="slider-btns flex justify-center mt-[40px]">
              <div className="flex space-x-5 item-center">
                <button
                  onClick={prev}
                  type="button"
                  className="w-[48px] h-[48px] rounded-full overflow-hidden flex justify-center items-center border border-qyellow text-qyellow focus:bg-qyellow focus:text-white"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth="2"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M15 19l-7-7 7-7"
                    />
                  </svg>
                </button>
                <button
                  onClick={next}
                  type="button"
                  className="w-[48px] h-[48px] rounded-full overflow-hidden flex justify-center items-center border border-qyellow text-qyellow focus:bg-qyellow focus:text-white"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6 transform rotate-180"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth="2"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M15 19l-7-7 7-7"
                    />
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}
