import PageTitle from "../Helpers/PageTitle";
import Layout from "../Partials/Layout";

export default function ShippingPolicy() {
  return (
    <Layout childrenClasses="pt-0 pb-0">
      <div className="terms-condition-page w-full bg-white pb-[30px]">
        <div className="w-full mb-[30px]">
          <PageTitle
            breadcrumb={[
              { name: "home", path: "/" },
              { name: "Shipping Policy", path: "/shipping" },
            ]}
            title="Shipping Policy"
          />
        </div>
        <div className="w-full">
          <div className="container-x mx-auto">
            <div className="content-item w-full mb-10">
              <p className="text-[15px] text-qgraytwo leading-7">
                Thank you for choosing Ezykle for your electric cycle needs. We
                aim to provide you with the best service possible, including
                efficient and reliable shipping. Please read the following
                shipping policy to understand how your order will be processed
                and delivered.
              </p>
            </div>

            <div className="content-item w-full mb-10 mt-10">
              <h2 className="text-[18px] font-medium text-qblack mb-5">
                1. Processing Time:
              </h2>
              <ul className="list-disc ml-5">
                <li className="text-[15px] text-qgraytwo leading-7">
                  Orders are typically processed within 1-2 business days after
                  payment confirmation.
                </li>
                <li className="text-[15px] text-qgraytwo leading-7">
                  Orders placed on weekends or holidays will be processed on the
                  following business day.
                </li>
              </ul>
            </div>
            <div className="content-item w-full mb-10">
              <h2 className="text-[18px] font-medium text-qblack mb-5">
                2. Shipping Methods:
              </h2>

              <p className="text-[15px] text-qgraytwo leading-7">
                We offer various shipping options to cater to your needs,
                including standard and expedited shipping. Shipping methods may
                vary depending on your location and the availability of our
                carriers.
              </p>
            </div>

            <div className="content-item w-full mb-10">
              <h2 className="text-[18px] font-medium text-qblack mb-5">
                3. Shipping Rates:
              </h2>

              <ul className="list-disc ml-5">
                <li className="text-[15px] text-qgraytwo leading-7">
                  Shipping rates are calculated based on the weight of your
                  order, shipping method selected, and destination.
                </li>
                <li className="text-[15px] text-qgraytwo leading-7">
                  You will be able to view the shipping costs during the
                  checkout process before completing your order.
                </li>
              </ul>
            </div>

            <div className="content-item w-full mb-10">
              <h2 className="text-[18px] font-medium text-qblack mb-5">
                4. Delivery Time:
              </h2>
              <ul className="list-disc ml-5">
                <li className="text-[15px] text-qgraytwo leading-7">
                  Delivery times may vary depending on the shipping method
                  selected and your location.
                </li>
                <li className="text-[15px] text-qgraytwo leading-7">
                  Standard shipping within the contiguous United States
                  typically takes 3-7 business days.
                </li>
                <li className="text-[15px] text-qgraytwo leading-7">
                  Expedited shipping options are available for faster delivery,
                  with delivery times ranging from 1-3 business days.
                </li>
              </ul>
            </div>

            <div className="content-item w-full mb-10">
              <h2 className="text-[18px] font-medium text-qblack mb-5">
                5. Order Tracking:
              </h2>
              <ul className="list-disc ml-5">
                <li className="text-[15px] text-qgraytwo leading-7">
                  Once your order has been shipped, you will receive a
                  confirmation email containing a tracking number.
                </li>
                <li className="text-[15px] text-qgraytwo leading-7">
                  You can use this tracking number to monitor the status of your
                  delivery through our website or the carrier's website.
                </li>
              </ul>
            </div>
            <div className="content-item w-full mb-10">
              <h2 className="text-[18px] font-medium text-qblack mb-5">
                6. International Shipping:
              </h2>
              <ul className="list-disc ml-5">
                <li className="text-[15px] text-qgraytwo leading-7">
                  We currently offer shipping within the United States only.
                </li>
                <li className="text-[15px] text-qgraytwo leading-7">
                  For international orders, please contact our customer service
                  team for assistance.
                </li>
              </ul>
            </div>
            <div className="content-item w-full mb-10">
              <h2 className="text-[18px] font-medium text-qblack mb-5">
                7. Shipping Delays:
              </h2>
              <ul className="list-disc ml-5">
                <li className="text-[15px] text-qgraytwo leading-7">
                  While we strive to deliver your order promptly, shipping
                  delays may occur due to unforeseen circumstances such as
                  weather conditions, carrier issues, or high order volumes.
                </li>
                <li className="text-[15px] text-qgraytwo leading-7">
                  We appreciate your patience and understanding in such
                  situations.
                </li>
              </ul>
            </div>
            <div className="content-item w-full mb-10">
              <h2 className="text-[18px] font-medium text-qblack mb-5">
                8. Shipping Address:
              </h2>
              <ul className="list-disc ml-5">
                <li className="text-[15px] text-qgraytwo leading-7">
                  Please ensure that the shipping address provided during
                  checkout is accurate and complete to prevent any delays or mis
                  deliveries.
                </li>
                <li className="text-[15px] text-qgraytwo leading-7">
                  We are not responsible for orders shipped to incorrect or
                  incomplete addresses provided by the customer.
                </li>
              </ul>
            </div>
            <div className="content-item w-full mb-10">
              <h2 className="text-[18px] font-medium text-qblack mb-5">
                9. Shipping Restrictions:
              </h2>
              <p className="text-[15px] text-qgraytwo leading-7">
                Some products may be subject to shipping restrictions or
                regulations based on their size, weight, or contents. Please
                refer to product descriptions for any applicable shipping
                restrictions.
              </p>
            </div>
            <div className="content-item w-full mb-10">
              <h2 className="text-[18px] font-medium text-qblack mb-5">
                10. Shipping Damage or Loss:
              </h2>
              <ul className="list-disc ml-5">
                <li className="text-[15px] text-qgraytwo leading-7">
                  In the unlikely event that your order is damaged during
                  shipping or lost in transit, please contact our customer
                  service team immediately for assistance.
                </li>
                <li className="text-[15px] text-qgraytwo leading-7">
                  We will work with the carrier to resolve the issue and ensure
                  that you receive a satisfactory resolution.
                </li>
                <li className="text-[15px] text-qgraytwo leading-7">
                  If you have any further questions or concerns regarding our
                  shipping policy, please don't hesitate to contact us. Your
                  satisfaction is our top priority, and we're here to assist you
                  every step of the way.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}
