import { useState } from "react";
import { useNavigate } from "react-router-dom";
import InputCom from "../../components/Helpers/InputCom";

export default function EMI() {
  const navigate = useNavigate();
  const [loginForm, setLoginForm] = useState({
    userName: "",
    password: "",
  });

  const login = async () => {};

  const handleChanges = (e) => {
    setLoginForm({ ...loginForm, [e.target.name]: e.target.value });
  };

  const showAlert = (message) => {
    alert(message);
  };

  return (
    <div>
      <div className="container-x mx-auto">
        <div className="content-item w-full mb-10">
          <h2 className="text-[18px] font-medium text-qblack mb-5">
            EMI Facility
          </h2>
          <p className="text-[15px] text-qgraytwo leading-7 mb-8">
            For those of you who prefer to spread your payments across multiple
            months, we have it covered.
          </p>
          <p className="text-[15px] text-qgraytwo leading-7 mb-8">
            We have two payment gateways to choose from, and both offer options
            for EMI on credit cards. Details of the EMI facility are available
            at checkout, on the payment gateway page.
          </p>
          <p className="text-[15px] text-qgraytwo leading-7 mb-8">
            To proceed with your purchase process, you can choose one of the
            payment gateways, click on the EMI option on screen and select your
            credit card issuing bank to get all the details of the EMI offer -
            EMI tenure, Annualised interest rate, Amount paid as interest, EMI
            per month etc. Go ahead and choose the best option available for
            you, and complete the transaction.
          </p>
          <p className="text-[15px] text-qgraytwo leading-7 mb-8">
            Once your EMI request is approved* by your bank, we will receive the
            payment confirmation and start preparing your product for dispatch.
          </p>
          <p className="text-[15px] text-qgraytwo leading-7 mb-8">
            * Please note the EMI approval is solely at the discretion of your
            bank, and EZYKLE(Eco-Automotive) has no connection/responsibility to
            that process
          </p>
          <p className="text-[15px] text-qgraytwo leading-7 mb-8">
            * EMI starts from 2499
          </p>
        </div>
        <img src="/assets/images/emi.png" alt="EMI" className="pb-10" />
      </div>
      <div className="lg:flex relative">
        <div className="lg:w-[800px] w-full h-[600px] bg-white flex flex-col sm:p-10 p-5 border border-[#E0E0E0]">
          <div className="w-full">
            <div className="input-area">
              <div className="input-item mb-5">
                <InputCom
                  placeholder="Name"
                  label="Name*"
                  name="userName"
                  type="email"
                  inputClasses="h-[50px]"
                  inputHandler={(e) => handleChanges(e)}
                />
              </div>
              <div className="input-item mb-5">
                <InputCom
                  placeholder="example@mail.com"
                  label="Email Address*"
                  name="userName"
                  type="email"
                  inputClasses="h-[50px]"
                  inputHandler={(e) => handleChanges(e)}
                />
              </div>
              <div className="input-item mb-5">
                <InputCom
                  placeholder="xxx xxx xxxx"
                  label="Phone Number*"
                  name="userName"
                  type="email"
                  inputClasses="h-50"
                  inputHandler={(e) => handleChanges(e)}
                />
              </div>
              <div className="input-item mb-5">
                <InputCom
                  placeholder="Select"
                  label="Select Bike*"
                  name="userName"
                  type="email"
                  inputClasses="h-[50px]"
                  inputHandler={(e) => handleChanges(e)}
                />
              </div>
              <div className="signin-area mb-3.5">
                <div className="flex justify-center">
                  <button
                    onClick={login}
                    type="button"
                    className="black-btn mb-6 text-sm text-white w-full h-[50px] font-semibold flex justify-center bg-purple items-center"
                  >
                    <span>Submit</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="flex-1 lg:flex hidden transform scale-60 xl:scale-100   xl:justify-center ">
            <div
              className="absolute xl:-right-20 -right-[138px]"
              style={{ top: "calc(50% - 258px)" }}
            >
              <Thumbnail />
            </div>
          </div> */}
      </div>
    </div>
  );
}
