import { useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { WishListContext } from "../../contextProviders/wishListContextProvider";
import BreadcrumbCom from "../BreadcrumbCom";
import EmptyWishlistError from "../EmptyWishlistError";
import PageTitle from "../Helpers/PageTitle";
import Layout from "../Partials/Layout";
import { clearWishlistItemsByIDRequest } from "../SingleProductPage/httppactions/httpactions";
import ProductsTable from "./ProductsTable";

export default function Wishlist({ wishlist = false }) {
  const [wishListData, setWishListData] = useContext(WishListContext);
  const [isWishList, setIsWishList] = useState(wishlist);
  const dispatch = useDispatch();

  useEffect(() => {
    if (wishListData.length) {
      setIsWishList(true);
    } else {
      setIsWishList(false);
    }
  }, [wishListData.length]);

  const clearWishList = async () => {
    console.log("clearw-List");
    await dispatch(
      clearWishlistItemsByIDRequest(
        { userId: localStorage.getItem("userId") },
        dispatch
      )
    ).then((response) => {
      console.log(response);
      if (response.status == true) {
        setWishListData([]);
      }
    });
  };

  return (
    <Layout childrenClasses={isWishList ? "pt-0 pb-0" : ""}>
      {isWishList === false ? (
        <div className="wishlist-page-wrapper w-full">
          <div className="container-x mx-auto">
            <BreadcrumbCom
              paths={[
                { name: "home", path: "/" },
                { name: "wishlist", path: "/wishlist" },
              ]}
            />
            <EmptyWishlistError />
          </div>
        </div>
      ) : (
        <div className="wishlist-page-wrapper w-full bg-white pb-[60px]">
          <div className="w-full">
            <PageTitle
              title="Wishlist"
              breadcrumb={[
                { name: "home", path: "/" },
                { name: "wishlist", path: "/wishlist" },
              ]}
            />
          </div>
          <div className="w-full mt-[23px]">
            <div className="container-x mx-auto">
              <ProductsTable className="mb-[30px]" data={wishListData} />
              <div className="w-full mt-[30px] flex sm:justify-end justify-start">
                <div className="sm:flex sm:space-x-[30px] items-center">
                  <button
                    type="button"
                    onClick={() => clearWishList()}
                    className="pr-[20px]"
                  >
                    <div className="w-full text-sm font-semibold text-qred mb-5 sm:mb-0">
                      Clear Wishlist
                    </div>
                  </button>
                  {/* <div className="w-[180px] h-[50px]">
                    <button type="button" className="yellow-btn">
                      <div className="w-full text-sm font-semibold">
                        Add to Cart All
                      </div>
                    </button>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </Layout>
  );
}
