import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getOrdersHistoryRequest } from "../../../SingleProductPage/httppactions/httpactions";
const getFormattedDate = (e) => {
  var date = new Date(e);
  // console.log(date.toDateString());
  return date.toLocaleDateString();
};

export default function OrderTab() {
  const dispatch = useDispatch();
  const [orders, setOrders] = useState([]);
  const navigate = useNavigate();
  useEffect(() => {
    getOreders();
    //eslint-disable-next-line
  }, []);

  const getOreders = async () => {
    await dispatch(
      getOrdersHistoryRequest(
        { userId: localStorage.getItem("userId") },
        dispatch
      )
    ).then((response) => {
      console.log(response);
      if (response.status == true) {
        setOrders(response.result);
      } else {
        alert("Unable to fetch records");
      }
    });
  };

  const loadInvoice = () => {
    // const fileName = item.id + "Invoice.pdf";
    // generatePDF(targetRef, { filename: fileName });
  };

  const loadDetails = (e) => {
    console.log("Get Invoice", e);
    navigate("/invoice", {
      state: {
        item: e,
      },
    });
  };
  return (
    <>
      <div className="relative w-full overflow-x-auto sm:rounded-lg">
        <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
          <thead>
            <tr className="text-base text-qgray whitespace-nowrap px-2 border-b default-border-bottom ">
              <th className="py-4 block whitespace-nowrap text-center">
                Order
              </th>
              <th className="py-4 whitespace-nowrap text-center">Date</th>
              <th className="py-4 whitespace-nowrap text-center">Status</th>
              <th className="py-4 whitespace-nowrap text-center">Amount</th>
              <th className="py-4 whitespace-nowrap  text-center">Action</th>
            </tr>
          </thead>
          <tbody>
            {orders.map((item, i) => {
              return (
                <tr className="bg-white border-b hover:bg-gray-50">
                  <td className="text-center py-4">
                    <span className="text-lg text-qgray font-medium">
                      #{item.id}
                    </span>
                  </td>
                  <td className="text-center py-4 px-2">
                    <span className="text-base text-qgray  whitespace-nowrap">
                      {getFormattedDate(item.updated_at)}
                    </span>
                  </td>
                  <td className="text-center py-4 px-2">
                    <span className="text-sm rounded text-green-500 bg-green-100 p-2">
                      {item.status_text}
                    </span>
                  </td>
                  <td className="text-center py-4 px-2">
                    <span className="text-base text-qblack whitespace-nowrap px-2 ">
                      ₹{item.items_cost}
                    </span>
                  </td>
                  <td className="text-center py-4">
                    <button
                      type="button"
                      className="w-[116px] h-[46px]"
                      style={{ background: "#862860", color: "white" }}
                      onClick={() => loadDetails(item)}
                    >
                      View Details
                    </button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </>
  );
}
