import PageTitle from "../Helpers/PageTitle";
import Layout from "../Partials/Layout";

export default function TermsCondition() {
  return (
    <Layout childrenClasses="pt-0 pb-0">
      <div className="terms-condition-page w-full bg-white pb-[30px]">
        <div className="w-full mb-[30px]">
          <PageTitle
            breadcrumb={[
              { name: "home", path: "/" },
              { name: "Terms and condition", path: "/terms-conditions" },
            ]}
            title="Terms and Condition"
          />
        </div>
        <div className="w-full">
          <div className="container-x mx-auto">
            <div className="content-item w-full mb-10">
              <h2 className="text-[18px] font-medium text-qblack mb-5">
                1. Acceptance of Terms:
              </h2>
              <p className="text-[15px] text-qgraytwo leading-7">
                These terms and conditions ("Terms") govern the sale of electric
                cycles and related products (collectively, "Products") by
                Eco-Automotive Pvt Ltd ("Seller") to you, the customer
                ("Buyer"). By placing an order through our website, you accept
                and agree to be bound by these Terms.
              </p>
            </div>
            <div className="content-item w-full mb-10">
              <h2 className="text-[18px] font-medium text-qblack mb-5">
                2. Orders and Payment:
              </h2>

              <ul className="list-disc ml-5">
                <li className="text-[15px] text-qgraytwo leading-7">
                  Orders are subject to availability and acceptance by Seller.
                </li>
                <li className="text-[15px] text-qgraytwo leading-7">
                  The price, shipping costs, and any applicable taxes will be
                  displayed on the website when placing an order. Payment is due
                  at the time of order placement.
                </li>
                <li className="text-[15px] text-qgraytwo leading-7">
                  Payment methods accepted may vary and will be specified on the
                  website.
                </li>
              </ul>
            </div>

            <div className="content-item w-full mb-10">
              <h2 className="text-[18px] font-medium text-qblack mb-5">
                3. Shipping and Delivery:
              </h2>

              <ul className="list-disc ml-5">
                <li className="text-[15px] text-qgraytwo leading-7">
                  The delivery schedule and shipping options are specified on
                  the website.
                </li>
                <li className="text-[15px] text-qgraytwo leading-7">
                  Seller will make reasonable efforts to meet delivery estimates
                  but does not guarantee delivery dates.
                </li>
                <li className="text-[15px] text-qgraytwo leading-7">
                  Risk of loss and title for the Products pass to the Buyer upon
                  delivery.
                </li>
              </ul>
            </div>

            <div className="content-item w-full mb-10">
              <h2 className="text-[18px] font-medium text-qblack mb-5">
                4. Returns and Refunds:
              </h2>
              <ul className="list-disc ml-5">
                <li className="text-[15px] text-qgraytwo leading-7">
                  Our return and refund policy is outlined on our website.
                  Buyers are encouraged to review this policy before making a
                  purchase.
                </li>
                <li className="text-[15px] text-qgraytwo leading-7">
                  If a return is accepted, the Buyer is responsible for return
                  shipping costs unless the product is received damaged or
                  defective.
                </li>
                <li className="text-[15px] text-qgraytwo leading-7">
                  Refunds, if applicable, will be processed according to our
                  return policy.
                </li>
              </ul>
            </div>

            <div className="content-item w-full mb-10">
              <h2 className="text-[18px] font-medium text-qblack mb-5">
                5. Warranty:
              </h2>
              <ul className="list-disc ml-5">
                <li className="text-[15px] text-qgraytwo leading-7">
                  Warranty information is available on the website for each
                  Product.
                </li>
                <li className="text-[15px] text-qgraytwo leading-7">
                  Buyer is responsible for understanding and complying with the
                  terms of the warranty.
                </li>
              </ul>
            </div>
            <div className="content-item w-full mb-10">
              <h2 className="text-[18px] font-medium text-qblack mb-5">
                6. Product Use and Safety:
              </h2>
              <ul className="list-disc ml-5">
                <li className="text-[15px] text-qgraytwo leading-7">
                  Buyers are responsible for using electric cycles safely and in
                  compliance with local laws and regulations.
                </li>
                <li className="text-[15px] text-qgraytwo leading-7">
                  Seller is not liable for any accidents, injuries, or damages
                  that may occur while using the Products.
                </li>
              </ul>
            </div>
            <div className="content-item w-full mb-10">
              <h2 className="text-[18px] font-medium text-qblack mb-5">
                7. Intellectual Property:
              </h2>
              <ul className="list-disc ml-5">
                <li className="text-[15px] text-qgraytwo leading-7">
                  All content on the website, including images, text, and logos,
                  is the intellectual property of Seller and may not be used
                  without permission.
                </li>
              </ul>
            </div>
            <div className="content-item w-full mb-10">
              <h2 className="text-[18px] font-medium text-qblack mb-5">
                8. Limitation of Liability:
              </h2>
              <ul className="list-disc ml-5">
                <li className="text-[15px] text-qgraytwo leading-7">
                  To the extent permitted by law, Seller's liability is limited
                  to the purchase price paid for the Products.
                </li>
                <li className="text-[15px] text-qgraytwo leading-7">
                  Seller is not liable for any indirect, special, consequential,
                  or punitive damages.
                </li>
              </ul>
            </div>
            <div className="content-item w-full mb-10">
              <h2 className="text-[18px] font-medium text-qblack mb-5">
                9. Governing Law and Jurisdiction:
              </h2>
              <ul className="list-disc ml-5">
                <li className="text-[15px] text-qgraytwo leading-7">
                  These Terms are governed by the laws of Telangana state in
                  india, and any disputes will be subject to the exclusive
                  jurisdiction of the courts in Hyderabad city ,Telangana state.
                </li>
              </ul>
            </div>
            <div className="content-item w-full mb-10">
              <h2 className="text-[18px] font-medium text-qblack mb-5">
                10. Changes to Terms and Conditions:
              </h2>
              <ul className="list-disc ml-5">
                <li className="text-[15px] text-qgraytwo leading-7">
                  Seller reserves the right to update or modify these Terms at
                  any time without prior notice. Any changes will be posted on
                  the website.
                </li>
              </ul>
            </div>
            <div className="content-item w-full mb-10">
              <h2 className="text-[18px] font-medium text-qblack mb-5">
                11. Entire Agreement:
              </h2>
              <ul className="list-disc ml-5">
                <li className="text-[15px] text-qgraytwo leading-7">
                  These Terms constitute the entire agreement between Buyer and
                  Seller and supersede any prior agreements or understandings,
                  whether oral or written.
                </li>
              </ul>
            </div>

            <div className="content-item w-full mb-10">
              <p className="text-[15px] text-qgraytwo leading-7">
                By making a purchase through our website, you acknowledge that
                you have read, understood, and agree to these Terms and
                Conditions of Sale.
              </p>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}
