import { API_URL } from "../utils/ApiUrl";
import ServiceCalls from "../utils/services/httpServiceCalls";

export const registerUser = (data) => async () => {
  try {
    let result = await ServiceCalls.post(
      `${API_URL.USER_REGISTER}`,
      data,
      true
    );
    return result;
  } catch (error) {}
};

export const updateUser = (data) => async () => {
  try {
    let result = await ServiceCalls.post(
      `${API_URL.USER_UPDATE}${data.id}`,
      data,
      true
    );
    return result;
  } catch (error) {}
};

export const addressListService = (data) => async () => {
  try {
    let result = await ServiceCalls.get(
      `${API_URL.ADDRESS_LIST}${data.id}`,
      data,
      true
    );
    return result;
  } catch (error) {}
};

export const addUserAddressService = (data) => async () => {
  try {
    let result = await ServiceCalls.post(
      `${API_URL.ADD_USER_ADDRESS}`,
      data,
      true
    );
    return result;
  } catch (error) {}
};

export const updateUserAddressService = (data) => async () => {
  try {
    let result = await ServiceCalls.post(
      `${API_URL.ADD_USER_ADDRESS}/${data.id}`,
      data,
      true
    );
    return result;
  } catch (error) {}
};

export const deleteUserAddressService = (data) => async () => {
  try {
    let result = await ServiceCalls.delete(
      `${API_URL.ADD_USER_ADDRESS}/${data.id}`,
      null,
      true
    );
    return result;
  } catch (error) {}
};

export const getUserDetailsService = (data) => async () => {
  try {
    let result = await ServiceCalls.get(
      `${API_URL.GET_USER}/${data.id}`,
      null,
      true
    );
    return result;
  } catch (error) {}
};
