import AOS from "aos";
import "aos/dist/aos.css";
import ReactDOM from "react-dom/client";
import { HelmetProvider } from "react-helmet-async";
import { Provider } from "react-redux";
import { HashRouter } from "react-router-dom";
import App from "./App";
import "./index.css";
import store from "./store/store";

AOS.init();

const root = document.getElementById("root");
ReactDOM.createRoot(root).render(
  <Provider store={store}>
    <HelmetProvider>
      <HashRouter history={history}>
        {/* <BrowserRouter history={history}> */}
        <App />
        {/* </BrowserRouter> */}
      </HashRouter>
    </HelmetProvider>
  </Provider>
);
