import { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import LoadingHud from "../../../hud/loadinghud";
import InputCom from "../../Helpers/InputCom";
import Layout from "../../Partials/Layout";
import {
  getUserDataRequest,
  registerUserRequest,
} from "../authActions/httpactions";
import Thumbnail from "./Thumbnail";

export default function Signup() {
  const [checked, setValue] = useState(false);
  const [registerForm, setRegisterForm] = useState({
    first_name: "",
    last_name: "",
    phone: "",
    email: "",
    password: "",
    confirm_password: "",
  });
  const [isLoading, setIsLoading] = useState(false);

  const rememberMe = () => {
    setValue(!checked);
  };

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleChanges = (e) => {
    setRegisterForm({ ...registerForm, [e.target.name]: e.target.value });
  };

  const registerUser = async () => {
    if (registerForm.first_name == "") {
      showAlert("Please enter first name");
    } else if (registerForm.last_name == "") {
      showAlert("Please enter last name");
    } else if (registerForm.password == "") {
      showAlert("Please enter password");
    } else if (registerForm.confirm_password == "") {
      showAlert("Please enter confirm password");
    } else if (registerForm.password != registerForm.confirm_password) {
      showAlert("Please enter password and confirm passwords are same");
    } else if (registerForm.email == "") {
      showAlert("Please enter email");
    } else if (registerForm.phone == "") {
      showAlert("Please enter phone number");
    } else {
      let registerObj = {
        first_name: registerForm.first_name,
        last_name: registerForm.last_name,
        phone: registerForm.phone,
        email: registerForm.email,
        password: registerForm.password,
        role: "user",
        platform: "web",
        notification_token: "",
        is_admin: "0",
        status: "active",
      };
      await dispatch(registerUserRequest(registerObj, dispatch)).then(
        (response) => {
          console.log(response);
          if (response.result) {
            showAlert("Register Success.");
            getUser(response.result.userId);
          } else {
            showAlert("Register Fail.");
          }
        }
      );
    }
  };

  const showAlert = (message) => {
    alert(message);
  };

  const getUser = async (id) => {
    setIsLoading(true);
    await dispatch(getUserDataRequest({ id: id }, dispatch)).then(
      (response) => {
        
        setIsLoading(false);
        updateData(response);
      }
    );
  };

  const updateData = async (response) => {
    if (response.result) {
      await localStorage.setItem("userId", response.result.id);
      await localStorage.setItem("user_first_name", response.result.first_name);
      await localStorage.setItem("user_last_name", response.result.last_name);
      await localStorage.setItem("userEmail", response.result.email);
      await localStorage.setItem("userPhone", response.result.phone);
      navigate("/");
      window.location.reload();
    }
  };

  return (
    <>
      {isLoading && <LoadingHud />}
      <Layout childrenClasses="pt-0 pb-0">
        <div className="login-page-wrapper w-full py-10">
          <div className="container-x mx-auto">
            <div className="lg:flex items-center relative">
              <div className="lg:w-[572px] w-full lg:h-[780px] bg-white flex flex-col justify-center sm:p-10 p-5 border border-[#E0E0E0]">
                <div className="w-full">
                  <div className="title-area flex flex-col justify-center items-center relative text-center mb-7">
                    <h1 className="text-[34px] font-bold leading-[74px] text-qblack">
                      Create Account
                    </h1>
                    <div className="shape -mt-6">
                      <svg
                        width="354"
                        height="30"
                        viewBox="0 0 354 30"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M1 28.8027C17.6508 20.3626 63.9476 8.17089 113.509 17.8802C166.729 28.3062 341.329 42.704 353 1"
                          stroke="#FFBB38"
                          strokeWidth="2"
                          strokeLinecap="round"
                        />
                      </svg>
                    </div>
                  </div>
                  <div className="input-area">
                    <div className="flex sm:flex-row flex-col space-y-5 sm:space-y-0 sm:space-x-5 mb-5">
                      <InputCom
                        placeholder="Please enter first name"
                        label="Frist Name*"
                        name="first_name"
                        type="text"
                        inputClasses="h-[50px]"
                        inputHandler={(e) => handleChanges(e)}
                      />
                    </div>
                    <div className="flex sm:flex-row flex-col space-y-5 sm:space-y-0 sm:space-x-5 mb-5">
                      <InputCom
                        placeholder="Please enter last Name"
                        label="last Name*"
                        name="last_name"
                        type="text"
                        inputClasses="h-[50px]"
                        inputHandler={(e) => handleChanges(e)}
                      />
                    </div>
                    <div className="flex sm:flex-row flex-col space-y-5 sm:space-y-0 sm:space-x-5 mb-5">
                      <InputCom
                        placeholder="● ● ● ● ● ●"
                        label="Password*"
                        name="password"
                        type="password"
                        inputClasses="h-[50px]"
                        inputHandler={(e) => handleChanges(e)}
                      />
                      <InputCom
                        placeholder="● ● ● ● ● ●"
                        label="Confirm Password*"
                        name="confirm_password"
                        type="password"
                        inputClasses="h-[50px]"
                        inputHandler={(e) => handleChanges(e)}
                      />
                    </div>
                    <div className="flex sm:flex-row flex-col space-y-5 sm:space-y-0 sm:space-x-5 mb-5">
                      <InputCom
                        placeholder="Please enter email"
                        label="Email Address*"
                        name="email"
                        type="email"
                        inputClasses="h-[50px]"
                        inputHandler={(e) => handleChanges(e)}
                      />
                    </div>
                    <div className="flex sm:flex-row flex-col space-y-5 sm:space-y-0 sm:space-x-5 mb-5">
                      <InputCom
                        placeholder="Please enter phone number"
                        label="Phone*"
                        name="phone"
                        type="text"
                        inputClasses="h-[50px]"
                        inputHandler={(e) => handleChanges(e)}
                      />
                    </div>
                    <div className="forgot-password-area mb-7">
                      <div className="remember-checkbox flex items-center space-x-2.5">
                        <button
                          onClick={rememberMe}
                          type="button"
                          className="w-5 h-5 text-qblack flex justify-center items-center border border-light-gray"
                        >
                          {checked && (
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="h-5 w-5"
                              viewBox="0 0 20 20"
                              fill="currentColor"
                            >
                              <path
                                fillRule="evenodd"
                                d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                clipRule="evenodd"
                              />
                            </svg>
                          )}
                        </button>
                        <span
                          onClick={rememberMe}
                          className="text-base text-black"
                        >
                          I agree all
                          <span className="text-qblack">
                            {" "}
                            terms and condition
                          </span>
                          in Eco Automotive.
                        </span>
                      </div>
                    </div>
                    <div className="signin-area mb-3">
                      <div className="flex justify-center">
                        <button
                          onClick={() => registerUser()}
                          type="button"
                          className="black-btn text-sm text-white w-full h-[50px] font-semibold flex justify-center bg-purple items-center"
                        >
                          <span>Create Account</span>
                        </button>
                      </div>
                    </div>

                    <div className="signup-area flex justify-center">
                      <p className="text-base text-qgraytwo font-normal">
                        Alrady have an Account?
                        <a
                          href="/login"
                          className="ml-2 text-qblack"
                          rel="noreferrer"
                        >
                          Log In
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex-1 lg:flex hidden transform scale-60 xl:scale-100   xl:justify-center">
                <div
                  className="absolute xl:-right-20 -right-[138px]"
                  style={{ top: "calc(50% - 258px)" }}
                >
                  <Thumbnail />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
}
