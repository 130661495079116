import { useNavigate } from "react-router-dom";

export default function ProductCardStyleFleet({ datas, type }) {
  const navigate = useNavigate();
  const available =
    (datas.cam_product_sale /
      (datas.cam_product_available + datas.cam_product_sale)) *
    100;

  const showDetails = (e) => {};
  return (
    <>
      <div
        className="product-card-one w-full h-full bg-white relative group overflow-hidden"
        style={{ boxShadow: "0px 15px 64px 0px rgba(0, 0, 0, 0.05)" }}
      >
        <div
          className="product-card-img w-full h-[300px]"
          style={
            {
              // background: `url(${process.env.PUBLIC_URL}/assets/images/${datas.image}.png) no-repeat center`,
            }
          }
        >
          <img
            className="w-full p-10 h-[300px]"
            src={`${process.env.PUBLIC_URL}/assets/images/${datas.image}.png`}
          />
        </div>
        <div className="product-card-details px-[30px] pb-[40px] relative">
          <p className="title mb-2 text-[20px] font-600 text-qblack leading-[24px] line-clamp-2 hover:text-blue">
            {datas.name}
          </p>

          <p className="price">
            <span className="main-price text-qgray text-[14px]">
              {datas.description}
            </span>
          </p>
        </div>
      </div>
    </>
  );
}
