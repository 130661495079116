import {
  createOrderService,
  getCartDataService,
} from "../../../api/productsservices";
import { easebuzzInitiatePayment } from "./checkouteasebuzzpaymentservices";

const checkOutInitiatePayment = (data, url) => async (dispatch) => {
  const response = await dispatch(easebuzzInitiatePayment(data, url));
  if (
    (response &&
      Object.keys(response).length &&
      response?.data?.status === 201) ||
    200
  ) {
    return Promise.resolve(response.data);
  } else {
    return Promise.resolve(null);
  }
};

const getCheckoutData = (data) => async (dispatch) => {
  const response = await dispatch(getCartDataService(data));
  if (
    (response &&
      Object.keys(response).length &&
      response?.data?.status === 201) ||
    200
  ) {
    return Promise.resolve(response.data);
  } else {
    return Promise.resolve(null);
  }
};

const createOrderRequest = (data) => async (dispatch) => {
  const response = await dispatch(createOrderService(data));
  if (
    (response &&
      Object.keys(response).length &&
      response?.data?.status === 201) ||
    200
  ) {
    return Promise.resolve(response.data);
  } else {
    return Promise.resolve(null);
  }
};

export { checkOutInitiatePayment, createOrderRequest, getCheckoutData };
