import { Helmet } from "react-helmet-async";
import datas from "../../data/fleet.json";
import PageTitle from "../Helpers/PageTitle";
import SectionStyleFleet from "../Helpers/SectionStyleFleet";
import Layout from "../Partials/Layout";

export default function FleetPage() {
  const { products, travel, institute } = datas;
  return (
    <Layout>
      <Helmet>
        <meta
          name="Keyword"
          content="Best Electric Cycles, Electric Mountain Bicycle"
        />
        <meta
          name="SEO title"
          content="Discover the Best Electric Cycles | Electric Mountain Bicycle"
        />
        <meta
          name="Description Line"
          content="Explore the best electric mountain bicycles for thrilling rides on any terrain. Find the perfect e-cycle for your adventurous spirit today!"
        />
        <meta name="Final URL" content="https://ezykle.com/#/fleet" />
        <meta
          name="description"
          content="Explore the best electric mountain bicycles for thrilling rides on any terrain. Find the perfect e-cycle for your adventurous spirit today!"
        />
        <link rel="canonical" href="https://ezykle.com/#/fleet" />
        <meta name="author" content="Goutham" />
      </Helmet>
      <div className="page-title mb-10">
        <PageTitle
          title="Fleet Management"
          breadcrumb={[
            { name: "home", path: "/" },
            { name: "fleet", path: "/fleet" },
          ]}
        />
      </div>
      <div className="cart-page-wrapper w-full">
        <div className="container-x mx-auto">
          <SectionStyleFleet
            type={3}
            products={products}
            sectionTitle="CORPORATES"
            seeMoreUrl=""
            className="new-products mb-[60px]"
          />
          <SectionStyleFleet
            type={3}
            products={travel}
            sectionTitle="TRAVEL & TOURISM"
            seeMoreUrl=""
            className="new-products mb-[60px]"
          />
          <SectionStyleFleet
            type={3}
            products={institute}
            sectionTitle="INSTITUTIONS"
            seeMoreUrl=""
            className="new-products mb-[60px]"
          />
        </div>
      </div>
    </Layout>
  );
}
