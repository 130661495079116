import { useState } from "react";
import { useNavigate } from "react-router-dom";
import InputCom from "../../components/Helpers/InputCom";

export default function Warranty() {
  const navigate = useNavigate();
  const [loginForm, setLoginForm] = useState({
    userName: "",
    password: "",
  });

  const [file, setFile] = useState(null);

  const login = async () => {};

  const handleChanges = (e) => {
    setLoginForm({ ...loginForm, [e.target.name]: e.target.value });
  };

  const showAlert = (message) => {
    alert(message);
  };

  return (
    <div>
      <div className="container-x mx-auto">
        <div className="content-item w-full mb-10">
          <h2 className="text-[18px] font-medium text-qblack mb-5">
            EZYKLE warranty covers the following –
          </h2>
          <ul className="list-disc ml-5">
            <li className="text-[15px] text-qgraytwo leading-7">
              Frame | 5Years
            </li>
            <li className="text-[15px] text-qgraytwo leading-7">
              Li-ion Battery | 24 months
            </li>
            <li className="text-[15px] text-qgraytwo leading-7">
              Hub Motor & Bike Control Unit | 18 months
            </li>
            <li className="text-[15px] text-qgraytwo leading-7">
              All other parts | As per OEM’s warranty; Wear & Tear parts not
              included
            </li>
          </ul>
        </div>
        <div className="content-item w-full mb-10">
          <h2 className="text-[18px] font-medium text-qblack mb-5">
            This warranty is not applicable if -
          </h2>
          <ul className="list-disc ml-5">
            <li className="text-[15px] text-qgraytwo leading-7">
              Normal wear and tear
            </li>
            <li className="text-[15px] text-qgraytwo leading-7">
              Lack of proper and regular maintenance
            </li>
            <li className="text-[15px] text-qgraytwo leading-7">
              Improper and Incorrect assembly
            </li>
            <li className="text-[15px] text-qgraytwo leading-7">
              Vehicle being used for competitions or commercial activities such
              as rentals or fleet operations
            </li>
            <li className="text-[15px] text-qgraytwo leading-7">
              Accidents, Abuse or Improper use
            </li>
            <li className="text-[15px] text-qgraytwo leading-7">
              Any modification, Installation of components, parts, or
              accessories not originally intended for or compatible with the
              EZYKLE product sold
            </li>
          </ul>
          <div>
            <h2 className="text-[18px] font-medium text-qblack mb-5">
              Warranty Terms & Conditions
            </h2>
            <ul className="list-disc ml-5">
              <li className="text-[15px] text-qgraytwo leading-7">
                This warranty is applicable to EZYKLE models from 2023 and
                onwards (unless otherwise stated)
              </li>
              <li className="text-[15px] text-qgraytwo leading-7">
                Original parts from 3rd party brands will be covered by the
                warranty of the original manufacturer
              </li>
              <li className="text-[15px] text-qgraytwo leading-7">
                Any items specifically not mentioned above are hereby omitted
              </li>
              <li className="text-[15px] text-qgraytwo leading-7">
                Warranty is limited to the repair or replacement of a defective
                item, and said repair or replacement is the sole remedy of the
                warranty
              </li>
              <li className="text-[15px] text-qgraytwo leading-7">
                Warranty is valid from the date of purchase, valid only if the
                product is registered and applicable only to the original retail
                purchaser and is not transferable.
              </li>
              <li className="text-[15px] text-qgraytwo leading-7">
                Warranty applies only to EZYKLE products purchased directly from
                EZYKLE or through an authorised dealer or distributor.
              </li>
              <li className="text-[15px] text-qgraytwo leading-7">
                All claims against this warranty must be made directly with
                EZYKLE or through an authorised dealer or distributor (depending
                on who the purchase was made from)
              </li>
              <li className="text-[15px] text-qgraytwo leading-7">
                Customer is responsible for paying any travel or shipment costs
                related to a warranty claim
              </li>
              <li className="text-[15px] text-qgraytwo leading-7">
                All warranty claims will require Proof of purchase, so please
                retain the purchase proof at all times
              </li>
              <li className="text-[15px] text-qgraytwo leading-7">
                Claims made outside the country of purchase may be subject to
                additional fees and restrictions
              </li>
              <li className="text-[15px] text-qgraytwo leading-7">
                EZYKLE Electric is not responsible for incidental or
                consequential damages
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="lg:flex relative">
        <div className="lg:w-[800px] w-full h-[600px] bg-white flex flex-col sm:p-10 p-5 border border-[#E0E0E0]">
          <div className="w-full">
            <div className="input-area">
              <div className="input-item mb-5">
                <InputCom
                  placeholder="Name"
                  label="Name*"
                  name="userName"
                  type="email"
                  inputClasses="h-[50px]"
                  inputHandler={(e) => handleChanges(e)}
                />
              </div>
              <div className="input-item mb-5">
                <InputCom
                  placeholder="example@mail.com"
                  label="Email Address*"
                  name="userName"
                  type="email"
                  inputClasses="h-[50px]"
                  inputHandler={(e) => handleChanges(e)}
                />
              </div>
              <div className="input-item mb-5">
                <InputCom
                  placeholder="xxx xxx xxxx"
                  label="Phone Number*"
                  name="userName"
                  type="email"
                  inputClasses="h-50"
                  inputHandler={(e) => handleChanges(e)}
                />
              </div>
              <div className="flex sm:flex-row flex-col space-y-5 sm:space-y-0 sm:space-x-5 mb-5">
                <InputCom
                  placeholder="Select"
                  label="Select Bike*"
                  name="userName"
                  type="email"
                  inputClasses="h-[50px]"
                  inputHandler={(e) => handleChanges(e)}
                />
                <InputCom
                  placeholder="frame no."
                  label="Frame No.*"
                  name="userName"
                  type="email"
                  inputClasses="h-[50px]"
                  inputHandler={(e) => handleChanges(e)}
                />
                <InputCom
                  placeholder="Invoice no."
                  label="Invoice Number*"
                  name="userName"
                  type="email"
                  inputClasses="h-[50px]"
                  inputHandler={(e) => handleChanges(e)}
                />
              </div>
              <div>
                <button
                  onClick={login}
                  type="button"
                  className="black-btn mb-6 text-sm text-white w-[200px] h-[50px] font-semibold flex justify-center bg-purple items-center"
                >
                  <span>Upload Invoice</span>
                </button>
              </div>
              <div className="signin-area mb-3.5">
                <div className="flex justify-center">
                  <button
                    onClick={login}
                    type="button"
                    className="black-btn mb-6 text-sm text-white w-full h-[50px] font-semibold flex justify-center bg-purple items-center"
                  >
                    <span>Submit</span>
                  </button>

                  {/* <button>
                    <input
                      accept="image/*, .pdf, .doc"
                      type="file"
                      name="document"
                      onChange={(e) => {
                        setFile(e.target.files[0]);

                        console.log(e);
                        console.log(file);
                      }}
                    />
                  </button> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
