export default function ServiceCard() {
  return (
    <div className={`blog-card-wrapper w-full border border-[#D3D3D3]`}>
      <div className="img w-full h-[40px]">
        <h1 className="text-qblack font-bold text-[22px] mb-4 text-center pt-5">
          SERVICE
        </h1>
      </div>
      <div className="p-[24px]">
        <div className="details">
          <p className="text-qgraytwo text-[15px] leading-[30px] mb-3">
            A quality product needs to be backed-up by quality service! That is
            exactly what we do at EZYKLE. Our products are built to be reliable,
            last long and offer you the best experience. In addition to the
            high-quality build, we understand it is very essential to offer high
            quality service for our products to perform at their best, at all
            times EZYKLE is committed to providing excellent service and
            high-quality workmanship that you can trust, always.
          </p>
          <p className="text-qgraytwo text-[15px] leading-[30px] mb-3">
            In service add you can book your service with App.
          </p>
        </div>
      </div>
    </div>
  );
}
