import { useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import { CartListContext } from "../../../../contextProviders/cartContextProvider";
import { WishListContext } from "../../../../contextProviders/wishListContextProvider";
import Cart from "../../../Cart";
import ThinBag from "../../../Helpers/icons/ThinBag";
import ThinLove from "../../../Helpers/icons/ThinLove";
import ThinPeople from "../../../Helpers/icons/ThinPeople";

export default function Middlebar({ className, type }) {
  const navigation = useNavigate();
  const [wishList, setWishList] = useContext(WishListContext);
  const [cartList, setCartList] = useContext(CartListContext);
  const validateUser = () => {
    if (
      localStorage.getItem("userId") !== null &&
      localStorage.getItem("userId") !== ""
    ) {
      navigation("/profile");
    } else {
      navigation("/login");
    }
  };

  const validateName = () => {
    if (
      localStorage.getItem("userId") !== null &&
      localStorage.getItem("userId") !== ""
    ) {
      return localStorage.getItem("user_first_name");
    } else {
      return "Account";
    }
  };

  return (
    <div className={`w-full h-[80px] bg-white ${className}`}>
      <div className="container-x mx-auto h-full">
        <div className="relative h-full">
          <div className="flex justify-between items-center h-full">
            <div>
              <Link to="/">
                <img
                  width="152"
                  height="20"
                  src={`${process.env.PUBLIC_URL}/assets/images/logo.png`}
                  alt="logo"
                />
              </Link>
            </div>
            {/* <div className="w-[517px] h-[44px]">
              <SearchBox type={type} className="search-com" />
            </div> */}
            <div className="flex space-x-6 items-center">
              {/* <div className="compaire relative">
                <a href="/products-compaire">
                  <span>
                    <Compair />
                  </span>
                </a>
                <span
                  className={`w-[18px] h-[18px] rounded-full  absolute -top-2.5 -right-2.5 flex justify-center items-center text-[9px] ${"bg-fuchsia-900 text-white"}`}
                >
                  0
                </span>
              </div> */}
              <div className="favorite relative">
                <Link to="/wishlist">
                  <span>
                    <ThinLove />
                  </span>
                  <span
                    className="justify-center items-center"
                    style={{ fontSize: 12 }}
                  >
                    Fav..
                  </span>
                </Link>
                <span
                  className={`w-[18px] h-[18px] rounded-full  absolute -top-2.5 -right-2.5 flex justify-center items-center text-[9px] ${"bg-fuchsia-900 text-white"}`}
                >
                  {wishList.length}
                </span>
              </div>
              <div className="cart-wrapper group relative py-4">
                <div className="cart relative cursor-pointer">
                  <Link to="/cart">
                    <span>
                      <ThinBag />
                    </span>
                    <span style={{ fontSize: 12 }}>Cart</span>
                  </Link>
                  <span
                    className={`w-[18px] h-[18px] rounded-full  absolute -top-2.5 -right-2.5 flex justify-center items-center text-[9px] ${"bg-fuchsia-900 text-white"}`}
                  >
                    {cartList.length}
                  </span>
                </div>
                {/* <div className="fixed left-0 top-0 w-full h-full z-40"></div> */}
                {/* hidden group-hover:block" */}
                {cartList.length > 0 ? (
                  <Cart
                    type={type}
                    className="absolute -right-[45px] top-11 z-50 hidden group-hover:block"
                  />
                ) : (
                  <></>
                )}
              </div>
              <div className="cursor-pointer" onClick={() => validateUser()}>
                {/* <Link to="/profile">
                  
                </Link> */}
                <span>
                  <ThinPeople />
                </span>
                <span style={{ fontSize: 12 }}>{validateName()}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
