import { useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import InputCom from "../../../Helpers/InputCom";
import Layout from "../../../Partials/Layout";
import Thumbnail from "../../Login/Thumbnail";
import { addUserAddressRequest } from "../../authActions/httpactions";

export default function AddAddress() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [checked, setValue] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const location = useLocation();
  const [isCheckout, setIsCheckout] = useState(location.state.isCheckout);

  const rememberMe = () => {
    setValue(!checked);
  };
  const [addressForm, setAddressForm] = useState({
    hno: "",
    street: "",
    locality: "",
    area: "",
    city: "",
    state: "",
    landmark: "",
  });

  const addAddress = async () => {
    setIsLoading(true);
    await dispatch(
      addUserAddressRequest(
        {
          user_id: localStorage.getItem("userId"),
          house_no: addressForm.hno,
          street: addressForm.street,
          locality: addressForm.locality,
          area: addressForm.area,
          city: addressForm.city,
          state: addressForm.state,
          landmark: addressForm.landmark,
          type: "1",
          status: "active",
        },
        dispatch
      )
    ).then((response) => {
      setIsLoading(false);
      console.log("Add address resp  ", response);
      if (response.status == true) {
        emptyForm();
        alert("Address Add successfully.");
        if(isCheckout == true) {
          navigate(-1)
        }
      } else {
        alert("Unable to add address. Please try again later.");
      }
    });
  };

  const emptyForm = () => {
    const empty = {
      hno: "",
      street: "",
      locality: "",
      area: "",
      city: "",
      state: "",
      landmark: "",
    };
    setAddressForm(empty);
  };

  const handleChanges = (e) => {
    setAddressForm({ ...addressForm, [e.target.name]: e.target.value });
  };

  const showAlert = (message) => {
    alert(message);
  };

  return (
    <Layout childrenClasses="pt-0 pb-0">
      <div className="login-page-wrapper w-full py-10">
        <div className="container-x mx-auto">
          <div className="lg:flex items-center relative">
            <div className="lg:w-[572px] w-full h-[900px] bg-white flex flex-col justify-center sm:p-10 p-5 border border-[#E0E0E0]">
              <div className="w-full">
                <div className="title-area flex flex-col justify-center items-center relative text-center mb-7">
                  <h1 className="text-[34px] font-bold leading-[74px] text-qblack">
                    Add Address
                  </h1>
                  <div className="shape -mt-6">
                    <svg
                      width="172"
                      height="29"
                      viewBox="0 0 172 29"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M1 5.08742C17.6667 19.0972 30.5 31.1305 62.5 27.2693C110.617 21.4634 150 -10.09 171 5.08727"
                        stroke="#FFBB38"
                      />
                    </svg>
                  </div>
                </div>
                <div className="input-area">
                  <div className="input-item mb-5">
                    <InputCom
                      placeholder="Enter House Number"
                      label="House No.*"
                      name="hno"
                      type="text"
                      inputClasses="h-[50px]"
                      inputHandler={(e) => handleChanges(e)}
                      value={addressForm.hno}
                    />
                  </div>
                  <div className="input-item mb-5">
                    <InputCom
                      placeholder="Enter Street"
                      label="Street*"
                      name="street"
                      type="text"
                      inputClasses="h-[50px]"
                      inputHandler={(e) => handleChanges(e)}
                      value={addressForm.street}
                    />
                  </div>
                  <div className="input-item mb-5">
                    <InputCom
                      placeholder="Enter Locality"
                      label="Locality*"
                      name="locality"
                      type="text"
                      inputClasses="h-[50px]"
                      inputHandler={(e) => handleChanges(e)}
                      value={addressForm.locality}
                    />
                  </div>
                  <div className="input-item mb-5">
                    <InputCom
                      placeholder="Enter Area name"
                      label="Area*"
                      name="area"
                      type="text"
                      inputClasses="h-[50px]"
                      inputHandler={(e) => handleChanges(e)}
                      value={addressForm.area}
                    />
                  </div>
                  <div className="input-item mb-5">
                    <InputCom
                      placeholder="Enter City"
                      label="City*"
                      name="city"
                      type="text"
                      inputClasses="h-[50px]"
                      inputHandler={(e) => handleChanges(e)}
                      value={addressForm.city}
                    />
                  </div>
                  <div className="input-item mb-5">
                    <InputCom
                      placeholder="Enter State"
                      label="State*"
                      name="state"
                      type="text"
                      inputClasses="h-[50px]"
                      inputHandler={(e) => handleChanges(e)}
                      value={addressForm.state}
                    />
                  </div>
                  <div className="input-item mb-5">
                    <InputCom
                      placeholder="Enter Landmark"
                      label="Landmark*"
                      name="landmark"
                      type="text"
                      inputClasses="h-[50px]"
                      inputHandler={(e) => handleChanges(e)}
                      value={addressForm.landmark}
                    />
                  </div>
                  <div className="signin-area mb-3.5">
                    <div className="flex justify-center">
                      <button
                        onClick={addAddress}
                        type="button"
                        className="black-btn mb-6 text-sm text-white w-full h-[50px] font-semibold flex justify-center bg-purple items-center"
                      >
                        <span>Submit</span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex-1 lg:flex hidden transform scale-60 xl:scale-100   xl:justify-center ">
              <div
                className="absolute xl:-right-20 -right-[138px]"
                style={{ top: "calc(50% - 258px)" }}
              >
                <Thumbnail />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}
