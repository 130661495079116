import { useContext, useEffect, useState } from "react";
import {
  FaAccessibleIcon,
  FaBicycle,
  FaCalendarAlt,
  FaComments,
  FaHome,
  FaIdCard,
  FaSuitcase,
  FaSun,
  FaTrailer,
} from "react-icons/fa";
import { Link } from "react-router-dom";
import { AccessoriesContext } from "../../../../contextProviders/accessoriesContextProvider";
import DataIteration from "../../../Helpers/DataIteration";

export default function Navbar({ className, type }) {
  const [categoryToggle, setToggle] = useState(false);
  const [elementsSize, setSize] = useState("0px");
  const [accessories, setAccessories] = useContext(AccessoriesContext);
  const [assurance, setAssurance] = useState([
    { name: "Warranty", id: "1" },
    { name: "Insurance", id: "2" },
    { name: "EMI", id: "3" },
  ]);
  // const getItems = document.querySelectorAll(`.categories-list li`).length;
  // if (categoryToggle && getItems > 0) {
  //   setSize(`${40 * getItems}px`);
  // }
  const handler = () => {
    setToggle(!categoryToggle);
  };
  useEffect(() => {
    console.log("Nav bar use effect");

    if (categoryToggle) {
      const getItems = document.querySelectorAll(`.categories-list li`).length;
      if (categoryToggle && getItems > 0) {
        setSize(`${42 * getItems}px`);
      }
    } else {
      setSize(`0px`);
    }
  }, [categoryToggle]);

  const showAccessories = (e) => {};

  return (
    <div
      className={`nav-widget-wrapper w-full  h-[60px] relative z-30 ${
        type === 3 ? "bg-fuchsia-900" : "bg-qyellow"
      }  ${className || ""}`}
    >
      <div className="container-x mx-auto h-full">
        <div className="w-full h-full relative">
          <div className="w-full h-full flex justify-between items-center">
            <div className="category-and-nav flex xl:space-x-7 space-x-3 items-center">
              <div className="nav">
                <ul className="nav-wrapper flex xl:space-x-10 space-x-5">
                  <li className="relative">
                    <span
                      className={`flex items-center text-sm font-600 cursor-pointer ${
                        type === 3
                          ? "text-white  hover:text-qh3-blue"
                          : "text-qblacktext  hover:text-qh3-blue"
                      }`}
                    >
                      <Link to="/">
                        <FaHome style={{ width: 20, height: 20 }} />
                        <span className="">Home</span>
                      </Link>
                    </span>
                  </li>
                  <li>
                    <span
                      className={`flex items-center text-sm font-600 cursor-pointer ${
                        type === 3
                          ? "text-white  hover:text-qh3-blue"
                          : "text-qblacktext  hover:text-qh3-blue"
                      }`}
                    >
                      <Link to="/all-products">
                        <FaBicycle
                          style={{ width: 20, height: 20 }}
                          className="fa-light fa-bicycle"
                        />
                        <span>Products</span>
                      </Link>
                    </span>
                  </li>

                  <li className="relative">
                    <span
                      className={`flex items-center text-sm font-600 cursor-pointer ${
                        type === 3
                          ? "text-white  hover:text-qh3-blue"
                          : "text-qblacktext  hover:text-qh3-blue"
                      }`}
                    >
                      <span>
                        <FaSuitcase style={{ width: 20, height: 20 }} />
                        <span>Accessories</span>

                        {/* <span className="ml-1 ">
                          <Arrow className="fill-current" />
                        </span> */}
                      </span>
                    </span>
                    <div className="sub-menu w-[220px] absolute left-0 top-[60px]">
                      <div
                        className="w-full bg-white flex justify-between items-center "
                        style={{
                          boxShadow: "0px 15px 50px 0px rgba(0, 0, 0, 0.14)",
                        }}
                      >
                        <div className="categories-wrapper w-full h-full p-5">
                          <div>
                            <div className="category-items">
                              <DataIteration
                                datas={accessories}
                                startLength={0}
                                endLength={accessories.length}
                              >
                                {({ datas }) => (
                                  <ul className="flex flex-col space-y-2">
                                    <li>
                                      <Link
                                        to={
                                          "/accessories#" +
                                          datas.name.replace(/\s/g, "")
                                        }
                                      >
                                        <div>
                                          <button
                                          // onClick={() => showAccessories(datas)}
                                          >
                                            <span
                                              className={`text-qgray text-sm font-400 border-b border-transparent   ${
                                                type === 3
                                                  ? "hover:text-qh3-blue hover:border-qh3-blue"
                                                  : "hover:text-qyellow hover:border-qyellow"
                                              }`}
                                            >
                                              {datas.name}
                                            </span>
                                          </button>
                                        </div>
                                      </Link>
                                    </li>
                                  </ul>
                                )}
                              </DataIteration>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                  <li>
                    <span
                      className={`flex items-center text-sm font-600 cursor-pointer ${
                        type === 3
                          ? "text-white  hover:text-qh3-blue"
                          : "text-qblacktext  hover:text-qh3-blue"
                      }`}
                    >
                      <span>
                        <Link to="/fleet">
                          <FaTrailer style={{ width: 20, height: 20 }} />

                          <span>Fleet</span>
                        </Link>
                      </span>
                    </span>
                  </li>
                  <li className="relative">
                    <span
                      className={`flex items-center text-sm font-600 cursor-pointer ${
                        type === 3
                          ? "text-white  hover:text-qh3-blue"
                          : "text-qblacktext  hover:text-qh3-blue"
                      }`}
                    >
                      <span>
                        <Link to="/assurance">
                          <FaSun style={{ width: 20, height: 20 }} />
                          <span>Assurance</span>
                        </Link>
                      </span>
                    </span>
                    <div className="sub-menu w-[150px] absolute left-0 top-[60px]">
                      <div
                        className="w-full bg-white flex justify-between items-center "
                        style={{
                          boxShadow: "0px 15px 50px 0px rgba(0, 0, 0, 0.14)",
                        }}
                      >
                        <div className="categories-wrapper w-full h-full p-5">
                          <div>
                            <div className="category-items">
                              <DataIteration
                                datas={assurance}
                                startLength={0}
                                endLength={assurance.length}
                              >
                                {({ datas }) => (
                                  <ul className="flex flex-col space-y-2">
                                    <li>
                                      <Link
                                        to={
                                          "/assurance#" +
                                          datas.name.replace(/\s/g, "")
                                        }
                                      >
                                        <div>
                                          <button
                                          // onClick={() => showAccessories(datas)}
                                          >
                                            <span
                                              className={`text-qgray text-sm font-400 border-b border-transparent   ${
                                                type === 3
                                                  ? "hover:text-qh3-blue hover:border-qh3-blue"
                                                  : "hover:text-qyellow hover:border-qyellow"
                                              }`}
                                            >
                                              {datas.name}
                                            </span>
                                          </button>
                                        </div>
                                      </Link>
                                    </li>
                                  </ul>
                                )}
                              </DataIteration>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                  <li>
                    <span
                      className={`flex items-center text-sm font-600 cursor-pointer ${
                        type === 3
                          ? "text-white  hover:text-qh3-blue"
                          : "text-qblacktext  hover:text-qh3-blue"
                      }`}
                    >
                      <span>
                        <Link to="/events">
                          <FaCalendarAlt style={{ width: 20, height: 20 }} />

                          <span>Blogs</span>
                        </Link>
                      </span>
                    </span>
                  </li>
                  <li>
                    <Link to="/v-showroom">
                      <span
                        className={`flex items-center text-sm font-600 cursor-pointer ${
                          type === 3
                            ? "text-white  hover:text-qh3-blue"
                            : "text-qblacktext  hover:text-qh3-blue"
                        }`}
                      >
                        <span>
                          <FaAccessibleIcon style={{ width: 20, height: 20 }} />
                          <span>Virtual Showroom</span>
                        </span>
                      </span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/about">
                      <span
                        className={`flex items-center text-sm font-600 cursor-pointer ${
                          type === 3
                            ? "text-white  hover:text-qh3-blue"
                            : "text-qblacktext  hover:text-qh3-blue"
                        }`}
                      >
                        <span>
                          <FaIdCard style={{ width: 20, height: 20 }} />
                          <span>About Us</span>
                        </span>
                      </span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/contact">
                      <span
                        className={`flex items-center text-sm font-600 cursor-pointer ${
                          type === 3
                            ? "text-white  hover:text-qh3-blue"
                            : "text-qblacktext  hover:text-qh3-blue"
                        }`}
                      >
                        <span>
                          <FaComments style={{ width: 20, height: 20 }} />
                          <span>Contact Us</span>
                        </span>
                      </span>
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="become-seller-btn">
              <Link to="/dealer">
                <div className="black-btn w-[161px] h-[40px] flex justify-center items-center cursor-pointer">
                  <div className="flex space-x-2 items-center">
                    <span className="text-sm font-600 hover:text-qh3-blue">
                      Become a Dealer
                    </span>
                    <span>
                      <svg
                        className="fill-current"
                        width="6"
                        height="10"
                        viewBox="0 0 6 10"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect
                          x="1.08984"
                          width="6.94106"
                          height="1.54246"
                          transform="rotate(45 1.08984 0)"
                          fill="white"
                        />
                        <rect
                          x="6"
                          y="4.9082"
                          width="6.94106"
                          height="1.54246"
                          transform="rotate(135 6 4.9082)"
                          fill="white"
                        />
                      </svg>
                    </span>
                  </div>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
