import "./styles.css";

export default function ShareMenu() {
  return (
    <div class="sticky-container">
      <ul class="sticky">
        <li>
          <img
            src="/assets/images/whatsapp-circle.png"
            width="32"
            height="32"
          />
          <p>
            <a href="https://wa.me/9154675146" target="blank">
              Message us from
              <br />
              Whats app
            </a>
          </p>
        </li>
        <li>
          <img
            src="/assets/images/instagram-circle.png"
            width="32"
            height="32"
          />
          <p>
            <a href="https://www.instagram.com/ezykle2023/" target="_blank">
              Follow Us on
              <br />
              Instagram
            </a>
          </p>
        </li>
        <li>
          <img
            src="/assets/images/twittwe-circles.png"
            width="32"
            height="32"
          />
          <p>
            <a href="https://twitter.com/EzykleOfficial" target="_blank">
              Follow Us on
              <br />
              Twitter
            </a>
          </p>
        </li>
        <li>
          <img
            src="/assets/images/facebook-circle.png"
            width="32"
            height="32"
          />
          <p>
            <a href="https://www.facebook.com/official.ezykle/" target="_blank">
              Follow Us on
              <br />
              Facebook
            </a>
          </p>
        </li>
        <li>
          <img
            src="/assets/images/pinterest-circle.png"
            width="32"
            height="32"
          />
          <p>
            <a href="https://in.pinterest.com/ezykleofficial/" target="blank">
              Follow Us on
              <br />
              Pinterest
            </a>
          </p>
        </li>
        <li>
          <img
            src="/assets/images/linkedin-circle.png"
            width="32"
            height="32"
          />
          <p>
            <a href="https://www.linkedin.com/company/ezykle-official/" target="blank">
              Follow Us on
              <br />
              Linkedin
            </a>
          </p>
        </li>
        <li>
          <img
            src="/assets/images/youtube-circle.jpg"
            width="32"
            height="32"
          />
          <p>
            <a href="https://www.youtube.com/channel/UCFPtnSTqhXc-Y-MzmIt-PJQ" target="_blank">
              Subscribe Us on
              <br />
              YouTube
            </a>
          </p>
        </li>
      </ul>
    </div>
  );
}
