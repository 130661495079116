import React, { useState } from 'react';
import './features.css'
import './carousel';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';




function Features() {

  const [cardInfo, setCardInfo] = useState({ show: false, header: '', description: [] });


  const headers = ['Front Wheel', 'Frame section', 'Light', 'Back Wheel', 'Handle section', 'Battery section'];
  const descriptions = [
    'High Griped Puncture Protected Nylon Tyres',
    'High Tensile Steel Frame',
    'High Efficiency 15A controller',
    'High Efficiency 14A controller',
    'Auto power cut brake levers for safety.',
    'Switching controls on display along with separate switch for Headlight and Horn',
    'Multi-information display with error code display and multi-features enabled',
    'Battery Capacity - 7.8 AH',
    'Motor Capacity - 250W',
    'Battery Charger - 36V/2A, IN 230V AC',
    'Charging Time - 3-4 HRS',
    'LED Display',
    'GPS Tracking',
    'Max Speed - 30 KMPH',
    'Distance Range - 100KM',
    'Throttle Grip',
    'Distance Range - 40 KM',
    'Distance Range - 30 KM',
    'Battery Capacity - 6.6 AH',
    'Battery Capacity - 7.6 AH',
    'Torque - 42NM',
    'Torque - 40NM',
    'Torque - 38NM'
  ];


  const handleIconClick = (header, ...descriptions) => {
    setCardInfo({ show: true, header, descriptions });
  }




  return (
    <div className='container-fluid'>
      <section className="feature_section section">
        <span className="feature_big_title">Features</span>
        <div id="carouselExample" className="carousel slide">
          <div className="carousel-inner">
            <div className="carousel-item active">
              <div className='slide-wrapper'>
                <img src='/assets/images/raze pro.png' className="product-img img-fluid" alt="pro" />
                <div className='menu'>
                  <div className='toggle slide1-pos1' onClick={() => handleIconClick(headers[0], descriptions[0], descriptions[4])}>
                    <img src='/assets/images/features.png' alt='feature' className='fa-plus' style={{ width: '35px', height: '35px' }} />
                  </div>
                  <div className='toggle slide1-pos2' onClick={() => handleIconClick(headers[1], descriptions[1], descriptions[7], descriptions[9], descriptions[10])}>
                    <img src='/assets/images/features.png' alt='feature' className='fa-plus' style={{ width: '35px', height: '35px' }} />
                  </div>
                  <div className='toggle slide1-pos3' onClick={() => handleIconClick(headers[2], descriptions[5])}>
                    <img src='/assets/images/features.png' alt='feature' className='fa-plus' style={{ width: '35px', height: '35px' }} />
                  </div>
                  <div className='toggle slide1-pos4' onClick={() => handleIconClick(headers[3], descriptions[0], descriptions[2], descriptions[4], descriptions[8], descriptions[21])}>
                    <img src='/assets/images/features.png' alt='feature' className='fa-plus' style={{ width: '35px', height: '35px' }} />
                  </div>
                  <div className='toggle slide1-pos5' onClick={() => handleIconClick(headers[4], descriptions[6], descriptions[11], descriptions[12], descriptions[13], descriptions[15], descriptions[16])}>
                    <img src='/assets/images/features.png' alt='feature' className='fa-plus' style={{ width: '35px', height: '35px' }} />
                  </div>
                </div>
              </div>
              <section className='feature_section section'>
                <span className='feature_small_title' >RAZE PRO</span>
              </section>
            </div>
            <div class="carousel-item">
              <img src='/assets/images/rook.png' className="product-img img-fluid" alt="raze" />
              <div className='menu'>
                <div className='toggle slide2-pos1' onClick={() => handleIconClick(headers[0], descriptions[0], descriptions[4])}>
                  <img src='/assets/images/features.png' alt='feature' className='fa-plus' style={{ width: '35px', height: '35px' }} />
                </div>
                <div className='toggle slide2-pos2' onClick={() => handleIconClick(headers[1], descriptions[1])}>
                  <img src='/assets/images/features.png' alt='feature' className='fa-plus' style={{ width: '35px', height: '35px' }} />
                </div>
                <div className='toggle slide2-pos3' onClick={() => handleIconClick(headers[2], descriptions[5])}>
                  <img src='/assets/images/features.png' alt='feature' className='fa-plus' style={{ width: '35px', height: '35px' }} />
                </div>
                <div className='toggle slide2-pos4' onClick={() => handleIconClick(headers[3], descriptions[0], descriptions[2], descriptions[4], descriptions[8], descriptions[20] )}>
                  <img src='/assets/images/features.png' alt='feature' className='fa-plus' style={{ width: '35px', height: '35px' }} />
                </div>
                <div className='toggle slide2-pos5' onClick={() => handleIconClick(headers[4], descriptions[6], descriptions[11], descriptions[12], descriptions[13], descriptions[14], descriptions[15])}>
                  <img src='/assets/images/features.png' alt='feature' className='fa-plus' style={{ width: '35px', height: '35px' }} />
                </div>
                <div className='toggle slide2-pos6' onClick={() => handleIconClick(headers[5], descriptions[7], descriptions[9], descriptions[10])}>
                  <img src='/assets/images/features.png' alt='feature' className='fa-plus' style={{ width: '35px', height: '35px' }} />
                </div>
              </div>
              <section className='feature_section section'>
                <span className='feature_small_title' >ROOK</span>
              </section>
            </div>
            <div class="carousel-item">
              <img src='/assets/images/larc.png' className="product-img img-fluid" alt="larc" />
              <div className='menu'>
                <div className='toggle slide3-pos1' onClick={() => handleIconClick(headers[0], descriptions[0], descriptions[4])}>
                  <img src='/assets/images/features.png' alt='feature' className='fa-plus' style={{ width: '35px', height: '35px' }} />
                </div>
                <div className='toggle slide3-pos2' onClick={() => handleIconClick(headers[1], descriptions[1], descriptions[9], descriptions[10], descriptions[19])}>
                  <img src='/assets/images/features.png' alt='feature' className='fa-plus' style={{ width: '35px', height: '35px' }} />
                </div>
                <div className='toggle slide3-pos3' onClick={() => handleIconClick(headers[2], descriptions[5])}>
                  <img src='/assets/images/features.png' alt='feature' className='fa-plus' style={{ width: '35px', height: '35px' }} />
                </div>
                <div className='toggle slide3-pos4' onClick={() => handleIconClick(headers[3],descriptions[0], descriptions[3], descriptions[4], descriptions[8], descriptions[22])}>
                  <img src='/assets/images/features.png' alt='feature' className='fa-plus' style={{ width: '35px', height: '35px' }} />
                </div>
                <div className='toggle slide3-pos5' onClick={() => handleIconClick(headers[4], descriptions[6], descriptions[11], descriptions[12], descriptions[13], descriptions[15], descriptions[16])}>
                  <img src='/assets/images/features.png' alt='feature' className='fa-plus' style={{ width: '35px', height: '35px' }} />
                </div>
              </div>
              <section className='feature_section section'>
                <span className='feature_small_title' >LARC</span>
              </section>
            </div>
            <div class="carousel-item">
              <img src='/assets/images/raze.png' className="product-img img-fluid" alt="lido" />
              <div className='menu'>
                <div className='toggle slide4-pos1' onClick={() => handleIconClick(headers[0], descriptions[0], descriptions[4])}>
                  <img src='/assets/images/features.png' alt='feature' className='fa-plus' style={{ width: '35px', height: '35px' }} />
                </div>
                <div className='toggle slide4-pos2' onClick={() => handleIconClick(headers[1], descriptions[1], descriptions[7], descriptions[9], descriptions[10])}>
                  <img src='/assets/images/features.png' alt='feature' className='fa-plus' style={{ width: '35px', height: '35px' }} />
                </div>
                <div className='toggle slide4-pos3' onClick={() => handleIconClick(headers[2], descriptions[5])}>
                  <img src='/assets/images/features.png' alt='feature' className='fa-plus' style={{ width: '35px', height: '35px' }} />
                </div>
                <div className='toggle slide4-pos4' onClick={() => handleIconClick(headers[3], descriptions[0], descriptions[2], descriptions[4], descriptions[8], descriptions[20])}>
                  <img src='/assets/images/features.png' alt='feature' className='fa-plus' style={{ width: '35px', height: '35px' }} />
                </div>
                <div className='toggle slide4-pos5' onClick={() => handleIconClick(headers[4], descriptions[6], descriptions[11], descriptions[12], descriptions[13], descriptions[15], descriptions[16])}>
                  <img src='/assets/images/features.png' alt='feature' className='fa-plus' style={{ width: '35px', height: '35px' }} />
                </div>
              </div>
              <section className='feature_section section'>
                <span className='feature_small_title' >RAZE</span>
              </section>
            </div>
            <div class="carousel-item">
              <img src='/assets/images/ezen.png' className="product-img img-fluid" alt="lido" />
              <div className='menu'>
                <div className='toggle slide5-pos1' onClick={() => handleIconClick(headers[0], descriptions[0], descriptions[4])}>
                  <img src='/assets/images/features.png' alt='feature' className='fa-plus' style={{ width: '35px', height: '35px' }} />
                </div>
                <div className='toggle slide5-pos2' onClick={() => handleIconClick(headers[1], descriptions[1], descriptions[9], descriptions[10], descriptions[18])}>
                  <img src='/assets/images/features.png' alt='feature' className='fa-plus' style={{ width: '35px', height: '35px' }} />
                </div>
                <div className='toggle slide5-pos3' onClick={() => handleIconClick(headers[2], descriptions[5])}>
                  <img src='/assets/images/features.png' alt='feature' className='fa-plus' style={{ width: '35px', height: '35px' }} />
                </div>
                <div className='toggle slide5-pos4' onClick={() => handleIconClick(headers[3],descriptions[0], descriptions[3], descriptions[4], descriptions[8], descriptions[22])}>
                  <img src='/assets/images/features.png' alt='feature' className='fa-plus' style={{ width: '35px', height: '35px' }} />
                </div>
                <div className='toggle slide5-pos5' onClick={() => handleIconClick(headers[4], descriptions[6], descriptions[11], descriptions[12], descriptions[13], descriptions[15], descriptions[17])}>
                  <img src='/assets/images/features.png' alt='feature' className='fa-plus' style={{ width: '35px', height: '35px' }} />
                </div>
              </div>
              <section className='feature_section section'>
                <span className='feature_small_title' >EZEN</span>
              </section>
            </div>
            <div class="carousel-item">
              <img src='/assets/images/axio.png' className="product-img img-fluid" alt="lido" />
              <div className='menu'>
                <div className='toggle slide6-pos1' onClick={() => handleIconClick(headers[0], descriptions[0], descriptions[4])}>
                  <img src='/assets/images/features.png' alt='feature' className='fa-plus' style={{ width: '35px', height: '35px' }} />
                </div>
                <div className='toggle slide6-pos2' onClick={() => handleIconClick(headers[1], descriptions[1], descriptions[7], descriptions[9], descriptions[10])}>
                  <img src='/assets/images/features.png' alt='feature' className='fa-plus' style={{ width: '35px', height: '35px' }} />
                </div>
                <div className='toggle slide6-pos3' onClick={() => handleIconClick(headers[2], descriptions[5])}>
                  <img src='/assets/images/features.png' alt='feature' className='fa-plus' style={{ width: '35px', height: '35px' }} />
                </div>
                <div className='toggle slide6-pos4' onClick={() => handleIconClick(headers[3], descriptions[3], descriptions[4], descriptions[8], descriptions[21])}>
                  <img src='/assets/images/features.png' alt='feature' className='fa-plus' style={{ width: '35px', height: '35px' }} />
                </div>
                <div className='toggle slide6-pos5' onClick={() => handleIconClick(headers[4], descriptions[6], descriptions[11], descriptions[12], descriptions[13], descriptions[15], descriptions[16])}>
                  <img src='/assets/images/features.png' alt='feature' className='fa-plus' style={{ width: '35px', height: '35px' }} />
                </div>
              </div>
              <section className='feature_section section'>
                <span className='feature_small_title' >ELIX</span>
              </section>
            </div>
            <div class="carousel-item">
              <img src='/assets/images/cosmo.png' className="product-img img-fluid" alt="lido" />
              <div className='menu'>
                <div className='toggle slide7-pos1' onClick={() => handleIconClick(headers[3], descriptions[0], descriptions[2], descriptions[4], descriptions[8], descriptions[20])}>
                  <img src='/assets/images/features.png' alt='feature' className='fa-plus' style={{ width: '35px', height: '35px' }} />
                </div>
                <div className='toggle slide7-pos2' onClick={() => handleIconClick(headers[1], descriptions[1], descriptions[7], descriptions[9], descriptions[10])}>
                  <img src='/assets/images/features.png' alt='feature' className='fa-plus' style={{ width: '35px', height: '35px' }} />
                </div>
                <div className='toggle slide7-pos3' onClick={() => handleIconClick(headers[2], descriptions[5])}>
                  <img src='/assets/images/features.png' alt='feature' className='fa-plus' style={{ width: '35px', height: '35px' }} />
                </div>
                <div className='toggle slide7-pos4' onClick={() => handleIconClick(headers[0], descriptions[0], descriptions[4])}>
                  <img src='/assets/images/features.png' alt='feature' className='fa-plus' style={{ width: '35px', height: '35px' }} />
                </div>
                <div className='toggle slide7-pos5' onClick={() => handleIconClick(headers[4], descriptions[6], descriptions[11], descriptions[12], descriptions[13], descriptions[15], descriptions[16])}>
                  <img src='/assets/images/features.png' alt='feature' className='fa-plus' style={{ width: '35px', height: '35px' }} />
                </div>
              </div>
              <section className='feature_section section'>
                <span className='feature_small_title' >COSMO</span>
              </section>
            </div>
            <div class="carousel-item">
              <img src='/assets/images/lido.png' className="product-img img-fluid" alt="lido" />
              <div className='menu'>
                <div className='toggle slide8-pos1' onClick={() => handleIconClick(headers[3], descriptions[0], descriptions[2], descriptions[4], descriptions[8], descriptions[20])}>
                  <img src='/assets/images/features.png' alt='feature' className='fa-plus' style={{ width: '35px', height: '35px' }} />
                </div>
                <div className='toggle slide8-pos2' onClick={() => handleIconClick(headers[1], descriptions[1], descriptions[7], descriptions[9], descriptions[10])}>
                  <img src='/assets/images/features.png' alt='feature' className='fa-plus' style={{ width: '35px', height: '35px' }} />
                </div>
                <div className='toggle slide8-pos3' onClick={() => handleIconClick(headers[2], descriptions[5])}>
                  <img src='/assets/images/features.png' alt='feature' className='fa-plus' style={{ width: '35px', height: '35px' }} />
                </div>
                <div className='toggle slide8-pos4' onClick={() => handleIconClick(headers[0], descriptions[0], descriptions[4])}>
                  <img src='/assets/images/features.png' alt='feature' className='fa-plus' style={{ width: '35px', height: '35px' }} />
                </div>
                <div className='toggle slide8-pos5' onClick={() => handleIconClick(headers[4], descriptions[6], descriptions[11], descriptions[12], descriptions[13], descriptions[15], descriptions[16])}>
                  <img src='/assets/images/features.png' alt='feature' className='fa-plus' style={{ width: '35px', height: '35px' }} />
                </div>
              </div>
              <section className='feature_section section'>
                <span className='feature_small_title' >LIDO</span>
              </section>
            </div>
            <div class="carousel-item">
              <img src='/assets/images/larc lite.png' className="product-img img-fluid" alt="lido" />
              <div className='menu'>
                <div className='toggle slide9-pos1' onClick={() => handleIconClick(headers[3], descriptions[0], descriptions[3], descriptions[4], descriptions[8], descriptions[22])}>
                  <img src='/assets/images/features.png' alt='feature' className='fa-plus' style={{ width: '35px', height: '35px' }} />
                </div>
                <div className='toggle slide9-pos2' onClick={() => handleIconClick(headers[1], descriptions[1], descriptions[9], descriptions[10], descriptions[19])}>
                  <img src='/assets/images/features.png' alt='feature' className='fa-plus' style={{ width: '35px', height: '35px' }} />
                </div>
                <div className='toggle slide9-pos3' onClick={() => handleIconClick(headers[2], descriptions[5])}>
                  <img src='/assets/images/features.png' alt='feature' className='fa-plus' style={{ width: '35px', height: '35px' }} />
                </div>
                <div className='toggle slide9-pos4' onClick={() => handleIconClick(headers[0], descriptions[0], descriptions[4])}>
                  <img src='/assets/images/features.png' alt='feature' className='fa-plus' style={{ width: '35px', height: '35px' }} />
                </div>
                <div className='toggle slide9-pos5' onClick={() => handleIconClick(headers[4], descriptions[6], descriptions[11], descriptions[12], descriptions[13], descriptions[15], descriptions[16])}>
                  <img src='/assets/images/features.png' alt='feature' className='fa-plus' style={{ width: '35px', height: '35px' }} />
                </div>
              </div>
              <section className='feature_section section'>
                <span className='feature_small_title' >LARC LITE</span>
              </section>
            </div>
          </div>
          <button className="carousel-control-prev" type="button" data-bs-target="#carouselExample" data-bs-slide="prev" >
            <span className="carousel-control-prev-icon" aria-hidden="true" style={{ backgroundColor: '#840691' }}></span>
            <span className="visually-hidden"></span>
          </button>
          <button className="carousel-control-next" type="button" data-bs-target="#carouselExample" data-bs-slide="next" >
            <span className="carousel-control-next-icon" aria-hidden="true" style={{ backgroundColor: '#840691' }}></span>
            <span className="visually-hidden"></span>
          </button>
        </div>
        {cardInfo.show && (
          <div className='container'>
            <div className='card-overlay'>
              <div className="modal fade feature_modal show" id="productFeatureModal" tabindex="-1" aria-labelledby="exampleModalCenterTitle" style={{ display: 'block', paddingRight: '17px' }} aria-modal="true" role="dialog">
                <div className="modal-dialog modal-dialog-centered" role="document">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title" id="exampleModalCenterTitle">{cardInfo.header}</h5>
                      <div className='close' onClick={() => setCardInfo({ ...cardInfo, show: false })}>
                        <FontAwesomeIcon icon={faTimes} style={{ cursor: 'pointer' }} />
                      </div>
                    </div>
                    <div className="modal-body">
                      <div className="content">
                        <ul className='list-ul'>
                          {cardInfo.descriptions.map((description, index) => (
                            <li className='list-li' key={index}>{description}</li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </section>
    </div>
  );
};

export default Features;
