import {
  getAllProducts,
  getAllSubCategories,
  getAllSubCategoriesById,
  getProductDetails,
} from "../../../api/productsservices";

const getAllProductsRequest = (data) => async (dispatch) => {
  const response = await dispatch(getAllProducts(data));
  if (
    (response &&
      Object.keys(response).length &&
      response?.data?.status === 201) ||
    200
  ) {
    if (response.data != null && response.data != undefined) {
      return Promise.resolve(response.data);
    } else {
      return Promise.resolve(null);
    }
  } else {
    return Promise.resolve(null);
  }
};

const getProductDetailsRequest = (data) => async (dispatch) => {
  const response = await dispatch(getProductDetails(data));
  if (
    (response &&
      Object.keys(response).length &&
      response?.data?.status === 201) ||
    200
  ) {
    if (response.data != null && response.data != undefined) {
      return Promise.resolve(response.data);
    } else {
      return Promise.resolve(null);
    }
  } else {
    return Promise.resolve(null);
  }
};

const getSubCategoriesRequest = (data) => async (dispatch) => {
  const response = await dispatch(getAllSubCategories(data));
  if (
    (response &&
      Object.keys(response).length &&
      response?.data?.status === 201) ||
    200
  ) {
    if (response.data != null && response.data != undefined) {
      return Promise.resolve(response.data);
    } else {
      return Promise.resolve(null);
    }
  } else {
    return Promise.resolve(null);
  }
};

const getAllSubCatProductsByIdRequest = (data) => async (dispatch) => {
  const response = await dispatch(getAllSubCategoriesById(data));
  if (
    (response &&
      Object.keys(response).length &&
      response?.data?.status === 201) ||
    200
  ) {
    if (response.data != null && response.data != undefined) {
      return Promise.resolve(response.data);
    } else {
      return Promise.resolve(null);
    }
  } else {
    return Promise.resolve(null);
  }
};

export {
  getAllProductsRequest,
  getAllSubCatProductsByIdRequest,
  getProductDetailsRequest,
  getSubCategoriesRequest,
};
